<swe-card [header]="languageService.getItem(380)" [hasRefresh]="true" [hasCollapse]="true" (refreshChange)="reload()">
  <div class="btn-group ms-1 align-top" *ngIf="resendAllowed">
    <div class="dropdown">
      <button [attr.aria-label]="languageService.getItem(842)" class="btn btn-swe" (click)="listchecked=!listchecked;checkall(listchecked)">
        <span><i class="bi bi-check-lg"></i></span>
      </button>
      <button [attr.aria-label]="languageService.getItem(843)" class="btn btn-swe" (click)="actionmenu=!actionmenu" *ngIf="ischecked">
        <span><i class="bi bi-swe-fw bi-three-dots-vertical"></i></span>
      </button>
      <ul class="dropdown-menu" [ngClass]="{'show': actionmenu && ischecked}">
        <li class="dropdown-item swe-click"><a (click)="resend()"><i class="bi bi-swe-fw bi-recycle"></i>&nbsp;{{languageService.getItem(768)}}</a></li>
      </ul>
    </div>
  </div>
  <div class="list-group">
    <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click" [ngClass]="{'list-group-item-info': receiver.checked}" *ngFor="let receiver of receivers">
      <div class="col-3 col-md-1" (click)="toggle(receiver, $event)">
        <span class="bi-swe-stack bi-swe-letter-circle-small" *ngIf="receiver.checked">
          <i class="bi bi-check-circle-fill bi-swe-stack-2x"></i>
        </span>
        <span class="bi-swe-stack bi-swe-letter-circle-small" *ngIf="!receiver.checked">
          <i class="bi bi-circle-fill bi-swe-stack-2x"></i>
          <strong class="bi-swe-stack-1x bi-swe-letter">{{receiver.To[0]}}</strong>
        </span>
      </div>
      <div class="col-9 col-md-11 d-flex flex-wrap">
        <div class="col-xs-12 col-md-3">{{receiver.ToAddress}}</div>
        <div class="col-xs-12 col-md-3">{{receiver.To}}</div>
        <div class="col-xs-12 col-md-3">{{receiver.StatusText}}</div>
        <div class="col-xs-12 col-md-3">
          <span class="small" *ngIf="receiver.FailureText&&receiver.FailureText.length>0">({{receiver.FailureText}})</span>
          <span class="small" *ngIf="permissionService.permissions && permissionService.user.IsSuper && receiver.Ticket && receiver.Ticket.length>0">({{receiver.Ticket}})</span>
        </div>
      </div>
    </a>
  </div>
</swe-card>
