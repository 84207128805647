<swe-pageheader [header]="languageService.getItem(273)"><span swe-hits>&nbsp;({{hits}}<span *ngIf="more">+</span>)</span></swe-pageheader>
<div class="swe-fill-to-edge" sweFixedHeader>
  <!--Filter-->
  <div class="btn-group align-top">
    <button [attr.aria-label]="languageService.getItem(614)" class="btn btn-swe dropdown" (click)="dofilter=!dofilter" *ngIf="ischecked">
      <span><i class="bi bi-swe-fw bi-funnel-fill"></i></span>
    </button>
  </div>
  <!--Actions-->
  <div class="btn-group me-1 align-top" *ngIf="toggleaccess&&settingService.timeline.multimode">
    <div class="dropdown">
      <button [attr.aria-label]="languageService.getItem(842)" class="btn btn-swe" (click)="togglecheck()" *ngIf="toggleaccess&&hits>0">
        <span><i class="bi bi-check-lg"></i></span>
      </button>
      <button [attr.aria-label]="languageService.getItem(843)" class="btn btn-swe" (click)="actionmenu=!actionmenu" *ngIf="isshiftchecked">
        <span><i class="bi bi-swe-fw bi-three-dots-vertical"></i></span>
      </button>
      <ul class="dropdown-menu" [ngClass]="{'show': actionmenu && isshiftchecked}">
        <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.CopyShift>0"><a (click)="action('copy');actionmenu=false"><i class="bi bi-swe-fw bi-journals"></i>&nbsp;{{languageService.getItem(354)}}</a></li>
        <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.MultiShift>0"><a (click)="action('multi');actionmenu=false"><i class="bi bi-swe-fw bi-files"></i>&nbsp;{{languageService.getItem(410)}}</a></li>
        <li class="dropdown-divider" *ngIf="(permissionService.permissions.CopyShift>0||permissionService.permissions.MultiShift>0)&&(permissionService.permissions.ListSms>0||permissionService.permissions.BookingListSms>0)"></li>
        <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.BookingListSms>0">
          <swe-confirm [isbutton]="false" [body]="languageService.getItem(927)" (acceptChange)="send(3, 0, $event)" (declineChange)="actionmenu=false">
            <i class="bi bi-swe-fw bi-phone-fill"></i>&nbsp;{{languageService.getItem(320)}}
          </swe-confirm>
        </li>
        <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.ListSms>0">
          <swe-confirm [isbutton]="false" [body]="languageService.getItem(928)" (acceptChange)="send(6, 0, $event)" (declineChange)="actionmenu=false">
            <i class="bi bi-swe-fw bi-phone-fill"></i>&nbsp;{{languageService.getItem(178)}}
          </swe-confirm>
        </li>
        <li class="dropdown-divider" *ngIf="permissionService.permissions.Abscent>0&&groupby==0"></li>
        <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.Abscent>0&&groupby==0"><a (click)="saveBooking(3);actionmenu=false;"><i class="bi bi-swe-fw bi-person-fill"></i>&nbsp;{{languageService.getItem(283)}}</a></li>
      </ul>
    </div>
  </div>
  <!--DateTime Pager-->
  <swe-datepager [type]="'booking'" (dateChange)="search(false)" [disabled]="loading" [hideTime]="true"></swe-datepager>
  <!--Precision-->
  <div class="btn-group mx-1 align-top" [attr.title]="languageService.getItem(796)">
    <div class="dropdown">
      <button [attr.aria-label]="languageService.getItem(796)" class="btn btn-swe" (click)="precisionmenu=!precisionmenu" [disabled]="loading">
        <span><i class="bi bi-swe-fw bi-compass" [ngClass]="{'bi-swe-spin': newprecision}"></i></span>
      </button>
      <ul class="dropdown-menu" [ngClass]="{'show': precisionmenu }">
        <li class="dropdown-item swe-click" (click)="changePrecision(0)"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':settingService.timeline.precision==0}"></i>{{languageService.getItem(797)}}</a></li>
        <li class="dropdown-item swe-click" (click)="changePrecision(-4)"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':settingService.timeline.precision==-4}"></i>{{languageService.getItem(1033)}}</a></li>
        <li class="dropdown-item swe-click" (click)="changePrecision(-3)"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':settingService.timeline.precision==-3}"></i>{{languageService.getItem(1034)}}</a></li>
        <li class="dropdown-item swe-click" (click)="changePrecision(-2)"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':settingService.timeline.precision==-2}"></i>{{languageService.getItem(1035)}}</a></li>
        <li class="dropdown-item swe-click" (click)="changePrecision(-1)"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':settingService.timeline.precision==-1}"></i>{{languageService.getItem(1036)}}</a></li>
        <li class="dropdown-item swe-click" (click)="changePrecision(1)"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':settingService.timeline.precision==1}"></i>{{languageService.getItem(527)}}</a></li>
        <li class="dropdown-item swe-click" (click)="changePrecision(2)"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':settingService.timeline.precision==2}"></i>{{languageService.getItem(528)}}</a></li>
      </ul>
    </div>
  </div>
  <!--Options-->
  <div class="btn-group ms-1 align-top">
    <div class="dropdown">
      <button class="btn btn-swe" (click)="optionmenu=!optionmenu" [disabled]="loading">
        <span>{{languageService.getItem(277)}}&nbsp;<i class="bi bi-caret-down-fill"></i></span>
      </button>
      <ul class="dropdown-menu" [ngClass]="{'show': optionmenu }" style="position:absolute;z-index:9999;">
        <li class="dropdown-item swe-click" (click)="show(1);optionmenu=false;" *ngIf="permissionService.permissions.MarkedDates>0&&permissionService.permissions.Timeline>0"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':isChecked(1)}"></i>{{languageService.getItem(4)}}</a></li>
        <li class="dropdown-item swe-click" (click)="show(2);optionmenu=false;" *ngIf="permissionService.permissions.MarkedDates>0&&permissionService.permissions.Timeline>0"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':isChecked(2)}"></i>{{languageService.getItem(51)}}</a></li>
        <li class="dropdown-item swe-click" (click)="show(4);optionmenu=false;" *ngIf="permissionService.permissions.News>0"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':isChecked(4)}"></i>{{languageService.getItem(134)}}</a></li>
        <li class="dropdown-item swe-click" (click)="settingService.timeline.showemptyrows=!settingService.timeline.showemptyrows;optionmenu=false;"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':settingService.timeline.showemptyrows}"></i>{{languageService.getItem(798)}}</a></li>
        <li class="dropdown-item swe-click" (click)="settingService.timeline.includereferences=!settingService.timeline.includereferences;search(false);optionmenu=false;" *ngIf="!permissionService.permissions.HideLevels"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':settingService.timeline.includereferences}"></i>{{languageService.getItem(278)}}</a></li>
        <li class="dropdown-item swe-click" (click)="search(false);settingService.timeline.exactrendering=!settingService.timeline.exactrendering;optionmenu=false;" *ngIf="!showhour&&permissionService.permissions.ExactRendering"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':settingService.timeline.exactrendering}"></i>{{languageService.getItem(300)}}</a></li>
        <li class="dropdown-item swe-click" (click)="settingService.timeline.showgraph=!settingService.timeline.showgraph;optionmenu=false;"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':settingService.timeline.showgraph}"></i>{{languageService.getItem(676)}}</a></li>
      </ul>
    </div>
  </div>
  <!--Excluded Marked (BookingMode)-->
  <div class="btn-group ms-1 align-top" *ngIf="(marked>0)&&!dofilter">
    <button class="btn btn-swe" (click)="settingService.timeline.markedhide=!settingService.timeline.markedhide">
      <span><i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-check-square': settingService.timeline.markedhide, 'bi-square': !settingService.timeline.markedhide}"></i>{{languageService.getItem(683)}}</span>
    </button>
  </div>
  <!--Notifications-->
  <div class="btn-group ms-1 align-top" *ngIf="settingService.timeline.bookingmode&&(permissionService.permissions.NotifyOnBookEmail>0||permissionService.permissions.NotifyOnBookSms>0)">
    <!--Email-->
    <button class="btn btn-swe" [attr.aria-label]="languageService.getItem(920)" [attr.title]="languageService.getItem(920)" (click)="notifyemail=!notifyemail" *ngIf="permissionService.permissions.NotifyOnBookEmail>0">
      <span><i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-square': !notifyemail, 'bi-check-square': notifyemail}"></i></span>
      <span><i class="bi bi-swe-fw bi-swe bi-envelope-fill"></i></span>
    </button>
    <!--SMS-->
    <button class="btn btn-swe" [attr.aria-label]="languageService.getItem(921)" [attr.title]="languageService.getItem(921)" (click)="notifysms=!notifysms" *ngIf="permissionService.permissions.NotifyOnBookSms>0">
      <span><i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-square': !notifysms, 'bi-check-square': notifysms}"></i></span>
      <span><i class="bi bi-swe-fw bi-swe bi-phone-fill"></i></span>
    </button>
  </div>
  <!--More Button-->
  <div class="btn-group ms-1 align-top" *ngIf="more&&!dofilter">
    <button class="btn btn-primary" (click)="search(true)">{{languageService.getItem(57)}}</button>
  </div>
  <!--Active Filters-->
  <swe-filteritems></swe-filteritems>
</div>
<!--Timeline-->
<div class="row py-2">
  <div class="col-12">
    <swe-card [hasCollapse]="false" (refreshChange)="search(false)" [stickyTop]="0">
      <span swe-leftcommand>
        <button class="btn btn-swe me-2 d-lg-none" (click)="menucollapsed=!menucollapsed"><i class="bi bi-swe-fw" [ngClass]="{'bi-arrow-left': !menucollapsed,'bi-arrow-right': menucollapsed}"></i></button>
        <button class="btn me-2" [ngClass]="{'btn-primary': settingService.timeline.multimode, 'btn-outline-primary': !settingService.timeline.multimode}" (click)="manageMultiMode()" *ngIf="toggleaccess"><i class="bi bi-swe-fw bi-check-lg"></i>{{languageService.getItem(889)}}</button>
        <button class="btn me-2" [ngClass]="{'btn-primary': settingService.timeline.bookingmode, 'btn-outline-primary': !settingService.timeline.bookingmode}" (click)="manageBookingMode()" *ngIf="permissionService.permissions.BookingMode"><i class="bi bi-swe-fw bi-check-lg"></i>{{languageService.getItem(536)}}</button>
      </span>
      <span class="d-flex flex-row-reverse d-sm-inline" swe-rightcommand>
        <i class="bi bi-swe-fw bi-newspaper bi-swe-pull-right swe-click" (click)="addnews($event)" *ngIf="permissionService.permissions.News>1" [attr.title]="languageService.getItem(100) + ' ' + languageService.getItem(131)"></i>
        <i class="bi bi-swe-fw bi-calendar-check bi-swe-pull-right swe-click" (click)="addusermarkeddate($event)" *ngIf="permissionService.permissions.MarkedDates>1" [attr.title]="languageService.getItem(100) + ' ' + languageService.getItem(110)"></i>
        <i class="bi bi-swe-fw bi-calendar-plus bi-swe-pull-right swe-click" (click)="addbooking($event)" *ngIf="permissionService.permissions.Timeline>1" [attr.title]="languageService.getItem(100) + ' ' + languageService.getItem(107)"></i>
      </span>
      <swe-timelinegrid [data]="content" [more]="more" [all]="all" [menucollapsed]="menucollapsed" [allchecked]="allchecked" [dofilter]="dofilter" [(changedSettings)]="changedSettings" [notifyemail]="notifyemail" [notifysms]="notifysms" [holidays]="holidays" (moreChange)="search(true)" (groupbyChange)="search(false, $event)" (sumoptionsChange)="search(false)" (settingsChange)="changedBySettings($event)"></swe-timelinegrid>
    </swe-card>
  </div>
</div>


<!--Profile when Book as Abscent-->
<swe-abscentdata [buttontext]="languageService.getItem(283)" [idList]="timereportIdList" [bookingid]="-1" [notifyEmail]="notifyemail" [notifySms]="notifysms" [(visible)]="popup[0].Visible" [option]="popup[0].Option" (doneChange)="reloadAfterBook()"></swe-abscentdata>
