<swe-card header="{{languageService.getItem(953)}}" [(open)]="open" [hasRefresh]="true" (refresh)="reload()">
  <swe-element [type]="'System.List'" [label]="languageService.getItem(953)" [statusLabel]="1" [(model)]="currentId" (modelChange)="manageContracts()" [items]="usercontracts"></swe-element>
  <swe-card header="{{current.Name}}" [color]="'swe'" [hasCollapse]="false" [hasRefresh]="false">
    <div class="mb-3">
      <swe-element [type]="'System.List'" [label]="languageService.getItem(953)" [statusLabel]="1" [(model)]="current.ContractId" [items]="contracts" [disabled]="userdata.ContractAccess<2||currentId>0"></swe-element>
      <swe-element [type]="'System.Date'" [label]="languageService.getItem(1191)" [statusLabel]="1" [(model)]="current.Start" [container]="{hideReset:true}" [disabled]="userdata.ContractAccess<2"></swe-element>
      <swe-element [type]="'System.Date'" [label]="languageService.getItem(1192)" [statusLabel]="1" [(model)]="current.End" [container]="{hideReset:true}" [disabled]="userdata.ContractAccess<2"></swe-element>
      <swe-element [type]="'System.List'" [label]="languageService.getItem(958)" [statusLabel]="1" [(model)]="current.BreakpointWeekday" [items]="weekdays" [disabled]="userdata.ContractAccess<2"></swe-element>
      <swe-element [type]="'System.List'" [label]="languageService.getItem(959)" [statusLabel]="1" [(model)]="current.BreakpointHour" [items]="hours" [disabled]="userdata.ContractAccess<2"></swe-element>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="save()" *ngIf="userdata.ContractAccess>1">{{languageService.getItem(16)}}</button>
        <swe-confirm [body]="languageService.getItem(1189)" (acceptChange)="delete()" *ngIf="currentId>0&&userdata.ContractAccess>2">
          <button class="btn btn-danger">{{languageService.getItem(17)}}</button>
        </swe-confirm>
      </div>
    </div>
    <swe-usertimebank [id]="id" [(open)]="cards.timebank" [contractId]="current.ContractId" [timebankId]="current.TimeBankId" [access]="userdata.TimeBankAccess" *ngIf="currentId>0&&current.TimeBankId>0&&userdata.TimeBankAccess>0"></swe-usertimebank>
    <swe-userlas [id]="id" [(open)]="cards.las" [lasId]="current.LasId" [access]="userdata.LasAccess" *ngIf="currentId>0&&current.LasId>0&&userdata.LasAccess>0"></swe-userlas>
    <swe-useremployments [id]="id" [(open)]="cards.employment" [contractId]="current.ContractId" [userContractId]="current.Id" [access]="userdata.EmploymentAccess" *ngIf="currentId>0&&userdata.EmploymentAccess>0"></swe-useremployments>
  </swe-card>
</swe-card>
