
  <div class="list-group-item px-0" [ngClass]="{'d-flex': settingService.timeline.groupby != -3, 'list-group-item-danger': row.Warning}" *ngIf="(!isEmpty||settingService.timeline.showemptyrows)&&(!dofilter||row.checked)&&(!settingService.timeline.markedhide||settings.markedgroup==row.GroupById||!row.Warning)" (click)="!dofilter&&toggle()" id="GroupBy_{{row.GroupById}}" (drop)="drop($event)" (dragover)="allowDrop($event)">
    <!--Mark Row-->
    <div class="swe-timeline-mark-row" *ngIf="row.checked">
      &nbsp;
    </div>
    <!--Resource-->
    <div class="d-flex d-wrap p-0" [ngClass]="{'col-2 col-md-4 col-lg-3 col-xl-2': menucollapsed, 'col-5 col-md-4 col-lg-3 col-xl-2': !menucollapsed}" *ngIf="settingService.timeline.groupby != -3">
      <div class="col-md-4 col-lg-3 col-xl-2 p-0" [ngClass]="{'col-4': !menucollapsed, 'col-12': menucollapsed}">
        <span class="bi-swe-stack swe-click" *ngIf="row.GroupById==0" (click)="settingService.timeline.calendarexpand = !settingService.timeline.calendarexpand">
          <i class="bi bi-circle bi-swe-stack-2x"></i>
          <i class="bi bi-swe-stack-1x" [ngClass]="{'bi-chevron-down': settingService.timeline.calendarexpand, 'bi-chevron-right ': !settingService.timeline.calendarexpand}"></i>
        </span>
        <span class="bi-swe-stack ms-md-1" *ngIf="row.Image.length==1">
          <i class="bi bi-circle-fill bi-swe-stack-2x"></i>
          <strong class="bi-swe-stack-1x bi-swe-letter">{{row.Image}}</strong>
        </span>
        <img class="swe-circle-image ms-1 ms-md-2" alt="{{row.GroupByList[0]}}" sweImage [imageFile]="row.Image" imageWidth="25" imageHeight="25" *ngIf="row.Image.length>1">
      </div>
      <div class="col-md-8 col-lg-9 col-xl-10 swe-click p-0" [ngClass]="{'col-8': !menucollapsed, 'd-none d-md-flex ': menucollapsed}" (click)="goto($event)">
        <div class="col-12 col-md-8">
          <div class="pe-1" [ngStyle]="{'white-space': row.GroupById>0?'nowrap':'normal', 'overflow': row.GroupById>0?'hidden':'auto'}" *ngFor="let key of row.GroupByList" [attr.title]="key">
            {{cut(key)}}
            <span *ngIf="row.GroupById==0"><i class="bi bi-fw bi-pin-fill swe-click" [ngClass]="{'bi-swe-rotate-90':!settings.emptyRowPinned}" (click)="managePinned()"></i></span>
          </div>
          
        </div>
        <div class="col-12 col-md-4 text-success">{{generalService.formatdecimal(shiftsum)}} {{shiftunit}}<span *ngIf="timelineshow(2)"><span *ngIf="row.GroupById>0"> / {{availablesum | number: '1.0-2'}} </span>{{availableunit}}</span></div>
      </div>
    </div>
    <!--Shifts-->
    <div class="position-relative" [ngClass]="{'col-10 col-md-8 col-lg-9 col-xl-10': settingService.timeline.groupby != -3 && menucollapsed, 'col-7 col-md-8 col-lg-9 col-xl-10': settingService.timeline.groupby != -3 && !menucollapsed, 'col-12': settingService.timeline.groupby == -3}" [ngStyle]="{'height': (row.GroupById != 0 || settingService.timeline.groupby == -3 || (settingService.timeline.calendarexpand && row.Bookings.length>0) || row.Bookings.length==0 ? height : shiftheight) + 'px'}">
      <!--Day lines-->
      <ng-container *ngFor="let day of settings.days;index as i">
        <div style="position:absolute;border-left: 1px solid #999999;top:-7px;"
             [ngStyle]="{'left': day.left + '%','height': (row.GroupById != 0 || settingService.timeline.groupby == -3 || settingService.timeline.calendarexpand || row.Bookings.length==0 ? height+20 : shiftheight+20) + 'px','min-height': minheight + 'px'}"
             *ngIf="day.main&&(settings.showday||(day.date.getDay()==1&&settings.showweek)||day.date.getDate()==1||i==0)" [attr.title]="day.date.getDate() + ' ' + dateTimeService.monthname(day.date)">&nbsp;</div>
      </ng-container>
      <!--Hour lines-->
      <ng-container *ngFor="let hour of settings.hours;index as i">
        <div style="position:absolute;border-left: 1px solid #999999;top:-7px;"
             [ngStyle]="{'left': i*100*settings.hourfactor/settings.hours.length + '%','height': (row.GroupById != 0 || settingService.timeline.groupby == -3 || settingService.timeline.calendarexpand || row.Bookings.length==0 ? height+20 : shiftheight+20) + 'px','min-height': minheight + 'px'}"
             *ngIf="settings.showhour" [attr.title]="hour">&nbsp;</div>
      </ng-container>
      <!--Weekdays, Weekends And Holidays-->
      <ng-container *ngFor="let day of settings.cols">
        <div (dblclick)="create(day.date, $event)"
             style="opacity:0.5;position:absolute;top:-7px;"
             [attr.title]="day.holiday + ' ' + row.WarningTexts + ' ' + row.PriorityTexts"
             [ngStyle]="{'width': day.width + '%','left': day.left + '%','height': (row.GroupById != 0 || settingService.timeline.groupby == -3 || settingService.timeline.calendarexpand || row.Bookings.length==0 ? height+20 : shiftheight+20) + 'px','min-height': minheight + 'px'}"
             [ngClass]="{'alert-primary':settings.showweekendcolor&&(day.date.getDay()==0||day.date.getDay()==6), 'swe-timeline-holiday':settings.showweekendcolor&&day.holiday.length>0, 'swe-timeline-weekday':!settings.showweekendcolor||(day.date.getDay()>0&&day.date.getDay()<6)}">&nbsp;</div>
      </ng-container>
      <!--Today line-->
      <div style="position:absolute;border-left: 1px solid #FF0000;top:-7px;"
           [ngStyle]="{'left': settings.today + '%','height': (row.GroupById != 0 || settingService.timeline.groupby == -3 || settingService.timeline.calendarexpand || row.Bookings.length==0 ? height+20 : shiftheight+20) + 'px','min-height': minheight + 'px'}"
           *ngIf="settings.today>0">&nbsp;</div>
      <!--News-->
      <ng-container *ngFor="let news of row.News">
        <div (click)="news.Access>0 && openNews(news.Id, $event)"
             class="swe-news-block border-info alert-info"
             [ngStyle]="{'width': news.width + '%','left': news.left + '%','top': news.top + 'px','height': news.height + 'px'}"
             [attr.title]="news.header">
          <div style="position:relative;width:100%;height:100%;overflow:hidden;">
            <i class="bi bi-caret-left-fill ps-1" *ngIf="news.outsidestart"></i>
            <i class="bi bi-caret-right-fill bi-swe-pull-right pt-1 pe-1" *ngIf="news.outsideend"></i>
            <i class="bi bi-newspaper ps-1"></i>
            {{news.Title}}
          </div>
        </div>
      </ng-container>
      <!--UserMarkedDates-->
      <ng-container *ngFor="let usermarkeddate of row.UserMarkedDates">
        <div (click)="usermarkeddate.Access>1 && openUserMarkedDate(usermarkeddate.Id, $event)"
             class="swe-usermarkeddate-block"
             [ngClass]="{'swe-disabled': (usermarkeddate.Access<2)}"
             [ngStyle]="{'width': usermarkeddate.width + '%','left': usermarkeddate.left + '%','top': usermarkeddate.top + 'px','height': usermarkeddate.height + 'px', 'border-color': usermarkeddate.Color}"
             [attr.title]="usermarkeddate.title">
          <div style="position:relative;width:100%;height:100%;overflow:hidden;">
            <i class="bi bi-caret-left-fill ps-1" *ngIf="usermarkeddate.outsidestart"></i>
            <i class="bi bi-caret-right-fill bi-swe-pull-right pt-1 pe-1" *ngIf="usermarkeddate.outsideend"></i>
            <div [ngStyle]="{'background-color': usermarkeddate.Color}" style="display:inline-block;width:10px;height:10px;border:1px solid #999999;"></div>
            {{usermarkeddate.dateheader}}<span *ngIf="usermarkeddate.Factor!=1" class="ps-1">{{usermarkeddate.Factor}}</span><span class="ps-1">{{usermarkeddate.Comment}}</span>
          </div>
        </div>
      </ng-container>
      <!--Detail-->
      <div class="swe-detail-block"
           [ngClass]="manageColors(detail.booking)"
           [ngStyle]="{'left': detail.left + '%','top': detail.top + 'px','height': detail.height + 'px', 'min-width': detail.width + '%', 'border-width': '1px'}"
           (contextmenu)="resetdetail($event)"
           *ngIf="detail&&detail.row==settings.detail">
        <div style="position:relative;width:100%;height:100%;overflow:hidden;">
          <!--Detail Header-->
          <div class="swe-detail-header">
            <div class="position-absolute bg-white w-100" style="margin-left: -3px; border-radius: 4px;" *ngIf="detail.booking.Status==10">&nbsp;</div>
            <div class="position-relative">
              <i class="bi bi-caret-left-fill ps-1" *ngIf="detail.booking.outsidestart"></i>
              <i class="bi ps-1" [ngClass]="{'bi-square': detail.booking.Status==10,'bi-arrow-up-right-square': detail.booking.Status==20,'bi-key-fill': detail.booking.Status==30,'bi-clock-fill': detail.booking.Status==40,'bi-check-lg': detail.booking.Status==50,'bi-lock-fill': detail.booking.Status==60}"></i>
              <i class="bi bi-link-45deg ps-1" *ngIf="detail.booking.Serie>0"></i>
              <i class="bi bi-shield-shaded ps-1" *ngIf="detail.booking.EmploymentPlan"></i>
              <i class="bi bi-chat ps-1" *ngIf="detail.booking.Sms"></i>
              <i class="bi bi-send ps-1" *ngIf="detail.booking.Request"></i>
              <i class="bi bi-recycle ps-1" *ngIf="detail.booking.Type==0||detail.booking.HasReplaceable"></i>
              <i class="bi bi-person-bounding-box ps-1" *ngIf="detail.booking.Type==2||detail.booking.HasStandby"></i>
              <i class="bi bi-person-x-fill ps-1" *ngIf="detail.booking.Type==3||detail.booking.HasAbscent"></i>
              <i class="bi bi-x-circle-fill ps-1" *ngIf="detail.booking.Type==4||detail.booking.HasDecline"></i>
              <i class="bi bi-box-arrow-in-right ps-1" *ngIf="detail.booking.HasCheckIn"></i>
              <i class="bi bi-caret-right-fill bi-swe-pull-right pt-1 ps-1" *ngIf="detail.booking.outsideend"></i>
              {{detail.booking.dateheader}}<span *ngIf="!permissionService.permissions.HideAmount">, {{detail.booking.Amount}}({{detail.booking.Max}})</span>
              <i class="bi bi-swe-fw bi-x-lg bi-swe-pull-right swe-click" (click)="resetdetail($event)"></i>
              <i class="bi bi-swe-fw bi-pencil-square bi-swe-pull-right swe-click" (click)="open(detail.booking, $event)"></i>
            </div>
            <div *ngIf="!permissionService.permissions.HideLevels">{{detail.booking.Level}}</div>
            <div>{{detail.booking.Header}}</div>
            <div>
              <span *ngIf="detail.booking.Serie>0">{{languageService.getItem(250)}}: {{detail.booking.Serie}}</span>
            </div>
          </div>
          <!--Detail Content-->
          <div class="table-responsive">
            <table class="swe-table">
              <tr>
                <td class="align-top">
                  <div class="swe-table-block" *ngIf="detailprofileready">
                    <table class="swe-table">
                      <tr>
                        <th colspan="2">{{languageService.getItem(29)}}</th>
                      </tr>
                      <tr *ngFor="let detailprofile of detail.profile">
                        <td>{{detailprofile.Name}}</td>
                        <td [ngSwitch]="detailprofile.Type">
                          <i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-square': !detailprofile.Value,'bi-check-square': detailprofile.Value}" *ngSwitchCase="'System.Boolean'"></i>
                          <span *ngSwitchCase="'Internetplatsen.Document'">{{detailprofile.Value.split('|')[0]}}</span>
                          <span *ngSwitchCase="'System.List'">{{detailprofile.Value}}</span>
                          <span *ngSwitchCase="'System.MultiList'">{{detailprofile.Value}}</span>
                          <ng-container *ngSwitchCase="'System.DateTime'">
                            <span *ngIf="detailprofile.Value!=null">{{detailprofile.Value}}</span>
                          </ng-container>
                          <ng-container *ngSwitchCase="'System.Date'">
                            <span *ngIf="detailprofile.Value!=null">{{detailprofile.Value}}</span>
                          </ng-container>
                          <ng-container *ngSwitchCase="'System.Time'">
                            <span *ngIf="detailprofile.Value!=null">{{detailprofile.Value}}</span>
                          </ng-container>
                          <span *ngSwitchDefault>{{detailprofile.Value}}</span>
                        </td>
                      </tr>
                    </table>
                  </div>
                </td>
                <td class="align-top">
                  <div class="swe-table-block" *ngFor="let user of detail.users | keyvalue">
                    <table class="swe-table">
                      <tr>
                        <th colspan="2">{{user.value.header}}</th>
                      </tr>
                      <tr *ngFor="let detailuser of user.value.items">
                        <td>{{detailuser.Firstname}}</td>
                        <td>{{detailuser.Lastname}}</td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <!--Bookings-->
      <ng-container *ngFor="let booking of row.Bookings">
        <div [attr.draggable]="settingService.timeline.bookingmode&&row.GroupById==0" id="Booking_{{booking.Id}}"
             (dragend)="resetScroll.emit()"
             (dragstart)="drag($event)"
             (click)="booking.Access>1 && click(booking, $event)"
             (dblclick)="booking.Access>1 && open(booking, $event)"
             (contextmenu)="showdetail(booking, $event)"
             [ngClass]="manageColors(booking)"
             class="swe-booking-block"
             [ngStyle]="{'width': booking.width + '%','left': booking.left + '%','top': booking.top + 'px','height': (row.GroupById!=0||settingService.timeline.groupby == -3||booking.top<shiftheight||settingService.timeline.calendarexpand ? booking.height : 8) + 'px', 'border-width': (booking.Id==settings.marked || booking.ismarked) ? '3px' : '1px'}"
             [attr.title]="booking.title"
             *ngIf="row.GroupById!=0||settingService.timeline.groupby==-3||booking.top<=shiftheight||settingService.timeline.calendarexpand">
          <div style="position:relative;width:100%;height:100%;overflow:hidden;padding-left:3px;">
            <!--Main values-->
            <div class="position-absolute bg-white w-100" style="margin-left: -3px; border-radius: 4px;" *ngIf="booking.Status==10">&nbsp;</div>
            <div class="position-relative">
              <i class="bi bi-caret-left-fill ps-1" *ngIf="booking.outsidestart"></i>
              <i class="bi ps-1" [ngClass]="{'bi-square': booking.Status==10,'bi-arrow-up-right-square': booking.Status==20,'bi-key-fill': booking.Status==30,'bi-clock-fill': booking.Status==40,'bi-check-lg': booking.Status==50,'bi-lock-fill': booking.Status==60}"></i>
              <i class="bi bi-link-45deg ps-1" *ngIf="booking.Serie>0"></i>
              <i class="bi bi-shield-shaded ps-1" *ngIf="booking.EmploymentPlan"></i>
              <i class="bi bi-chat ps-1" *ngIf="booking.Sms"></i>
              <i class="bi bi-send ps-1" *ngIf="booking.Request"></i>
              <i class="bi bi-recycle ps-1" *ngIf="booking.Type==0||booking.HasReplaceable"></i>
              <i class="bi bi-person-bounding-box ps-1" *ngIf="booking.Type==2||booking.HasStandby"></i>
              <i class="bi bi-person-x-fill ps-1" *ngIf="booking.Type==3||booking.HasAbscent"></i>
              <i class="bi bi-x-circle-fill ps-1" *ngIf="booking.Type==4||booking.HasDecline"></i>
              <i class="bi bi-box-arrow-in-right ps-1" *ngIf="booking.HasCheckIn"></i>
              <i class="bi bi-caret-right-fill bi-swe-pull-right pt-1 ps-1" *ngIf="booking.outsideend"></i>
              {{booking.dateheader}}<span *ngIf="!permissionService.permissions.HideAmount">, {{booking.Amount}}({{booking.Max}})</span>
              <span *ngIf="!permissionService.permissions.HideLevels&&booking.EmploymentPlan&&settingService.timeline.groupby!=-1">, {{booking.Level}}</span>
              <span *ngIf="booking.EmploymentPlan">, {{booking.Header}}</span>
            </div>
            <div *ngIf="!permissionService.permissions.HideLevels&&!booking.EmploymentPlan&&settingService.timeline.groupby!=-1">{{booking.Level}}</div>
            <div *ngIf="!booking.EmploymentPlan">{{booking.Header}}</div>
            <div>
              <span *ngIf="booking.Serie>0">{{languageService.getItem(250)}}: {{booking.Serie}}</span>
            </div>
            <!--Profile-->
            <div *ngFor="let profile of booking.Profiles">
              {{profile}}
            </div>
            <!--Users-->
            <i class="bi bi-arrow-clockwise ps-1" *ngIf="booking.IsRightClick"></i>
            <div *ngFor="let usergroup of booking.Users | keyvalue">
              <div *ngFor="let detailuser of usergroup.value.items">
                <i class="bi bi-person-fill ps-1" *ngIf="usergroup.key==0"></i>
                <i class="bi bi-recycle ps-1" *ngIf="usergroup.key==1"></i>
                <i class="bi bi-person-bounding-box ps-1" *ngIf="usergroup.key==2"></i>
                <i class="bi bi-person-x-fill ps-1" *ngIf="usergroup.key==3"></i>
                <span class="ps-1">{{detailuser.Firstname}} {{detailuser.Lastname}}</span>
              </div>
            </div>
          </div>
          <!--Activities-->
          <div *ngFor="let activity of booking.Activities"
               style="position:absolute; bottom:0px;height:66%;opacity:0.5;"
               [ngStyle]="{'left': activity.left + '%','width': activity.width + '%', 'background-color': activity.Color}"
               [attr.title]="activity.title">
          </div>
          <!--Included Break-->
          <div *ngFor="let break of booking.Breaks"
               style="position:absolute; bottom:0px;height:100%;opacity:0.8;"
               [ngStyle]="{'left': break.breakleft + '%','width': break.breakwidth + '%', 'background-color': break.breakcolor}"
               [ngClass]="{'swe-disabled-border-block': booking.Access<2, 'swe-reference-border-block': booking.Type>1, 'swe-timeline-border-danger': booking.Amount==0,'swe-timeline-border-warning': (booking.Amount>0&&booking.Amount<booking.Max), 'swe-timeline-border-success': (booking.Amount>0&&booking.Amount==booking.Max)}"
               [attr.title]="break.breaktitle">
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <swe-confirm [(isvisible)]="droppedStep1" [event]="dropevent" [body]="dropbody" (acceptChange)="dropacceptStep1($event)" (declineChange)="dropdecline($event)"></swe-confirm>
  <swe-confirmserie [(isvisible)]="droppedStep2" (acceptChange)="dropacceptStep2($event,dropevent)" [isSerie]="droppedSerieId>0" [affectAllOnly]="droppedAffectAllOnly">
