import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LanguageService } from '../_services/language.service';
import { SettingService } from '../_services/setting.service';
import { PermissionService } from '../_services/permission.service';
import { DataService } from '../_services/data.service';
import { ListService } from '../_services/list.service';
import { GeneralService } from '../_services/general.service';
import { AuthenticationService } from '../_services/authentication.service';
import { AlertService } from '../_services/alert.service';


@Component({
  selector: 'app-userdetail',
  templateUrl: './userdetail.component.html'
})
export class UserDetailComponent implements OnInit, OnDestroy {

  private _id: number;
  private _userdata: any = {}; //Sharing UserData between components
  private _objecttoobjects: any[] = [];
  private _impersonatemenu: boolean = false;
  private _pincode: string = '';
  private _pinfocus: boolean = false;


  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private settingService: SettingService,
    private dataService: DataService,
    private listService: ListService,
    private generalService: GeneralService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute) {
    
  }

  ngOnDestroy() {
    this.dataService.tokenRequest('/api/v1/users/' + this._id + '/close', 'POST')
      .subscribe(res => {

      });
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this._id = Number(params.get('id'));
      this.settingService.lastuser = this._id;

      this.load();
    });
  }

  /*Properties*/
  public get cards() {
    return {
      data: this.settingService.isDevice(['md', 'lg', 'xl']),
      employmentplans: false,
      bookings: false,
      requests: false,
      availability: false,
      belongings: false,
      statistics: false,
      icalendar: false,
      contract: false,
      logs: false,
      systemlogs: false,
      messagelogs: false
    };
  }
  public get id() {
    return this._id;
  }
  public get userdata() {
    return this._userdata;
  }
  public get objecttoobjects() {
    return this._objecttoobjects;
  }
  public get impersonatemenu() {
    return this._impersonatemenu;
  }
  public set impersonatemenu(val) {
    this._impersonatemenu = val;
  }
  public get pincode() {
    return this._pincode;
  }
  public set pincode(val) {
    this._pincode = val;
  }
  public get pinfocus() {
    return this._pinfocus;
  }
  public set pinfocus(val) {
    this._pinfocus = val;
  }




  /*Methods*/
  public manageUserdata(data) {
    this._userdata = data;
  }
  public toggle() {
    this._impersonatemenu = !this._impersonatemenu;
    this._pinfocus = this._impersonatemenu;
  }
  public keyup(e) {
    if (e.keyCode == 13) {
      this.impersonate();
      this._pinfocus = false;
    }
  }
  public impersonate() {

    this.authenticationService.impersonate(this.id, this._pincode)
      .subscribe((res) => {

        setTimeout(() => {
          this.alertService.Add({ type: 'success', message: this.languageService.getItem(317) + ': ' + this.permissionService.user.Firstname + ' ' + this.permissionService.user.Lastname });

          let currentUrl = this.router.url;
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentUrl]);
          });
        }, 1000);
      });

    this._pincode = '';
    this._impersonatemenu = false;
  }


  //Functions
  private load() {

    this._objecttoobjects = this.listService.formatArray(this.generalService.propertycategories, ['User'], 'Type');

  }
}
