import { Component, OnChanges, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

import { LanguageService } from '../../_services/language.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';
import { PermissionService } from '../../_services/permission.service';
import { GeneralService } from '../../_services/general.service';
import { PropertyService } from '../../_services/property.service';
import { SettingService } from '../../_services/setting.service';

@Component({
  selector: 'swe-objecttoobjectdata',
  templateUrl: './objecttoobjectdata.component.html'
})
export class ObjectToObjectDataComponent implements OnChanges, OnDestroy {
  @Input() id: number;
  @Input() category: number;
  @Input() header: string = '';
  @Input() general: number;
  @Input() open: boolean;

  private _subscriptions: Subscription[] = [];
  private _object: any = {};
  private _categories: any[] = [];
  private _loading: boolean;

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public generalService: GeneralService,
    private dataService: DataService,
    private alertService: AlertService,
    private propertyService: PropertyService,
    private settingService: SettingService,
    private location: Location,
    private router: Router
  ) {

    this._subscriptions.push(settingService.onSave$
      .subscribe((e) => {
        //Save
        this.save();
      }));
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnChanges() {
    if (this.open) {
      
      this.load();
    }
    
  }

  /*Properties*/
  public get loading() {
    return this._loading;
  }
  public get object() {
    return this._object;
  }
  public get categories() {
    return this._categories;
  }


  /*Methods*/
  public load() {

    if (!this.open) { return; }

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/objects/children/' + this.category + '/' + this.id , 'GET')
      .subscribe(res => {
        if (res) {

          this._object = res;

          this._categories = [];
          res.Categories.forEach((category) => {
            this._categories.push({ Id: category.Key, Name: category.Value });
          });

          if (this.id == 0) {
            this._object.GeneralId = this.general;
            if (this._categories.length > 0) {
              this._object.ObjectId = this._categories[0].Id;
            }
          }

          this._loading = false;
        }
      });
  }
  public save() {
    if (!this.propertyService.validate(this._object.Profile)) { return; }

    let verb = 'POST';
    let path = '/api/v1/objects/children/'
    if (this.id > 0) {
      verb = 'PUT';
      path += this.id;
    }

    let dto = {
      ObjectId: this._object.ObjectId,
      GeneralId: this.general,
      Profile: this._object.Profile
    };

    this.dataService.tokenRequest(path, verb, dto, 'text', 'response')
      .subscribe((response) => {

        this.alertService.Add({ type: 'success', message: response.body });

        if (response.status == 201) {
          this.router.navigate([response.headers.get('Location')], { replaceUrl: true });
        }
        else {
          this.load();
        }

      });
  }
  public delete() {

    this.dataService.tokenRequest('/api/v1/objects/children/' + this.id, 'DELETE', {}, 'text')
      .subscribe((res) => {

        this.alertService.Add({ type: 'success', message: res });

        this.location.back();
      });
  }


  //Functions
  
}
