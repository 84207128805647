import { Component, OnChanges, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LanguageService } from '../../_services/language.service';
import { DataService } from '../../_services/data.service';
import { SettingService } from '../../_services/setting.service';
import { DateTimeService } from '../../_services/datetime.service';
import { PermissionService } from '../../_services/permission.service';
import { ListService } from '../../_services/list.service';
import { AlertService } from '../../_services/alert.service';
import { GeneralService } from '../../_services/general.service';
import { PropertyService } from '../../_services/property.service';
import { ListUtility } from '../../_utilities/list.utility';
import { ViewCacheService } from '../../_services/viewcache.service';



@Component({
  selector: 'swe-userbookings',
  templateUrl: './userbookings.component.html'
})
export class UserBookingsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() id: number;
  @Input() open: boolean;
  @Input() type: number = 0;
  @Input() userdata: any;

  private unsubsribe$ = new Subject<void>();
  private _bookings: any[] = [];
  private _groupedby = {};
  private _more: boolean = false;
  private _top: number = 25;
  private _multiple: number = 1;
  private _sum: number = 0;
  private _sumStandby: number = 0;
  private _sumAbscent: number = 0;
  private _sumDecline: number = 0;
  private _title: string = '';
  private _employmentplan: boolean = false;
  private _loading: boolean = false;
  private _sortId: number = 0;
  private _listutility: ListUtility = new ListUtility();
  //Popup
  private _timereportIdList: any[] = [];
  private _popup: any[] = [{ Visible: false, Event: null, Option: 3 }];

  constructor(
    public languageService: LanguageService,
    public propertyService: PropertyService,
    private dataService: DataService,
    private dateTimeService: DateTimeService,
    public settingService: SettingService,
    public permissionService: PermissionService,
    private listService: ListService,
    private alertService: AlertService,
    public generalService: GeneralService,
    private viewCacheService: ViewCacheService,
    private router: Router
  ) {
    settingService.initView(
      1 | 256 | 512,
      [],
      [{ type: 'booking', key: 'activityoption' }]);

    settingService.onRefresh$
      .pipe(takeUntil(this.unsubsribe$))
      .subscribe(() => {
      this.search();
      });

    
  }

  ngOnDestroy() {
    this.unsubsribe$.next();
    this.unsubsribe$.complete();
  }

  ngOnInit() {
    this._listutility.toggleaccess = (this.permissionService.permissions.MultiTimereport > 0);
    this._listutility.dataaccessname = 'MarkAccess';
    this._listutility.dataaccess = 1;
  }

  ngOnChanges() {
    if (this.open) {
      this.search();
    }
    this._title = (this.type == 1) ? this.languageService.getItem(698) : this.languageService.getItem(4);
    this._employmentplan = (this.type == 1) && this.permissionService.permissions.EmploymentPlan > 0;
  }

  /*Properties*/
  public get more(): boolean {
    return this._more;
  }
  public get sum(): number {
    return this._sum;
  }
  public get sumStandby(): number {
    return this._sumStandby;
  }
  public get sumAbscent(): number {
    return this._sumAbscent;
  }
  public get sumDecline(): number {
    return this._sumDecline;
  }
  public get loading(): boolean {
    return this._loading;
  }
  public get groupedby() {
    return this._groupedby;
  }
  public get bookings() {
    return this._bookings;
  }
  public get title() {
    return this._title;
  }
  public get employmentplan() {
    return this._employmentplan;
  }
  public get sortId() {
    return this._sortId;
  }
  public get listutility() {
    return this._listutility;
  }
  //Popup
  public get timereportIdList() {
    return this._timereportIdList;
  }
  public get popup() {
    return this._popup;
  }
  public set popup(val) {
    this._popup = val;
  }



  /*Methods*/
  public search(getmore: boolean = false) {

    if (!this.open) { return; }

    if (!this.dateTimeService.validation(this.settingService.booking.start, this.settingService.booking.end)) { return; }

    this._more = false;

    if (getmore) {
      this._multiple = 1 + (this._multiple / 4);
      this._top *= 4;
    }
    else {
      this._multiple = 1;
      this._top = 25;
      this._bookings = [];

      this._sum = 0;
      this._sumStandby = 0;
      this._sumAbscent = 0;
      this._sumDecline = 0;
    }

    let filter = {
      Top: this._top,
      Multiple: this._multiple,
      //Bookings
      Start: this.settingService.start('booking'),
      End: this.settingService.end('booking'),
      IntervalType: this.settingService.booking.intervaltype,
      Weekdays: this.settingService.booking.weekdays,
      StatusList: this.settingService.booking.status,
      Personal: this.settingService.booking.personal,
      ShiftType: this.settingService.booking.shifttype,
      ReplaceableAsNoSlot: this.settingService.booking.replaceableasnoslot,
      CheckIn: this.settingService.booking.checkin,
      TypeList: this.settingService.booking.bookingtype.join(),
      IncludeAllLevels: true,
      OwnerId: this.settingService.booking.owner,
      //Users
      UserId: this.id
    };

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/bookings/search', 'POST', filter)
      .subscribe(res => {
        if (res) {

          let isBooking = true;
          if (this.type && this.type == 1) {
            isBooking = false;
          }

          res.Bookings.forEach(booking => {

            if ((this.permissionService.permissions.EmploymentPlan == 0) || (isBooking && !booking.EmploymentPlan) || (!isBooking && booking.EmploymentPlan)) {
              let start = new Date(booking.Start);
              let end = new Date(booking.End);

              booking.dateheader = this.dateTimeService.header(start, end, false);

              if (this.permissionService.permissions.UserBookingProfile) {
                this.manageProfile(booking.Profile);
              }

              this._bookings.push(booking);
            }
          });

          this._listutility.rows = this._bookings;

          this._more = res.More;

          this._sum += res.Sum;
          this._sumStandby += res.SumStandby;
          this._sumAbscent += res.SumAbscent;
          this._sumDecline += res.SumDecline;

          this._groupedby = this.listService.groupByInt(this._bookings, "Type", [{ Id: 1, Name: '', Sort: 0 }, { Id: 0, Name: this.languageService.getItem(423), Sort: 1 }, { Id: 2, Name: this.languageService.getItem(1129), Sort: 2 }, { Id: 3, Name: this.languageService.getItem(280), Sort: 3 }, { Id: 4, Name: this.languageService.getItem(1106), Sort: 4 }]);
        }

        this._loading = false;
      });
  }
  public showedit(booking: any): boolean {

    if (booking.TimereportStatus == 40) {
      return (booking.TimereportAccess > 0);
    }
    else if (booking.TimereportStatus == 50) {
      return (booking.ConfirmedAccess > 0);
    }
    else if (booking.TimereportStatus == 60) {
      return (booking.CompletedAccess > 0);
    }
    else {
      return (booking.TimereportAccess > 1);
    }
  }
  public create(type: number, e) {

    let url = '/timereports/0/user/' + this.id + '/type/' + type;

    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }
  public goto(id: number, e) {
    e.stopPropagation();

    let url = '/bookings/' + id;
    if (id == 0) {
      url += '/user/' + this.id;
    }

    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }
  public timereport(id: number, e) {
    e.stopPropagation();

    this.router.navigate(['/timereports', id]);
  }
  public deletetimereport(id: number) {
    
    this.dataService.tokenRequest('/api/v1/timereports/' + id, 'DELETE', {}, 'text')
      .subscribe(res => {
        if (res) {

          this.alertService.Add({ message: res, type: 'success' });

          //Reload
          this.search();
        }
      });
  }
  public manageDeleteText(booking) {
    if (booking.Type == 3) {
      return this.languageService.getItem(1134);
    }
    else {
      return this.languageService.getItem(1133);
    }
  }
  public abscent(id: number, e) {
    e.stopPropagation();

    this.router.navigate(['/timereports/'+ id + '/user/0/type/3']);
  }
  public change(booking: any, status: number, e) {
    e.stopPropagation();

    let diffstatus = (booking.TimereportStatus - status);

    if (diffstatus >= 0) {
      //Downgrade
      var comparestatus = booking.TimereportStatus;

      if (this.getAccess(comparestatus, booking) < 3) {
        this.raiseAccessError(comparestatus, true);
        return;
      }
      if (diffstatus > 10 && this.listService.find(this.generalService.timereportstatus, 'Id', comparestatus - 10)) {
        //More than one step
        if (this.getAccess(comparestatus - 10, booking) < 3) {
          this.raiseAccessError(comparestatus - 10, true);
          return;
        }
      }

      if (diffstatus == 0) {
        //Click Out
        if (booking.TimereportStatus == 40) {
          status = -1;
        }
        else {
          status = status - 10;
        }
      }
    }
    else {
      //Upgrade
      if (this.getAccess(status, booking) < 2) {
        this.raiseAccessError(status, false);
        return;
      }
    }

    let dto = {
      Status: status,
      StartTimeType: -1, //Not changed
      TimeType: -1, //Not changed
      EndTimeType: -1 //Not changed
    };

    this.dataService.tokenRequest('/api/v1/timereports/' + booking.Timereport, 'PUT', dto, 'text')
      .subscribe(res => {
        if (res) {
          this.search(false);
          this.alertService.Add({ message: res, type: 'success' });
        }
      });
  }
  public action(option) {

    let timereports = [];
    this._bookings.forEach((bookinguser) => {
      if (bookinguser.checked && bookinguser.MarkAccess > 0) {
        timereports.push(bookinguser.Timereport);
      }
    });

    if (timereports.length > 0) {

      this.viewCacheService.add('multi_timereports', timereports);

      this.router.navigate(['/timereports/action/' + option + '/']);
    }
  }
  public saveBooking(booktype) {
    let users = [];
    let timereports = [];
    this._bookings.forEach((bookinguser) => {
      if (bookinguser.checked && bookinguser.MarkAccess > 0) {
        users.push(bookinguser);
        timereports.push(bookinguser.Timereport);
      }
    });

    if (booktype < 3 || !this.permissionService.permissions.DetailWhenAbscent) {
      this.synchronouslysave(booktype, users, 0);
    }
    else {
      this._popup[0].Visible = true;
      this._popup[0].Event = 3;
      this._popup[0].Option = 1;

      this._timereportIdList = timereports;
    }

    this.listutility.checkall(false);
  }
  public reloadAfterBook() {
    this.search(false);
  }


  //Sort
  public sort(id) {

    if (this._bookings.length == 0) {
      return;
    }

    let sorted = [...this._bookings];

    let item = this.listService.find(this._bookings[0].Profile, 'Property', id);
    let index = this._bookings[0].Profile.indexOf(item);

    if (this._sortId == id) {
      this._sortId *= -1;
      sorted = this.sortcolumns(sorted, index, true);
    }
    else if ((this._sortId * -1) == id) {
      this._sortId = 0;
    }
    else {
      this._sortId = id;
      sorted = this.sortcolumns(sorted, index, false);
    }

    this._groupedby = this.listService.groupByInt(sorted, "Type", [{ Id: 1, Name: '', Sort: 0 }, { Id: 0, Name: this.languageService.getItem(423), Sort: 1 }, { Id: 2, Name: this.languageService.getItem(279), Sort: 2 }, { Id: 3, Name: this.languageService.getItem(280), Sort: 3 }, { Id: 4, Name: this.languageService.getItem(1106), Sort: 4 }]);
  }

            

  //Functions
  private getAccess(status, obj) {
    if (status == 40) {
      return obj.TimereportAccess;
    }
    else if (status == 50) {
      return obj.ConfirmedAccess;
    }
    else if (status == 60) {
      return obj.CompletedAccess;
    }

    return 0;
  }
  private raiseAccessError(status, downgrade) {
    if (downgrade) {
      let msg = (status == 60) ? this.languageService.getItem(458) : ((status == 50) ? this.languageService.getItem(457) : this.languageService.getItem(456));
      this.alertService.Add({ message: msg, type: 'danger' });
      return;
    }
    else {
      let msg = (status == 60) ? this.languageService.getItem(230) : ((status == 50) ? this.languageService.getItem(229) : this.languageService.getItem(228));
      this.alertService.Add({ message: msg, type: 'danger' });
      return;
    }
  }
  private manageProfile(profileList: any[]) {

    profileList.forEach((profile) => {

      let property = this.propertyService.getProperty(profile.Property);
      if (property) {
        profile.Type = property.Type;
        if (profile.Type == 'System.DateTime' || profile.Type == 'System.Date' || profile.Type == 'System.Time') {
          if (property.Format && property.Format.length > 0) {
            profile.FormatValue = this.dateTimeService.format(profile.Value, property.Format);
          }
        }
        else if (profile.Type == 'System.List' || profile.Type == 'System.MultiList') {
          let res = '';

          if (profile.Value != null) {
            profile.Value.toString().split('|').forEach((v) => {

              property.Items.forEach((pi) => {

                if (pi.Id == parseInt(v)) {

                  if (res.length > 0) {
                    res += '| ';
                  }
                  res += pi.Name;
                }

              });

            });
          }

          profile.FormatValue = res;

        }
        else {
          profile.FormatValue = profile.Value;
        }
      }

    });

  }
  private synchronouslysave(booktype, users, counter) {

    let user = users[counter];
    if (!user) {
      return;
    }

    let filter = {
      Type: booktype
    };

    this.dataService.tokenRequest('/api/v1/bookings/' + user.Id + '/users/' + this.id, 'POST', filter, 'text')
      .subscribe(res => {

        this.alertService.Add({ message: res, type: 'success' });

        counter++;
        if (counter < users.length) {
          this.synchronouslysave(booktype, users, counter);
        }
        else {
          this.search(false);
        }
      });

  }
  private sortcolumns(array, index, desc) {

    //Sort list
    array.sort(function (a, b) {

      //Value A
      var objectA = a.Profile[index];
      var valueA = objectA.Value;
      if (typeof valueA == "string") {
        valueA = valueA.toLowerCase();
      }
      //Value B
      var objectB = b.Profile[index];
      var valueB = objectB.Value;
      if (typeof valueB == "string") {
        valueB = valueB.toLowerCase();
      }

      //Compare
      if (valueA == valueB) {
        // if they are equal, return 0 (no sorting)
        return 0;
      }
      else if (valueA > valueB) {
        // if a should come after b, return 1
        return desc ? -1 /*desc*/ : 1 /*asc*/;
      }
      else {
        // if b should come after a, return -1
        return desc ? 1 /*desc*/ : -1 /*asc*/;
      }
    });

    return array;
  }

}
