import { Component, OnChanges, Input, Output, EventEmitter, OnDestroy, ElementRef, ViewChild, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { LanguageService } from '../../_services/language.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';
import { SettingService } from '../../_services/setting.service';
import { PermissionService } from '../../_services/permission.service';
import { GeneralService } from '../../_services/general.service';
import { DateTimeService } from '../../_services/datetime.service';
import { PropertyService } from '../../_services/property.service';


@Component({
  selector: 'swe-leveldata',
  templateUrl: './leveldata.component.html'
})
export class LevelDataComponent implements OnInit, OnChanges, OnDestroy {
  @Input() id: number;
  @Input() open: boolean;
  @Output() leveldataChange = new EventEmitter<any>();
  @ViewChild('sweSaveElement') saveElement: ElementRef;

  private _subscriptions: Subscription[] = [];
  private _level: any;
  private _loading: boolean;
  private _employmentcategories: any[] = [];
  private _contracts: any[] = [];
  
  constructor(
    public languageService: LanguageService,
    private dataService: DataService,
    private alertService: AlertService,
    private settingService: SettingService,
    public permissionService: PermissionService,
    public generalService: GeneralService,
    private dateTimeService: DateTimeService,
    private propertyService: PropertyService,
    private route: ActivatedRoute,
    private router: Router
  ) {

    this._subscriptions.push(settingService.onViewRefresh$
      .subscribe((refresh) => {

        if (refresh.type == 'levelsettings_save') {
          this._level.NewRegistrationEmail = refresh.data.NewRegistrationEmail;
          this._level.BookingChangeEmail = refresh.data.BookingChangeEmail;
          this._level.BookingChangeSms = refresh.data.BookingChangeSms;
          this.save();
        }

      }));

    this._subscriptions.push(settingService.onSave$
      .subscribe((e) => {
        //Save
        this.save();
      }));
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    if (this.open) {
      this.load();
    }
  }

  /*Properties*/
  public get loading() {
    return this._loading;
  }
  public get level() {
    return this._level;
  }
  public get employmentcategories() {
    return this._employmentcategories;
  }
  public get contracts() {
    return this._contracts;
  }



  /*Methods*/
  public profiledone() {
    if (this._level.Access > 1) {
      let element = this.saveElement.nativeElement;
      element.click();
    }
  }
  public load() {

    if (!this.open) { return; }

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/levels/' + this.id, 'GET')
      .subscribe(res => {
        if (res) {

          this._level = res;
          this._loading = false;

          this.leveldataChange.emit({
            Access: this._level.Access,
            SettingAccess: this._level.SettingAccess,
            RelationAccess: this._level.RelationAccess,
            ParentId: this._level.ParentId,
            Parent: this._level.Parent,
            ParentAccess: this._level.ParentAccess,
            NewRegistrationEmail: this._level.NewRegistrationEmail,
            BookingChangeEmail: this._level.BookingChangeEmail,
            BookingChangeSms: this._level.BookingChangeSms,
            BookingAccess: this._level.BookingAccess,
            UserAccess: this._level.UserAccess,
            LogAccess: this._level.LogAccess,
            Created: this._level.Created
          });
        }
      });
  }
  public save() {

    if (!this.propertyService.validate(this._level.Profile)) { return; }

    let verb = 'POST';
    let path = '/api/v1/levels/'
    if (this.id > 0) {
      verb = 'PUT';
      path += this.id;
    }

    let dto: any = {
      ParentId: this._level.ParentId,
      Group: this._level.Group > 0 ? this._level.Group : 0,
      Profile: this._level.Profile,
      NewRegistrationEmail: this._level.NewRegistrationEmail,
      BookingChangeEmail: this._level.BookingChangeEmail,
      BookingChangeSms: this._level.BookingChangeSms,
      ExternalCategories: this._level.ExternalCategories,
      ContractId: this._level.ContractId ? this._level.ContractId : 0
    };

    this.dataService.tokenRequest(path, verb, dto, 'text', 'response')
      .subscribe(response => {
        if (response) {

          this.alertService.Add({ message: response.body, type: 'success' });
          if (response.status == 201) {
            this.router.navigate([response.headers.get('Location')], { replaceUrl: true });
          }
          else {
            this.load();
          }
        }
      });
  }

  public connect() {

    let verb = 'POST';
    let path = '/api/v1/levels/' + this.id + '/connect';
    
    this.dataService.tokenRequest(path, verb, {}, 'text')
      .subscribe(res => {
        if (res) {
          this.alertService.Add({ message: res, type: 'success' });

          this.load();
        }
      });
  }

  public disconnect() {

    let verb = 'POST';
    let path = '/api/v1/levels/' + this.id + '/disconnect';

    this.dataService.tokenRequest(path, verb, {}, 'text')
      .subscribe(res => {
        if (res) {
          this.alertService.Add({ message: res, type: 'success' });

          this.load();
        }
      });
  }

  public delete() {

    this.dataService.tokenRequest('/api/v1/levels/' + this.id, 'DELETE', {}, 'text')
      .subscribe(res => {
        if (res) {

          this.alertService.Add({ message: res, type: 'success' });

          this.router.navigateByUrl('/levels');
        }
      });
  }


  //Functions
  private init() {

    this.generalService.employmentcategories.forEach((employmentcategory) => {

      this._employmentcategories.push({ Id: employmentcategory.Key, Name: employmentcategory.Value });

    });

    this.generalService.contracts.forEach((contract) => {

      this._contracts.push({ Id: contract.Key, Name: contract.Value });

    });
  }
}
