<swe-pageheader></swe-pageheader>
<div class="row justify-content-md-center py-2">
  <div class="col-12 col-md-3">
    <swe-card header="{{languageService.getItem(606)}}" [hasRefresh]="false" [hasCollapse]="false" *ngIf="!showverification&&!showsso">
      <div class="mb-3">
        <img sweImage alt="Logo" title="Logo" style="width:100%;" imageFolder="images" [imageFile]="permissionService.unauthpermissions.Logo" *ngIf="permissionService.unauthpermissions.Logo&&permissionService.unauthpermissions.Logo.length>0">
        <a href="https://www.swedetime.com" target="_blank" *ngIf="!permissionService.unauthpermissions.Logo||permissionService.unauthpermissions.Logo.length==0"><img alt="SwedeTime" src="assets/images/swedetime.png" /></a>
      </div>
      <swe-element [type]="'System.String'" [statusLabel]="0" [label]="languageService.getItem(7)" [placeholder]="languageService.getItem(7)" [(model)]="username" [setFocus]="focus"></swe-element>
      <swe-element [type]="'System.Password'" [statusLabel]="0" [label]="languageService.getItem(8)" [placeholder]="languageService.getItem(8)" [(model)]="password" (enterChange)="login()"></swe-element>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="login()" [disabled]="loading"><i class="bi bi-swe-fw bi-arrow-clockwise bi-swe-spin" *ngIf="loading" #btnLogin></i>{{languageService.getItem(9)}}</button>
      </div>
      <!--SSO-->
      <ng-container *ngIf="permissionService.unauthpermissions.SSO&&samlcontent.length>0">
        <div class="mb-3 text-center">
          <label>{{languageService.getItem(761)}}</label>
        </div>
        <div class="d-grid gap-2">
          <ng-container *ngFor="let saml of samlcontent">
            <button class="btn btn-primary" [ngClass]="{'swe-disabled-block': samlfocus > 0 && samlfocus != saml.Id}" (click)="loginsso(saml.Id)">{{languageService.getItem(733).replace('{0}', saml.Name)}}</button>
          </ng-container>
        </div>
      </ng-container>
      <!--Google SignIn-->
      <ng-container *ngIf="permissionService.unauthpermissions.GoogleSignIn">
        <div class="mb-3 text-center">
          <label>{{languageService.getItem(761)}}</label>
        </div>
        <div class="d-grid gap-2">
          <button class="btn btn-swe" (click)="logingoogle()" #BtnGoogleLogin><i class="bi bi-google"></i>&nbsp;Google</button>
        </div>
      </ng-container>
      <!--Registration-->
      <div class="mb-3" *ngIf="permissionService.unauthpermissions.Registration">
        <button class="btn btn-link" (click)="registration()">{{languageService.getItem(424)}}</button>
      </div>
      <!--Lost Password-->
      <div class="mb-3" *ngIf="!permissionService.unauthpermissions.HideLostPassword">
        <button class="btn btn-link" (click)="lostpassword()">{{languageService.getItem(438)}}</button>
      </div>
      <!--News-->
      <div class="alert alert-warning" *ngFor="let news of newscontent">
        <div><b>{{news.Title}}</b></div>
        <div *ngIf="news.Image.length>0"><img sweImage alt="{{news.Title}}" imageFolder="announcementimages" [imageFile]="news.Image"  /></div>
        <div class="swe-pre" [innerHTML]="wash(news.Body)"></div>
        <div *ngIf="news.Link.length>0"><a href="{{news.Link}}" target="_blank">{{news.LinkName}}</a></div>
        <div><i>{{news.Publish}}</i></div>
      </div>
      <!--Contact Info-->
      <div class="alert alert-warning" *ngIf="permissionService.unauthpermissions.SystemEmailTo&&permissionService.unauthpermissions.SystemEmailTo.length>0">
        <div><b>{{languageService.getItem(803)}}</b></div>
        <div>
          <div>{{languageService.getItem(804)}}</div>
          <a [attr.aria-label]="languageService.getItem(839)" href="mailto:{{permissionService.unauthpermissions.SystemEmailTo}}" target="_blank">{{permissionService.unauthpermissions.SystemEmailTo}}</a>
        </div>
      </div>
      <div class="float-end">
        <a href="https://www.swedetime.com" target="_blank" *ngIf="permissionService.unauthpermissions.Logo&&permissionService.unauthpermissions.Logo.length>0"><img alt="SwedeTime" src="assets/images/swedetime.png" /></a>
      </div>
    </swe-card>
    <swe-card header="{{getVerifyHeader()}}" [hasRefresh]="false" [hasCollapse]="false" *ngIf="showverification&&!showsso">
      <swe-element [type]="'System.String'" [statusLabel]="0" [label]="languageService.getItem(708)" [placeholder]="languageService.getItem(708)" [(model)]="code" (enterChange)="verify()"></swe-element>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="verify()">{{languageService.getItem(709)}}</button>
        <button class="btn btn-danger" (click)="back()">{{languageService.getItem(711)}}</button>
      </div>
    </swe-card>
    <swe-card [hasRefresh]="false" [hasCollapse]="false" *ngIf="showsso">
      <div class="mb-3">
        <label>{{languageService.getItem(734)}}</label>
      </div>
    </swe-card>
  </div>
</div>
