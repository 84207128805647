<swe-card header="{{languageService.getItem(51)}}" *ngIf="usermarkeddate" [(open)]="open" (refreshChange)="load()" [focus]="true" [hasCollapse]="false" (saveChange)="save()" [hasSave]="usermarkeddate.Access>1">
  <span swe-rightcommand>
  </span>
  <swe-infotext [text]="languageService.getItem(814)" [preventdrowning]="true"></swe-infotext>
  <!--DateTimes-->
  <div class="mb-3 row">
    <div class="swe-row-icon">
      <div class="swe-col-icon">
        <i class="bi bi-swe-fw bi-swe bi-clock-fill" [attr.title]="languageService.getItem(10)+' '+languageService.getItem(430)+' '+languageService.getItem(11)"></i>
      </div>
      <div class="swe-col-content">
        <swe-datetime [(start)]="usermarkeddate.Start" [(end)]="usermarkeddate.End" [labelStart]="languageService.getItem(10)" [labelEnd]="languageService.getItem(11)" [hideTime]="!permissionService.permissions.UserMarkedDateTime"></swe-datetime>
      </div>
    </div>
  </div>
  <!--Repeat-->
  <div *ngIf="!loading&&usermarkeddate.Id==0">
    <swe-card [header]="languageService.getItem(251)" [color]="'swe'" [open]="false" [hasRefresh]="false">
      <swe-element [type]="'System.MultiList'" [label]="languageService.getItem(254)" [statusLabel]="1" [(model)]="repeatdays" [items]="serieDayList" [container]="{isbit:true}"></swe-element>
      <swe-element [type]="'System.Date'" [label]="languageService.getItem(255)" [statusLabel]="1" [(model)]="repeatend"></swe-element>
    </swe-card>
  </div>
  <!--User-->
  <div class="mb-3 row" *ngIf="!loading">
    <div class="swe-row-icon">
      <div class="swe-col-icon">
        <i class="bi bi-swe-fw bi-swe bi-person-fill" [attr.title]="languageService.getItem(110)"></i>
      </div>
      <div class="swe-col-content">
        <swe-usersearch [(model)]="usermarkeddate.UserId" [displayname]="usermarkeddate.User" [onlyone]="true" [type]="'UserMarkedDate'" *ngIf="permissionService.permissions.UserMarkedDateSearch>0&&userId==0"></swe-usersearch>
        <span *ngIf="permissionService.permissions.UserMarkedDateSearch==0&&userId==0">{{usermarkeddate.User}}</span>
        <span *ngIf="userId>0">{{hiddenuser}}</span>
      </div>
    </div>
  </div>
  <!--Type-->
  <div class="mb-3 row" *ngIf="!loading">
    <div class="swe-row-icon">
      <div class="swe-col-icon">
        <div style="display:inline-block;width:10px;height:10px;border:1px solid #999999;" [ngStyle]="{'background-color': usermarkeddate.Color}"></div>
      </div>
      <div class="swe-col-content">
        <swe-element [(model)]="usermarkeddate.Type" (modelChange)="changeType()" [label]="languageService.getItem(15)" [items]="types" [type]="'System.List'"></swe-element>
      </div>
    </div>
  </div>
  <!--Profile-->
  <div *ngIf="!loading">
    <swe-profile [(model)]="usermarkeddate.Profile" (modelChange)="profiledone()" [id]="id"></swe-profile>
  </div>
  <!--Buttons-->
  <div class="d-grid gap-2" *ngIf="!loading">
    <span class="swe-click" (click)="createnew=!createnew">
      <i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-square': !createnew, 'bi-check-square': createnew}"></i>{{languageService.getItem(682)}}
    </span>
    <button class="btn btn-primary" (click)="save()" *ngIf="usermarkeddate.Access>1" #sweSaveElement>{{languageService.getItem(16)}}</button>
    <swe-confirm *ngIf="id>0&&usermarkeddate.Access>2" [body]="languageService.getItem(194)" (acceptChange)="delete()">
      <button class="btn btn-danger"><i class="bi bi-swe-fw bi-trash-fill"></i>&nbsp;{{languageService.getItem(17)}}</button>
    </swe-confirm>
  </div>
</swe-card>
