import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';

import { LanguageService } from '../_services/language.service';
import { PermissionService } from '../_services/permission.service';
import { GeneralService } from '../_services/general.service';
import { DataService } from '../_services/data.service';
import { SettingService } from '../_services/setting.service';
import { ListService } from '../_services/list.service';


@Component({
  selector: 'app-bookingdetail',
  templateUrl: './bookingdetail.component.html'
})
export class BookingDetailComponent implements OnInit, OnDestroy {

  private _id: number;
  private _userid: number;
  private _levelid: number;
  private _bookingdata: any = {};
  private _objecttoobjects: any[] = [];

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public generalService: GeneralService,
    private listService: ListService,
    private dataService: DataService,
    private settingService: SettingService,
    private route: ActivatedRoute,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this._id = Number(params.get('id'));
      this.settingService.lastbooking = this._id;
      this._userid = Number(params.get('userid'));
      this._levelid = Number(params.get('levelid'));

      this.load();
    });

  }

  ngOnDestroy() {
    this.dataService.tokenRequest('/api/v1/bookings/' + this._id + '/close', 'POST')
      .subscribe(res => {

      });
  }


  /*Properties*/
  public get cards() {
    return {
      data: true,
      users: !this.permissionService.permissions.BookingProfile,
      availableusers: false,
      requests: false,
      logs: false,
      systemlogs: false,
      messagelogs: false
    };
  }
  public get id() {
    return this._id;
  }
  public get userid() {
    return this._userid;
  }
  public get levelid() {
    return this._levelid;
  }
  public get bookingdata() {
    return this._bookingdata;
  }
  public get objecttoobjects() {
    return this._objecttoobjects;
  }



  /*Methods*/
  public manageBookingdata(data) {
    this._bookingdata = data;
  }
  public create() {
    this.router.navigate(['/bookings/0']);
  }
  public gotoLevel() {
    this.router.navigate(['/levels/' + this._bookingdata.LevelId]);
  }




  //Functions
  private load() {

    this._objecttoobjects = this.listService.formatArray(this.generalService.propertycategories, ['Booking'], 'Type');

  }

}
