<swe-card header="{{languageService.getItem(385)}}" [(open)]="open" [hasRefresh]="false">
  <span swe-rightcommand></span>
  <div class="mb-3" *ngIf="icalurl.length>0">
    <b>{{languageService.getItem(387)}}</b>
    <div><a target="_blank" [href]="sanitize('webcal://' + generalService.api + 'calendars/user.ics?id=' + icalurl)">webcal://{{generalService.api}}calendars/user.ics?id={{icalurl}}</a></div>
  </div>
  <div class="mb-3" *ngIf="icallevelurl.length>0">
    <b>{{languageService.getItem(386)}}</b>
    <div><a target="_blank" [href]="sanitize('webcal://' + generalService.api + 'calendars/level.ics?id=' + icallevelurl)">webcal://{{generalService.api}}calendars/level.ics?id={{icallevelurl}}</a></div>
  </div>
  <div class="mb-3">
    <b>Google & Android</b>
    <p>{{languageService.getItem(523)}}</p>
  </div>
  <div class="mb-3">
    <b>Iphone</b>
    <p>{{languageService.getItem(524)}}</p>
  </div>
  <div class="mb-3">
    <b>Outlook</b>
    <p>{{languageService.getItem(525)}}</p>
  </div>
</swe-card>
