import { Component, HostListener, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';

import { AuthenticationService } from '../_services/authentication.service';
import { PermissionService } from '../_services/permission.service';
import { LanguageService } from '../_services/language.service';
import { MenuService } from '../_services/menu.service';
import { GeneralService } from '../_services/general.service';
import { ListService } from '../_services/list.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TokenService } from '../_services/token.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit, OnDestroy {

  @ViewChild('sweFocusElement') focusElement: ElementRef;

  private unsubsribe$ = new Subject<void>();
  private _deferredPrompt: any;
  private _showButton = false;
  private _showText = false;
  
  constructor(
    public authenticationService: AuthenticationService,
    public permissionService: PermissionService,
    public languageService: LanguageService,
    public menuService: MenuService,
    private tokenService: TokenService,
    private generalService: GeneralService,
    private listService: ListService
  ) {

    menuService.onMenuChange$
      .pipe(takeUntil(this.unsubsribe$))
      .subscribe((refresh) => {

        if (refresh) {
          setTimeout(() => {
            if (this.focusElement) {
              let element = this.focusElement.nativeElement;
              element.focus();
            }
          }, 0); //Create a macrotask that will run in the next VM turn
        }

      }); 

  }

  ngOnDestroy() {
    this.unsubsribe$.next();
    this.unsubsribe$.complete();
  }

  ngOnInit() {
    if (this.generalService.isIOS) {
      let isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator['standalone']);
      if (!isInStandaloneMode) {
        this._showButton = true;
      }
    }
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e: { preventDefault: () => void; }) {
    console.log(e);
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this._deferredPrompt = e;
    this._showButton = true;
  }



  //Properties
  public get showButton() {
    return this._showButton;
  }
  public get showText() {
    return this._showText;
  }
  public get adminpermission() {

    if (!this.permissionService.permissions || this.permissionService.user.UpdatePassword) {
      return false;
    }

    let impersonateAccess = (this.permissionService.permissions.Impersonate || this.permissionService.isimpersonate() > 0);
    let newsAccess = (this.permissionService.permissions.News > 1);
    let importAccess = (this.permissionService.permissions.Import > 0);
    let propertiesAccess = (this.permissionService.permissions.Properties > 0);
    let holidayAccess = (this.permissionService.permissions.Holiday > 0);
    let overtimeAccess = (this.permissionService.permissions.Overtime > 0);
    let contractAccess = (this.permissionService.permissions.Contract > 0);
    let integrationAccess = (this.permissionService.permissions.Integration > 0);

    return impersonateAccess || newsAccess || importAccess || propertiesAccess || holidayAccess || overtimeAccess || contractAccess || integrationAccess;
  }
  public permission() {
    if (!this.permissionService.permissions || this.permissionService.user.UpdatePassword) {
      return false;
    }

    return true;
  }
  public get categories() {
    return this.listService.formatArray(this.generalService.propertycategories, ['Object'], 'Extra');
  }
  public get version() {
    return this.tokenService.getToken('version');
  }


  //Methods
  public manageMenu() {
    this.menuService.closeMenu();
  }
  public addToHomeScreen() {

    if (this.generalService.isIOS) {
      this._showText = !this._showText;
    }
    else {
      // hide our user interface that shows our A2HS button
      this._showButton = false;
      // Show the prompt
      this._deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      this._deferredPrompt.userChoice
        .then((choiceResult: { outcome: string; }) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted');
          } else {
            console.log('User dismissed');
          }
          this._deferredPrompt = null;
        });
    }
  }



  //Functions
  
}

export class AdminPermission {
  visible: boolean;
  impersonate: boolean;
}
