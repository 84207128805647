import { Component, OnChanges, Input, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChartType } from 'chart.js';
import { MultiDataSet, Label } from 'ng2-charts';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LanguageService } from '../../_services/language.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';
import { SettingService } from '../../_services/setting.service';
import { PropertyService } from '../../_services/property.service';


@Component({
  selector: 'swe-userstatistics',
  templateUrl: './userstatistics.component.html'
})
export class UserStatisticsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() id: number;
  @Input() open: boolean;

  private unsubsribe$ = new Subject<void>();
  private _charttype: ChartType = 'doughnut';
  private _shiftdata: MultiDataSet = [];
  private _smsdata: MultiDataSet = [];
  private _shiftlabels: Label = [];
  private _smslabels: Label = [];
  private _shiftcolors: any = [];
  private _smscolors: any = [];
  private _shiftoptions: any = {};
  private _smsoptions: any = {};
  private _loading: boolean;

  constructor(public languageService: LanguageService, private dataService: DataService, private alertService: AlertService, private settingService: SettingService, private propertyService: PropertyService, private route: ActivatedRoute, private router: Router) {
    settingService.onRefresh$
      .pipe(takeUntil(this.unsubsribe$))
      .subscribe(() => {
      this.load();
    });
  }

  ngOnDestroy() {
    this.unsubsribe$.next();
    this.unsubsribe$.complete();
  }

  ngOnInit() {
    this._shiftlabels = [
      this.languageService.getItem(242) + ': \n',
      this.languageService.getItem(243) + ': \n',
      this.languageService.getItem(247) + ': \n'
    ];

    this._shiftcolors = [
      {
        //backgroundColor: ['#4cd964', '#e6b800', '#ff3b30']
        backgroundColor: ['#198754', '#ffc107', '#dc3545']
      }
    ];

    this._shiftoptions = {
      legend: {
        align: 'start'
      },
      
    }

    this._smslabels = [
      this.languageService.getItem(245) + ': \n',
      this.languageService.getItem(244) + ': \n',
      this.languageService.getItem(246) + ': \n'
    ];

    this._smscolors = [
      {
        //backgroundColor: ['#4cd964', '#e6b800', '#ff3b30']
        backgroundColor: ['#198754', '#ffc107', '#dc3545']
      }
    ];

    this._smsoptions = {
      legend: {
        align: 'start'
      }
    }
  }

  ngOnChanges() {
    if (this.open) {
      this.load();
    }
  }

  /*Properties*/
  public get loading() {
    return this._loading;
  }
  public get charttype() {
    return this._charttype;
  }
  public get shiftdata() {
    return this._shiftdata;
  }
  public get smsdata() {
    return this._smsdata;
  }
  public get shiftlabels() {
    return this._shiftlabels;
  }
  public get smslabels() {
    return this._smslabels;
  }
  public get shiftcolors() {
    return this._shiftcolors;
  }
  public get smscolors() {
    return this._smscolors;
  }
  public get shiftoptions() {
    return this._shiftoptions;
  }
  public get smsoptions() {
    return this._smsoptions;
  }
  public chartPlugins = [{
    afterLayout: function (chart) {
      chart.legend.legendItems.forEach(
        (label) => {
          let value = chart.data.datasets[0].data[label.index];

          label.text += value;

          return label;
        }
      )
    }
  }];


  /*Methods*/
  public load() {

    if (!this.open) { return; }

    this._loading = true;

    let filter = {
      Start: this.settingService.start('booking'),
      End: this.settingService.end('booking'),
      CheckIn: this.settingService.booking.checkin,
      Reservation: this.settingService.booking.reservation,
      ReplaceableAsNoSlot: this.settingService.booking.replaceableasnoslot,
      LevelList: this.settingService.levelList(2),
      StatusList: this.settingService.booking.status
    };

    this.dataService.tokenRequest('/api/v1/users/' + this.id + '/statistics', 'POST', filter)
      .subscribe(res => {
    
        this._shiftdata = [res.Shifts, res.Standby, res.Abscent];
        this._smsdata = [res.Yes, res.Sms, res.No];

        this._loading = false;
      });

    
  }
  

}
