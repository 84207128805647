<swe-card header="{{languageService.getItem(38)}}" *ngIf="level" [(open)]="open" (refreshChange)="load()" [focus]="true" (saveChange)="save()" [hasSave]="level.Access>1">
  <span swe-rightcommand>
  </span>
  <!--Profile-->
  <div *ngIf="!loading">
    <swe-profile [(model)]="level.Profile" (modelChange)="profiledone()" [id]="id"></swe-profile>
  </div>
  <!--Parent-->
  <div *ngIf="!loading&&permissionService.permissions.LevelAdmin">
    <swe-element [statusLabel]="1" [(model)]="level.ParentId" [display]="level.Parent" [label]="languageService.getItem(235)" [optional]="true" [type]="'System.LevelSearch'"></swe-element>
  </div>
  <!--LevelGroup-->
  <div *ngIf="!loading&&permissionService.permissions.LevelAdmin">
    <swe-element [statusLabel]="1" [optional]="true" [(model)]="level.Group" [label]="languageService.getItem(236)" [items]="generalService.levelgroups" [type]="'System.List'"></swe-element>
  </div>
  <swe-card [header]="languageService.getItem(112)" [color]="'swe'" [open]="false" [hasRefresh]="false" *ngIf="!loading&&(level.ExternalAccess>0||level.ContractAccess>0)">
    <swe-element [statusLabel]="1" [optional]="true" [(model)]="level.ExternalCategories" [label]="languageService.getItem(517)" [items]="employmentcategories" [disabled]="level.ExternalAccess<2" [type]="'System.MultiList'" *ngIf="level.ExternalAccess>0"></swe-element>
    <swe-element [statusLabel]="1" [optional]="true" [(model)]="level.ContractId" [label]="languageService.getItem(953)" [items]="contracts" [disabled]="level.ContractAccess<2" [type]="'System.List'" *ngIf="level.ContractAccess>0"></swe-element>
  </swe-card>
  <!--Buttons-->
  <div class="d-grid gap-2" *ngIf="!loading">
    <button class="btn btn-primary" (click)="save()" *ngIf="level.Access>1" #sweSaveElement>{{languageService.getItem(16)}}</button>
    <button class="btn btn-success" (click)="connect()" *ngIf="level.ConnectAccess>1&&!level.IsConnected">{{languageService.getItem(584)}}</button>
    <button class="btn btn-success" (click)="disconnect()" *ngIf="level.ConnectAccess>2&&level.IsConnected">{{languageService.getItem(585)}}</button>
    <swe-confirm *ngIf="id>0&&level.Access>2" [body]="languageService.getItem(193)" (acceptChange)="delete()">
      <button class="btn btn-danger">{{languageService.getItem(17)}}</button>
    </swe-confirm>
  </div>
</swe-card>
