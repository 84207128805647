import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { ListService } from '../../_services/list.service';
import { DataService } from '../../_services/data.service';
import { ViewCacheService } from '../../_services/viewcache.service';
import { AlertService } from '../../_services/alert.service';
import { Location } from '@angular/common';
import { SettingService } from '../../_services/setting.service';
import { GeneralService } from '../../_services/general.service';


@Component({
  selector: 'swe-multitimereports',
  templateUrl: './multitimereports.component.html'
})
export class MultiTimereportsComponent implements OnInit {

  private _chosenbookings: any = {};
  private _status: number = 0;
  private _externalcategory: number = 0;
  private _externalcategories: any[] = [];
  private _yesOrNoList: any[] = [];
  private _salarysent: number = 0;
  private _invoicesent: number = 0;
  private _bookingtype: number = -1;
  private _properties: any[] = [];
  private _chosenproperties: any[] = [];
  private _bookingTypeList: any[] = [];

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public generalService: GeneralService,
    private dataService: DataService,
    private listService: ListService,
    private viewCacheService: ViewCacheService,
    private alertService: AlertService,
    private settingService: SettingService,
    private location: Location) {

    //Reset
    settingService.initView();
  }

  ngOnInit() {
    this._chosenbookings = this.viewCacheService.get('multi_timereports');

    this.load();
  }


  //Properties
  public get status() {
    return this._status;
  }
  public set status(val:number) {
    this._status = val;
  }
  public get externalcategory() {
    return this._externalcategory;
  }
  public set externalcategory(val: number) {
    this._externalcategory = val;
  }
  public get externalcategories() {
    return this._externalcategories;
  }
  public get yesOrNoList() {
    return this._yesOrNoList;
  }
  public get salarysent() {
    return this._salarysent;
  }
  public set salarysent(val) {
    this._salarysent = val;
  }
  public get invoicesent() {
    return this._invoicesent;
  }
  public set invoicesent(val) {
    this._invoicesent = val;
  }
  public get bookingtype() {
    return this._bookingtype;
  }
  public set bookingtype(val: number) {
    this._bookingtype = val;
  }
  public get bookingTypeList() {
    return this._bookingTypeList;
  }
  public get properties() {
    return this._properties;
  }
  public get chosenproperties() {
    return this._chosenproperties;
  }




  //Methods
  public confirmsave() {
    let confirmtext = '';

    //Be sure that this is an integer
    let iStatus = parseInt(this._status.toString());
    let iExternalCategory = parseInt(this._externalcategory.toString());
    let iSalarySent = parseInt(this._salarysent.toString());
    let iInvoiceSent = parseInt(this._invoicesent.toString());
    let iBookingType = parseInt(this._bookingtype.toString());

    if (iStatus > 0) {
      let statusname = '';
      let statusObj = this.listService.find(this.generalService.timereportstatus, 'Id', iStatus);
      if (statusObj) {
        statusname = statusObj.Name;
      }

      if (confirmtext.length > 0) {
        confirmtext += ' ' + this.languageService.getItem(430) + ' '; //and
      }
      confirmtext += this.languageService.getItem(427).replace('{0}', statusname);
    }

    if (iExternalCategory > 0) {
      let externalcategoryname = '';
      let externalcategoryObj = this.listService.find(this.generalService.employmentcategories, 'Key', iExternalCategory);
      if (externalcategoryObj) {
        externalcategoryname = externalcategoryObj.Value;
      }

      if (confirmtext.length > 0) {
        confirmtext += ' ' + this.languageService.getItem(430) + ' '; //and
      }
      confirmtext += this.languageService.getItem(1058).replace('{0}', externalcategoryname);
    }

    if (iSalarySent > 0) {
      if (confirmtext.length > 0) {
        confirmtext += ' ' + this.languageService.getItem(430) + ' '; //and
      }
      confirmtext += this.languageService.getItem(1147);
    }

    if (iInvoiceSent > 0) {
      if (confirmtext.length > 0) {
        confirmtext += ' ' + this.languageService.getItem(430) + ' '; //and
      }
      confirmtext += this.languageService.getItem(1148);
    }

    //if (iBookingType > -1) {
    //  let bookingtypename = '';
    //  let bookingTypeObj = this.listService.find(this._bookingTypeList, 'Id', iBookingType);
    //  if (bookingTypeObj) {
    //    bookingtypename = bookingTypeObj.Name;
    //  }
    //  if (confirmtext.length > 0) {
    //    confirmtext += ' ' + this.languageService.getItem(430) + ' '; //and
    //  }
    //  confirmtext += this.languageService.getItem(1050).replace('{0}', bookingtypename);
    //}

    if (this._chosenproperties.length > 0) {
      if (confirmtext.length > 0) {
        confirmtext += ' ' + this.languageService.getItem(430) + ' '; //and
      }
      confirmtext += this.languageService.getItem(428).replace('{0}', this._chosenproperties.length);
    }
    
    return this.languageService.getItem(668).replace('{0}', confirmtext);
  }
  public save() {
    
    if (this._chosenbookings) {

      let profile: any[] = [];
      this._chosenproperties.forEach(property => {
        profile.push({ Property: property.Id, Value: property.Val });
      });

      let tmp_status: any = this._status;
      if (!tmp_status && tmp_status.length == 0) {
        tmp_status = 0;
      }

      let tmp_externalcategory: any = this._externalcategory;
      if (!tmp_externalcategory && tmp_externalcategory.length == 0) {
        tmp_externalcategory = 0;
      }

      let tmp_bookingtype: any = this._bookingtype;
      if (!tmp_bookingtype && tmp_bookingtype.length == 0) {
        tmp_bookingtype = -1; //No change
      }

      let tmp_salarysent: any = this._salarysent;
      if (!tmp_salarysent && tmp_salarysent.length == 0) {
        tmp_salarysent = 0;
      }

      let tmp_invoicesent: any = this._invoicesent;
      if (!tmp_invoicesent && tmp_invoicesent.length == 0) {
        tmp_invoicesent = 0;
      }

      let filter = {
        IdList: this._chosenbookings.value,
        Status: tmp_status,
        ExternalCategory: tmp_externalcategory,
        SalarySent: tmp_salarysent,
        InvoiceSent: tmp_invoicesent,
        BookingType: tmp_bookingtype,
        Profiles: profile
      };

      this.dataService.tokenRequest('/api/v1/timereports/multi', 'POST', filter, 'text')
        .subscribe((res) => {
          this.alertService.Add({ message: res, type: 'success' });
          this.location.back();
        });
    }
    else {
      this.alertService.Add({ message: this.languageService.getItem(666), type: 'danger' });
    }
  }
  


  //Functions
  private load() {

    this.generalService.employmentcategories.forEach((employmentcategory) => {

      this._externalcategories.push({ Id: employmentcategory.Key, Name: employmentcategory.Value });

    });

    this._yesOrNoList = [];
    this._yesOrNoList.push({ Id: 1, Name: this.languageService.getItem(190) });
    this._yesOrNoList.push({ Id: 2, Name: this.languageService.getItem(146) });


    this.dataService.tokenRequest('/api/v1/properties/type/multitimereport', 'GET', {})
      .subscribe((res) => {

        let groupbyList: any[] = [];
        res.forEach((item) => {
          let groupby = this.listService.find(groupbyList, 'Name', item.Category);
          if (groupby) {
            groupby.Items.push({ Id: item.Id, Name: item.Name });
          }
          else {
            groupbyList.push({ Name: item.Category, Items: [{ Id: item.Id, Name: item.Name }] });
          }
        });

        this._properties = groupbyList;
      });

    if (this._bookingTypeList.length == 0) {
      let list = [{ Id: 1, Name: this.languageService.getItem(476) }];
      if (this.permissionService.permissions.Replaceable) {
        list.push({ Id: 0, Name: this.languageService.getItem(423) });
      }
      if (this.permissionService.permissions.Standby > 0) {
        list.push({ Id: 2, Name: this.languageService.getItem(279) });
      }
      if (this.permissionService.permissions.Abscent > 0) {
        list.push({ Id: 3, Name: this.languageService.getItem(280) });
      }

      this._bookingTypeList = list;
    }
   
  }

}
