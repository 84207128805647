<swe-pageheader [header]="languageService.getItem(410)"></swe-pageheader>
<div class="row d-block py-2">
  <div class="col-12 col-md-4 float-start">
    <swe-card header="{{languageService.getItem(2)}}" [open]="true" [hasRefresh]="false" [hasCollapse]="false">
      <!--Belongings-->
      <swe-card header="{{languageService.getItem(469)}}" [open]="true" [hasRefresh]="false" [hasCollapse]="true">
        <swe-element [(model)]="belongoption" [label]="languageService.getItem(843)" [items]="belongoptions" [type]="'System.RadioList'" *ngIf="permissionService.permissions.AdvancedBelonging"></swe-element>
        <swe-element [(model)]="belongoption" [label]="languageService.getItem(82)" [statusLabel]="2" [items]="belongoptions" [type]="'System.RadioList'" *ngIf="!permissionService.permissions.AdvancedBelonging"></swe-element>
        <swe-element [(model)]="roles" [label]="languageService.getItem(82)" [items]="availableroles" [type]="'System.MultiList'"></swe-element>
        <swe-element [(model)]="belongoption" [label]="languageService.getItem(3)" [statusLabel]="2" [items]="belongoptions" [type]="'System.RadioList'" *ngIf="!permissionService.permissions.AdvancedBelonging"></swe-element>
        <swe-levelsearch [bottomMargin]="3" [onlyone]="false" (modelChange)="addLevel($event)" [label]="languageService.getItem(3)" [chosen]="levels" [type]="'User'"></swe-levelsearch>
        <div class="list-group pt-0" *ngIf="levels.length>0">
          <div class="list-group-item" *ngFor="let level of levels">
            <span class="swe-grayed-out">{{level.Path}}</span>{{level.Name}}
            <i class="bi bi-swe-fw bi-swe bi-x-lg bi-swe-pull-right swe-click" (click)="removeLevel(level)"></i>
          </div>
        </div>
      </swe-card>
      <!--Activation-->
      <swe-element [(model)]="activationoption" [label]="languageService.getItem(466)" [statusLabel]="2" [items]="activationoptions" [type]="'System.RadioList'"></swe-element>
      <!--Main Level-->
      <swe-element [(model)]="mainleveloption" [label]="languageService.getItem(1055)" [statusLabel]="2" [items]="mainleveloptions" [type]="'System.RadioList'"></swe-element>
      <swe-element [type]="'System.LevelSearch'" [label]="languageService.getItem(950)" [statusLabel]="0" [(model)]="mainlevel" [optional]="true" [disabled]="mainleveloption!=1"></swe-element>
      <!--Contract-->
      <swe-element [type]="'System.List'" [label]="languageService.getItem(953)" [statusLabel]="2" [(model)]="contractId" [items]="contracts" [optional]="true"></swe-element>
      <!--Properties-->
      <swe-properties [label]="languageService.getItem(415)" [statusLabel]="2" [properties]="properties" [items]="chosenproperties"></swe-properties>
      <div class="d-grid gap-2">
        <!--Buttons-->
        <swe-confirm [body]="confirmsave()" (acceptChange)="save()">
          <button class="btn btn-primary"><i class="bi bi-swe-fw bi-files"></i>&nbsp;{{languageService.getItem(470)}}</button>
        </swe-confirm>
        <swe-confirm [body]="languageService.getItem(686)" (acceptChange)="delete()">
          <button class="btn btn-danger"><i class="bi bi-swe-fw bi-trash-fill"></i>&nbsp;{{languageService.getItem(685)}}</button>
        </swe-confirm>
      </div>
    </swe-card>
  </div>
</div>  
