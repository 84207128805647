<swe-pageheader [header]="languageService.getItem(4)">
  <span swe-hits>
    &nbsp;(#{{id}})
    <a href="javascript:void(0)" class="swe-no-link" *ngIf="id>0" (click)="gotoLevel()" [attr.title]="languageService.getItem(1125)"><i class="bi bi-swe-fw bi-diagram-3-fill"></i></a>
    <i class="bi bi-swe-fw bi-plus-lg bi-swe-pull-right swe-click" (click)="create()" *ngIf="permissionService.permissions.Bookings>1&&id>0"></i>
  </span>
</swe-pageheader>
<div class="row d-block py-2">
  <div class="col-12 col-md-4 float-start">
    <swe-bookingdata [id]="id" [(open)]="cards.data" [userid]="userid" [levelid]="levelid" (bookingdataChange)="manageBookingdata($event)"></swe-bookingdata>
  </div>
  <div class="col-12 col-md-8 float-start" *ngIf="id>0">
    <swe-bookingusers [id]="id" [(open)]="cards.users" [bookingdata]="bookingdata"></swe-bookingusers>
  </div>
  <div class="col-12 col-md-8 float-start">
    <swe-adminrule [rulelist]="['SweBookingUsers']"></swe-adminrule>
  </div>
  <div class="col-12 col-md-8 float-start" *ngIf="id>0&&bookingdata.UsersAccess>0">
    <swe-bookingavailableusers [id]="id" [(open)]="cards.availableusers" [bookingdata]="bookingdata"></swe-bookingavailableusers>
  </div>
  <div class="col-12 col-md-8 float-start" *ngIf="id>0&&bookingdata.RequestAccess>0">
    <swe-generalrequests [bookingid]="id" [(open)]="cards.requests" [data]="bookingdata"></swe-generalrequests>
  </div>
  <ng-container *ngFor="let obj of objecttoobjects">
    <div class="col-12 col-md-8 float-start" *ngIf="id>0">
      <swe-objecttoobjects [id]="id" [data]="obj" [open]="false"></swe-objecttoobjects>
    </div>
  </ng-container>
  <div class="col-12 col-md-8 float-start" *ngIf="id>0&&bookingdata.LogAccess>0">
    <swe-card header="{{languageService.getItem(400)}}" [(open)]="cards.logs" [hasRefresh]="false">
      <swe-generallogs [id]="id" [(open)]="cards.systemlogs" [type]="'booking'" [data]="bookingdata"></swe-generallogs>
      <swe-generalmessages [id]="id" [(open)]="cards.messagelogs" [type]="'booking'" [data]="bookingdata"></swe-generalmessages>
    </swe-card>
  </div>
</div>
  <!--To make float behavior (fill available space), use [d-block] on row and [float-start] on columns-->
