<swe-card header="{{data.Value}}" [(open)]="open" (refreshChange)="load(false)" (openChange)="load(false)" *ngIf="permissionService.hasObject(data.Key)>0">
  <span swe-hits *ngIf="open">&nbsp;({{hits}}<span *ngIf="more">+</span>)</span>
  <span swe-rightcommand *ngIf="permissionService.hasObject(data.Key)>1">
    <i class="bi bi-swe-fw bi-plus-lg bi-swe-pull-right swe-click" (click)="goto({Id:0}, $event)"></i>
  </span>
  <!--Table-->
  <div class="table-responsive">
    <table class="table" *ngIf="objects.length>0">
      <thead>
        <tr>
          <th style="border-top:0; border-bottom:0;" *ngIf="objects[0].OneToOne">&nbsp;</th>
          <th style="border-top:0; border-bottom:0;" *ngFor="let header of objects[0].Profile">
            {{propertyService.getProperty(header.Property).Name}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr style="border-top:0; border-bottom:0;" *ngFor="let row of objects" (click)="row.Access>1&&goto(row, $event)" [ngClass]="{'swe-click': (row.Access>1)}">
          <td *ngIf="row.OneToOne">{{row.ObjectName}}</td>
          <td *ngFor="let profile of row.Profile" [ngSwitch]="profile.Type">
            <span *ngSwitchDefault>{{profile.FormatValue}}</span>
            <span *ngSwitchCase="'System.Link'"><a [target]="'_blank'" [href]="profile.Url">{{profile.FormatValue}}</a></span>
            <span *ngSwitchCase="'System.Boolean'"><i class="bi bi-fw" [ngClass]="{'bi-check-square': profile.FormatValue, 'bi-square': !profile.FormatValue}"></i></span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</swe-card>
