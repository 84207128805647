import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LanguageService } from '../_services/language.service';
import { DateTimeService } from '../_services/datetime.service';
import { SettingService } from '../_services/setting.service';



@Component({
  selector: 'app-requestdetail',
  templateUrl: './requestdetail.component.html'
})
export class RequestDetailComponent implements OnInit {

  private _id: number = 0;
  
  constructor(
    public languageService: LanguageService,
    public dateTimeService: DateTimeService,
    public settingService: SettingService,
    private route: ActivatedRoute
  ) {

    settingService.initView();

  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      //Id
      this._id = Number(params.get('id'));
    });
  }

  /*Properties*/
  public get id() {
    return this._id;
  }
  



  //Methods



  //Functions
  
  
}
