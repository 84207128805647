import { Injectable, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MenuService } from './menu.service';

@Injectable({ providedIn: 'root' })
export class SettingService {

  //Class members
  private _general: GeneralSetting;
  private _booking: BookingSetting;
  private _timeline: TimelineSetting;
  private _user: UserSetting;
  private _level: LevelSetting;
  private _message: MessageSetting;
  private _log: LogSetting;
  private _property: PropertySetting;
  private _report: ReportSetting;
  private _holiday: HolidaySetting;

  //Events
  public onSave$ = new EventEmitter<any>();
  public onRefresh$ = new EventEmitter<any>();
  public onViewRefresh$ = new EventEmitter<any>();
  public onFilterReset$ = new EventEmitter<any>();

  //Defaults
  private _visible: number = 0; //bitflag
  private _hidden: {};
  private _categories: any[] = [];
  private _filtercategory: number = 0; //Objects
  private _watchdog: any[] = [];
  private _difflist: any[] = [];
  private _defaultdatetimespan: number = 1;
  private _defaultbooking: any = { start: this.todayStart(), end: this.todayEnd(), shifttype: '-1', reservation: '0', personal: '0', availability: -1, bookingtype: [], levelgroups: [] };
  private _defaulttimeline: any = { groupby: 0, timelineshow: 1, includereferences: false, exactrendering: true, showemptyrows: true, sumoptions: 0, markedhide: false };
  private _defaultuser: any = { roles: [], levelgroups: [], availabilitystart: this.todayStart(), availabilityend: this.todayStart(), employmentstart: this.todayStart(), employmentend: this.yearEnd() };
  private _defaultlevel: any = { levelgroups: [] };
  private _defaultmessage: any = { start: this.todayStart(), end: this.todayEnd() };
  private _defaultlog: any = { start: this.todayStart(), end: this.todayEnd() };
  private _defaultproperty: any = {};
  private _defaultreport: any = { visibility: 1 };
  private _defaultholiday: any = { start: this.yearStart(), end: this.yearEnd() };
  private _lastbooking: number = 0;
  private _lastuser: number = 0;
  private _lastlevel: number = 0;
  private _lastobject: number = 0;
  private _lasttimereport: number = 0;
  private _lastproperty: number = 0;
  private _searchcriteriarules: string = '';
  private _pinned: boolean = false;
  private _adminmode: boolean = false;
  private _isrefreshing: boolean = false;
  private _searchlocked: any[] = [];


  //Constructor
  constructor(
    private datePipe: DatePipe,
    private menuService: MenuService
  ) {
    this.defaultGeneral();
    this.defaultBooking();
    this.defaultTimeline();
    this.defaultUser();
    this.defaultLevel();
    this.defaultMessage();
    this.defaultLog();
    this.defaultProperty();
    this.defaultReport();
    this.defaultHoliday();

    this.initWatchdog();
  }

  //Properties
  public set SearchLocked(val) {
    this._searchlocked = val;
  }
  public get general() {
    return this._general;
  }
  public get booking() {
    return this._booking;
  }
  public get defaultbooking() {
    return this._defaultbooking;
  }
  public get timeline() {
    return this._timeline;
  }
  public get defaulttimeline() {
    return this._defaulttimeline;
  }
  public get user() {
    return this._user;
  }
  public get defaultuser() {
    return this._defaultuser;
  }
  public get level() {
    return this._level;
  }
  public get defaultlevel() {
    return this._defaultlevel;
  }
  public get message() {
    return this._message;
  }
  public get log() {
    return this._log;
  }
  public get property() {
    return this._property;
  }
  public get report() {
    return this._report;
  }
  public get holiday() {
    return this._holiday;
  }
  public get visible() {
    return this._visible;
  }
  public get hidden() {
    let hide: boolean = false;

    return hide;
  }
  public get difflist() {
    return this._difflist.filter(diff => { return this.isVisible(diff.visible)&&!this.isHidden(diff.type,diff.key) });
  }
  public get lastbooking() { return this._lastbooking; }
  public set lastbooking(value) { this._lastbooking = value; }
  public get lastuser() { return this._lastuser; }
  public set lastuser(value) { this._lastuser = value; }
  public get lastlevel() { return this._lastlevel; }
  public set lastlevel(val) { this._lastlevel = val; }
  public get lastobject() { return this._lastobject; }
  public set lastobject(val) { this._lastobject = val; }
  public get lasttimereport() { return this._lasttimereport; }
  public set lasttimereport(val) { this._lasttimereport = val; }
  public get lastproperty() { return this._lastproperty; }
  public set lastproperty(val) { this._lastproperty = val; }
  public get searchcriteriarules() { return this._searchcriteriarules; }
  public set searchcriteriarules(val) { this._searchcriteriarules = val; }
  public get pinned() {
    return this._pinned;
  }
  public set pinned(val) {
    this._pinned = val;
  }
  public get adminmode() {
    return this._adminmode;
  }
  public get isrefreshing() {
    return this._isrefreshing;
  }


  public isDevice(size: string[]) {
    let res:boolean = false;

    if (size) {
      size.forEach((item) => {

        if (!res) {
          if (item == 'xs') {
            res = (window.innerWidth < 576);
          }
          if (item == 'sm') {
            res = (window.innerWidth >= 576 && window.innerWidth < 768);
          }
          if (item == 'md') {
            res = (window.innerWidth >= 768 && window.innerWidth < 992);
          }
          if (item == 'lg') {
            res = (window.innerWidth >= 992 && window.innerWidth < 1200);
          }
          if (item == 'xl') {
            res = (window.innerWidth >= 1200);
          }
        }
      });
    }

    return res;
  }

  //View filter visibility-------------------------------------------
  public initView(visible: number = 0, categories: string[] = [], hiddenList: HiddenFilter[] = [], filtercategory: number = 0) {
    this._visible = visible;

    this._categories = categories;
    this._filtercategory = filtercategory;

    let hidden = {};
    hiddenList.forEach((filter) => {
      hidden[filter.type] = hidden[filter.type] || {};
      hidden[filter.type][filter.key] = true;
    });
    this._hidden = hidden;

    if (visible == 0) { this.menuService.closeSearch(); }
    else if (this._pinned) { this.menuService.openSearch(); }
  }
  public isVisible(bit) {

    return ((this._visible & bit) == bit);
  }
  public isHidden(type: string, key: string) {
    return (this._hidden[type] && this._hidden[type][key]);
  }
  public hasCategory(category) {

    return this._categories.indexOf(category) > -1;
  }
  public get filtercategory() {
    return this._filtercategory
  }
  //-----------------------------------------------------------------


  //Resets-----------------------------------------------------------
  public reset(doRefresh: boolean = true, filterReset: boolean = false) {
    this.defaultBooking();
    this.defaultTimeline();
    this.defaultUser();
    this.defaultLevel();
    this.defaultMessage();
    this.defaultLog();
    this.defaultProperty();
    this.defaultHoliday();
    this.check();

    if (doRefresh) {
      this.refresh();
    }
    if (!this._pinned) {
      this.menuService.closeSearch();
    }

    if (filterReset) {
      this.onFilterReset$.emit(true);
    }
  }
  public resetBooking(e) {
    if (e) { e.stopPropagation(); }

    this.defaultBooking();
    this.check();
  }
  public resetTimeline(e) {
    if (e) { e.stopPropagation(); }

    this.defaultTimeline();
    this.check();
  }
  public resetUser(e) {
    if (e) { e.stopPropagation(); }

    this.defaultUser();
    this.check();
  }
  public resetLevel(e) {
    if (e) { e.stopPropagation(); }

    this.defaultLevel();
    this.check();
  }
  public resetMessage(e) {
    if (e) { e.stopPropagation(); }

    this.defaultMessage();
    this.check();
  }
  public resetLog(e) {
    if (e) { e.stopPropagation(); }

    this.defaultLog();
    this.check();
  }
  public resetProperty(e) {
    if (e) { e.stopPropagation(); }

    this.defaultProperty();
    this.check();
  }
  public resetReport() {
    this.defaultReport();
  }
  public resetItem(item: any) {
    if (Array.isArray(item.original)) {
      this[item.type][item.key] = [...item.original];
    }
    else {
      this[item.type][item.key] = item.original;
    }

    this.refresh();
  }
  //-----------------------------------------------------------------

  

  //Methods
  public keyEvent(e) {
    if (e.ctrlKey || e.metaKey) {
      if (e.keyCode == 83) {
        //Key S
        this.onSave$.emit();
      }
      else if (e.altKey && e.keyCode == 82) {
        //Key Alt+R
        this._adminmode = !this._adminmode;
      } 
    }
  }
  public refresh() {
    if (!this._isrefreshing) {
      this._isrefreshing = true;
      this.check();
      this.onRefresh$.emit(true);
    }

    setTimeout(() => {
      this._isrefreshing = false;
    }, 1500);
  }
  public viewrefresh(type: string, data: any = null) {
    this.onViewRefresh$.emit({ type: type, data: data });
  }
  public checkWatchdog() {
    this.check();
  }
  public isDiff(type: string, key: string): boolean {
    let diff = false;
    for (let i = 0; i < this._difflist.length; i++) {
      if (this._difflist[i].type == type && this._difflist[i].key == key) {
        diff = true;
        break;
      }
    }
    return diff;
  }
  public getDiff(type: string, key: string) {
    let diff;
    for (let i = 0; i < this._difflist.length; i++) {
      if (this._difflist[i].type == type && this._difflist[i].key == key) {
        diff = this._difflist[i].value;
        break;
      }
    }
    return diff;
  }
  public levelList(type: number) {
    if (type == 1) {
      //User
      return this.user.levels.filter(function (level) { return level.active; }).map(function (level) { return level.Id; }).join();
    }
    else if (type == 2) {
      //Booking
      return this.booking.levels.filter(function (level) { return level.active; }).map(function (level) { return level.Id; }).join();
    }
    else if (type == 3) {
      //Level
      return this.level.levels.filter(function (level) { return level.active; }).map(function (level) { return level.Id; }).join();
    }

  }
  public userList() {
    return this.user.users.filter(function (user) { return user.active; }).map(function (user) { return user.Id; }).join();
  }
  public start(type: string) {
    if (type == 'booking') {
      //Booking
      return this.adjustDate(this.booking.start);
    }
    else if (type == 'usermarkeddate') {
      //UserMarkedDate
      return this.adjustDate(this.user.availabilitystart);
    }
    else if (type == 'employment') {
      //Employment
      return this.adjustDate(this.user.employmentstart);
    }
    else if (type == 'message') {
      //Message
      return this.adjustDate(this.message.start);
    }
    else if (type == 'log') {
      //Log
      return this.adjustDate(this.log.start);
    }
  }
  public end(type: string) {
    if (type == 'booking') {
      //Booking
      return this.adjustDate(this.booking.end, true);
    }
    else if (type == 'usermarkeddate') {
      //UserMarkedDate
      return this.adjustDate(this.user.availabilityend, true);
    }
    else if (type == 'employment') {
      //Employment
      return this.adjustDate(this.user.employmentend, true);
    }
    else if (type == 'message') {
      //Message
      return this.adjustDate(this.message.end);
    }
    else if (type == 'log') {
      //Log
      return this.adjustDate(this.log.end);
    }
  }


  /*Base64 to Blob*/
  public b64toBlob(b64Data, contentType, sliceSize?) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    let byteCharacters = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    let blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }




  //Functions
  private manageDefault(type, oldItem, newItem) {

    if (oldItem) {
      for (let field in oldItem) {

        //Found item in watchdog
        let found: any = null;
        this._watchdog.forEach((item) => {
          if (found == null && (item['type'] == type && item['key'] == field)) {
            found = item;
          }
        });


        if (found != null) {
          let change: boolean = true;

          if (this._searchlocked) {
            if (this._searchlocked.length > 0 && found.locked == 0) {
              //No item is locked or this item can't be locked
              change = true;
            }
            else if (this._searchlocked.indexOf(found.locked) > -1) {
              //Item is locked
              change = false;
            }
            else {
              //Item is not locked
              change = true;
            }
          }
          else {
            //SearchLocked is invalid
            change = false;
          }

          if (change) {
            oldItem[field] = newItem[field];
          }
        }
      }
    }
    else {
      oldItem = newItem;
    }

    return oldItem;
  }
  private defaultGeneral() {

    this._general = {
      datetimespan: this._defaultdatetimespan
    };
  }
  private defaultBooking() {

    this._booking = this.manageDefault('booking', this._booking, {
      start: this._defaultbooking.start,
      end: this._defaultbooking.end,
      intervaltype: '0',
      weekdays: [],
      status: [],
      personal: this._defaultbooking.personal,
      shifttype: this._defaultbooking.shifttype,
      reservation: this._defaultbooking.reservation,
      replaceableasnoslot: false,
      checkin: '0',
      bookingtype: this._defaultbooking.bookingtype,
      owner: 0,
      availability: this._defaultbooking.availability,
      activityoption: 0,
      activitytypes: [],
      timetypes: [],
      levelgroups: [...this._defaultbooking.levelgroups], //Copy Array
      levels: []
    });

    //this._booking = {
    //  start: this._defaultbooking.start,
    //  end: this._defaultbooking.end,
    //  intervaltype: '0',
    //  weekdays: [],
    //  status: [],
    //  personal: this._defaultbooking.personal,
    //  shifttype: this._defaultbooking.shifttype,
    //  reservation: this._defaultbooking.reservation,
    //  replaceableasnoslot: false,
    //  checkin: '0',
    //  bookingtype: this._defaultbooking.bookingtype,
    //  owner: 0,
    //  availability: this._defaultbooking.availability,
    //  activityoption: 0,
    //  activitytypes: [],
    //  timetypes: [],
    //  levelgroups: [...this._defaultbooking.levelgroups], //Copy Array
    //  levels: []
    //};
  }
  private defaultTimeline() {
    this._timeline = {
      groupby: this._defaulttimeline.groupby,
      timelineshow: this._defaulttimeline.timelineshow,
      timelinedisabled: false,
      bookingmode: false,
      multimode: false,
      includereferences: this._defaulttimeline.includereferences,
      showprofile: false,
      exactrendering: this._defaulttimeline.exactrendering,
      showgraph: false,
      showemptyrows: this._defaulttimeline.showemptyrows,
      precision: 0,
      calendarexpand: false,
      sumoptions: this._defaulttimeline.sumoptions,
      markedhide: this._defaulttimeline.markedhide,
      templateIdList: '',
      sortByShift: false
    };
  }
  private defaultUser() {

    this._user = this.manageDefault('user', this._user, {
      search: '',
      users: [],
      roles: [...this._defaultuser.roles], //Copy Array
      activity: '1',
      availability: '0',
      availabilitylist: [],
      availabilitystart: this._defaultuser.availabilitystart,
      availabilityend: this._defaultuser.availabilityend,
      employment: '0',
      nobelonging: 0,
      bookingtype: '0',
      levelgroups: [...this._defaultuser.levelgroups], //Copy Array
      levels: [],
      employmentstart: this._defaultuser.employmentstart,
      employmentend: this._defaultuser.employmentend,
    });

    //this._user = {
    //  search: '',
    //  users: [],
    //  roles: [...this._defaultuser.roles], //Copy Array
    //  activity: '1',
    //  availability: '0',
    //  availabilitystart: this._defaultuser.availabilitystart,
    //  availabilityend: this._defaultuser.availabilityend,
    //  nobelonging: 0,
    //  bookingtype: '0',
    //  levelgroups: [...this._defaultuser.levelgroups], //Copy Array
    //  levels: [],
    //  employmentstart: this._defaultuser.employmentstart,
    //  employmentend: this._defaultuser.employmentend,
    //};
  }
  private defaultLevel() {

    this._level = this.manageDefault('level', this._level, {
      levelgroups: [...this._defaultlevel.levelgroups], //Copy Array
      levels: []
    });

    //this._level = {
    //  levelgroups: [...this._defaultlevel.levelgroups], //Copy Array
    //  levels: []
    //};
  }
  private defaultMessage() {

    this._message = this.manageDefault('message', this._message, {
      start: this._defaultmessage.start,
      end: this._defaultmessage.end,
      address: '',
      subject: '',
      body: '',
      messagetype: [],
      messagestatus: []
    });

    //this._message = {
    //  start: this._defaultmessage.start,
    //  end: this._defaultmessage.end,
    //  address: '',
    //  subject: '',
    //  body: '',
    //  messagetype: [],
    //  messagestatus: []
    //};
  }
  private defaultLog() {

    this._log = this.manageDefault('log', this._log, {
      start: this._defaultlog.start,
      end: this._defaultlog.end,
      logobject: [],
      logtype: [],
      showauto: false
    });

    //this._log = {
    //  start: this._defaultlog.start,
    //  end: this._defaultlog.end,
    //  logobject: [],
    //  logtype: [],
    //  showauto: false
    //};
  }
  private defaultProperty() {

    this._property = this.manageDefault('property', this._property, {
      properties: [],
      useor: false
    });

    //this._property = {
    //  properties: [],
    //  useor: false
    //};
  }
  private defaultReport() {
    this._report = {
      id: 0,
      main: 0,
      name: '',
      description: '',
      reports: [],
      groupby: 0,
      visibility: this._defaultreport.visibility,
      excludeobject: 0,
      order: []
    };
  }
  private defaultHoliday() {
    this._holiday = {
      start: this._defaultholiday.start,
      end: this._defaultholiday.end
    };
  }
  private initWatchdog() {

    this._watchdog = [
      //Bookings
      { type: 'booking', key: 'intervaltype', language: 817, visible: 1, locked: 0, icon: '', original: null, name: null },
      { type: 'booking', key: 'weekdays', language: 933, visible: 1, locked: 0, icon: '', original: null, name: null },
      { type: 'booking', key: 'status', language: 15, visible: 1, locked: 3, icon: '', original: null, name: null },
      { type: 'booking', key: 'personal', language: 12, visible: 1, locked: 4, icon: '', original: null, name: null },
      { type: 'booking', key: 'shifttype', language: 699, visible: 1, locked: 7, icon: '', original: null, name: null },
      { type: 'booking', key: 'reservation', language: 160, visible: 1, locked: 5, icon: '', original: null, name: null },
      { type: 'booking', key: 'replaceableasnoslot', language: 545, visible: 1, locked: 0, icon: '', original: null, name: null },
      { type: 'booking', key: 'checkin', language: 72, visible: 1, locked: 6, icon: '', original: null, name: null },
      { type: 'booking', key: 'bookingtype', language: 475, visible: 1, locked: 0, icon: '', original: null, name: null },
      { type: 'booking', key: 'owner', language: 654, visible: 1, locked: 0, icon: '', original: null, name: null },
      { type: 'booking', key: 'activitytypes', language: 395, visible: 1, locked: 0, icon: '', original: null, name: null },
      { type: 'booking', key: 'timetypes', language: 1121, visible: 1, locked: 0, icon: '', original: null, name: null },
      { type: 'booking', key: 'levelgroups', language: 298, visible: 1, locked: 0, icon: 'bi-calendar-date', original: null },
      { type: 'booking', key: 'levels', language: 14, visible: 1, locked: 0, icon: 'bi-calendar-date', original: null, name: 'Name' },
      //Users
      { type: 'user', key: 'search', language: 59, visible: 2, locked: 21, icon: '', original: null, name: null },
      { type: 'user', key: 'users', language: 59, visible: 2, locked: 21, icon: '', original: null, name: null },
      { type: 'user', key: 'roles', language: 82, visible: 2, locked: 22, icon: '', original: null, name: null },
      { type: 'user', key: 'activity', language: 231, visible: 2, locked: 23, icon: '', original: '0', name: null },
      { type: 'user', key: 'availability', language: 173, visible: 2, locked: 13, icon: '', original: null, name: null },
      { type: 'user', key: 'availabilitylist', language: 728, visible: 2, locked: 13, icon: '', original: null, name: null },
      { type: 'user', key: 'employment', language: 905, visible: 2, locked: 0, icon: '', original: null, name: null },
      { type: 'user', key: 'nobelonging', language: 104, visible: 2, locked: 24, icon: '', original: null, name: null },
      { type: 'user', key: 'bookingtype', language: 4, visible: 2, locked: 14, icon: '', original: null, name: null },
      { type: 'user', key: 'levelgroups', language: 298, visible: 2, locked: 0, icon: 'bi-people-fill', original: null, name: null },
      { type: 'user', key: 'levels', language: 14, visible: 2, locked: 0, icon: 'bi-people-fill', original: null, name: 'Name' },
      //Levels
      { type: 'level', key: 'levelgroups', language: 298, visible: 4, locked: 0, icon: '', original: null, name: null },
      { type: 'level', key: 'levels', language: 14, visible: 4, locked: 0, icon: '', original: null, name: 'Name' },
      //Messages
      { type: 'message', key: 'address', language: 375, visible: 128, locked: 0, icon: '', original: null, name: null },
      { type: 'message', key: 'subject', language: 327, visible: 128, locked: 0, icon: '', original: null, name: null },
      { type: 'message', key: 'body', language: 328, visible: 128, locked: 0, icon: '', original: null, name: null },
      { type: 'message', key: 'messagetype', language: 379, visible: 128, locked: 0, icon: '', original: null, name: null },
      { type: 'message', key: 'messagestatus', language: 15, visible: 128, locked: 0, icon: '', original: null, name: null },
      //Logs
      { type: 'log', key: 'logobject', language: 405, visible: 256, locked: 0, icon: '', original: null, name: null },
      { type: 'log', key: 'logtype', language: 404, visible: 256, locked: 0, icon: '', original: null, name: null },
      { type: 'log', key: 'showauto', language: 805, visible: 256, locked: 0, icon: '', original: null, name: null },
      //Properties
      { type: 'property', key: 'properties', language: 156, visible: 16, locked: 0, icon: '', original: null, name: null },
    ];

    this._watchdog.forEach(item => {
      let value = this[item.type][item.key];
      if (item.original == null) {
        if (Array.isArray(value)) {
          item.original = [...value]; //Copy Array
        }
        else {
          item.original = value;
        }
      }
    });
  }
  private check() {
    this._difflist = [];

    this._watchdog.forEach(item => {
      
      let current = this[item.type][item.key];

      if (Array.isArray(current)) {
        //Array
        if (!this.compareArray(item.original, current)) {
          this._difflist.push({ type: item.type, key: item.key, language: item.language, value: current, visible: item.visible, locked: item.locked, icon: item.icon, original: item.original, name: item.name });
        }
      }
      else {
        //Value
        if (item.original != current) {
          this._difflist.push({ type: item.type, key: item.key, language: item.language, value: current, visible: item.visible, locked: item.locked, icon: item.icon, original: item.original });
        }
      }
      
    });

  }
  private compareArray(master, slave) {

    if (master.length != slave.length) {
      return false;
    }
    else {
      for (let mIndex in master) {
        var mItem = master[mIndex];

        var found = false;
        for (var sIndex in slave) {
          let sItem = slave[sIndex];
          found = true;
          break;
        }

        if (!found) {
          return false;
        }
      }
    }

    return true;
  }

  private adjustDate(date: any, isend:boolean = false) {

    let newdate = new Date(date);
    if (isend) {
      let beforeoffset = newdate.getTimezoneOffset();
      newdate = new Date(newdate.getTime() + (1000 * 60 * 60 * 24));
      let afteroffset = newdate.getTimezoneOffset();
      if (beforeoffset != afteroffset) {
        let adjustOffsetTicks = (afteroffset - beforeoffset) * 60 * 1000;
        newdate = new Date(newdate.getTime() + adjustOffsetTicks);
      }
      
    }

    let dateWithTimeZone = this.datePipe.transform(newdate, 'yyyy-MM-ddTHH:mm:ssZ').replace(/([+\-]\d\d)(\d\d)$/, "$1:$2");

    return dateWithTimeZone;
  }

  private todayStart() {
    let today = new Date();

    return new Date(today.getFullYear(), today.getMonth(), today.getDate());
  }
  private todayEnd() {
    let today = new Date();

    return new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
  }
  private yearStart() {
    let today = new Date();

    return new Date(today.getFullYear(), 0, 1);
  }
  private yearEnd() {
    let today = new Date();

    return new Date(today.getFullYear(), 11, 31);
  }
}




//Interfaces
export interface GeneralSetting {
  datetimespan: number;
}
export interface BookingSetting {
  start: Date;
  end: Date;
  intervaltype: string;
  weekdays: number[];
  status: number[];
  personal: string;
  shifttype: string;
  reservation: string;
  replaceableasnoslot: boolean;
  checkin: string;
  bookingtype: number[];
  owner: number;
  availability: number;
  activityoption: number;
  activitytypes: number[];
  timetypes: number[];
  levelgroups: number[];
  levels: any[];
}
export interface TimelineSetting {
  groupby: number;
  timelineshow: number;
  timelinedisabled: boolean;
  bookingmode: boolean;
  multimode: boolean;
  includereferences: boolean;
  showprofile: boolean;
  exactrendering: boolean;
  showgraph: boolean;
  showemptyrows: boolean;
  precision: number;
  calendarexpand: boolean;
  sumoptions: number;
  markedhide: boolean;
  templateIdList: string;
  sortByShift: boolean;
}
export interface UserSetting {
  search: string;
  users: any[];
  roles: number[];
  activity: string;
  availability: string;
  availabilitylist: number[];
  availabilitystart: Date;
  availabilityend: Date;
  employment: string;
  employmentstart: Date;
  employmentend: Date;
  nobelonging: number;
  bookingtype: string;
  levelgroups: number[];
  levels: any[];
}
export interface LevelSetting {
  levelgroups: number[];
  levels: any[];
}
export interface MessageSetting {
  start: Date;
  end: Date;
  address;
  subject;
  body;
  messagetype: number[];
  messagestatus: number[];
}
export interface LogSetting {
  start: Date;
  end: Date;
  logobject: number[];
  logtype: string[];
  showauto: boolean;
}
export interface PropertySetting {
  properties: any[];
  useor: false;
}

export interface HiddenFilter {
  type: string;
  key: string;
}

export interface ReportSetting {
  id: number;
  main: number;
  name: string;
  description: string;
  reports: any[];
  groupby: number;
  visibility: number;
  excludeobject: number;
  order: any[];
}
export interface HolidaySetting {
  start: Date;
  end: Date;
}
