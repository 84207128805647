<swe-pageheader [header]="header"><span swe-hits>&nbsp;({{hits}}<span *ngIf="more">+</span>)</span></swe-pageheader>
<div class="swe-fill-to-edge" sweFixedHeader>
  <div class="btn-group ms-1 align-top">
    <div class="dropdown">
      <button [attr.aria-label]="languageService.getItem(842)" class="btn btn-swe" (click)="listchecked=!listchecked;listutility.checkall(listchecked)" *ngIf="objects.length>0">
        <span><i class="bi bi-check-lg"></i></span>
      </button>
      <button [attr.aria-label]="languageService.getItem(843)" class="btn btn-swe" (click)="actionmenu=!actionmenu" *ngIf="listutility.ischecked">
        <span><i class="bi bi-swe-fw bi-three-dots-vertical"></i></span>
      </button>
      <ul class="dropdown-menu" [ngClass]="{'show': actionmenu && listutility.ischecked}">
        <li class="dropdown-item swe-click"><a (click)="action('multi', $event)"><i class="bi bi-swe-fw bi-files"></i>&nbsp;{{languageService.getItem(410)}}</a></li>
      </ul>
    </div>
  </div>
  <div class="btn-group ms-1 align-top" *ngIf="more">
    <button class="btn btn-primary" (click)="search(true)">{{languageService.getItem(57)}}</button>
  </div>
  <swe-filteritems></swe-filteritems>
</div>
<div class="row py-2" *ngIf="permissionService.hasObject(category)>0">
  <div class="col-12">
    <swe-card [open]="true" [hasCollapse]="false" (refreshChange)="search(false)">
      <span swe-rightcommand *ngIf="permissionService.hasObject(category)>1">
        <i class="bi bi-swe-fw bi-plus-lg bi-swe-pull-right swe-click" (click)="open(0, $event)"></i>
      </span>
      <swe-list [data]="objects" [loading]="isloading">
        <a href="javascript:void(0);" class="list-group-item list-group-item-action d-flex flex-wrap swe-click" [ngClass]="{'list-group-item-warning': object.Id==settingService.lastobject&&!object.checked,'list-group-item-info': object.checked}" *ngFor="let object of objects" (click)="open(object.Id, $event)">
          <div class="col-2 col-md-1" (click)="listutility.toggle(object, $event)">
            <span *ngIf="!object.checked">{{object.Id}}</span>
            <i class="bi bi-swe bi-check-circle-fill" *ngIf="object.checked"></i>
          </div>
          <div class="col-10 col-md-11 d-flex flex-wrap">
            <div class="col-xs-12 col-md-7">{{object.Header}}</div>
            <div class="col-xs-12 col-md-2">{{object.CreatedBy}}</div>
            <div class="col-xs-12 col-md-1"><span class="small" *ngIf="object.Created!=object.LastModified">{{object.Created}}</span></div>
            <div class="col-xs-12 col-md-2">{{object.LastModified}}</div>
          </div>
        </a>
        <button class="btn btn-primary" *ngIf="more" (click)="search(true)">{{languageService.getItem(57)}}</button>
      </swe-list>
    </swe-card>
  </div>
</div>
