<swe-card header="{{languageService.getItem(1077)}}" [(open)]="open" (openChange)="search()" (refreshChange)="search()" *ngIf="data&&data.Id>0">
  <span swe-hits *ngIf="open">&nbsp;({{hits}}<span *ngIf="more">+</span>)</span>
  <span swe-rightcommand><i class="bi bi-plus-lg bi-swe-pull-right swe-click" (click)="goto(0, $event)" *ngIf="new"></i></span>
  <swe-datepager [type]="'booking'" (dateChange)="search(false)" [disabled]="loading" [hideTime]="true" *ngIf="userid>0"></swe-datepager>
  <div class="btn-group ms-3 align-top">
    <!--Email Notification-->
    <button [attr.aria-label]="languageService.getItem(920)" [attr.title]="languageService.getItem(920)" class="btn btn-swe" (click)="checknotificationemail=!checknotificationemail" *ngIf="permissionService.permissions.NotifyOnBookEmail>0">
      <i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-square': !checknotificationemail, 'bi-check-square': checknotificationemail}"></i>
      <i class="bi bi-swe-fw bi-swe bi-envelope-fill"></i>
    </button>
    <!--SMS Notification-->
    <button [attr.aria-label]="languageService.getItem(921)" [attr.title]="languageService.getItem(921)" class="btn btn-swe" (click)="checknotificationsms=!checknotificationsms" *ngIf="permissionService.permissions.NotifyOnBookSms>0">
      <i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-square': !checknotificationsms, 'bi-check-square': checknotificationsms}"></i>
      <i class="bi bi-swe-fw bi-swe bi-phone-fill"></i>
    </button>
    <span class="small ms-1" *ngIf="bookingid==0">{{languageService.getItem(1149)}}</span>
  </div>
  <swe-list [data]="requests" [loading]="loading">
    <div class="list-group-item list-group-item-action d-flex flex-wrap" [ngClass]="{'list-group-item-danger': request.Responses.length==0,'list-group-item-info': request.Personal}" *ngFor="let request of requests" (click)="request.Access>0&&goto(request.Id, $event)">
      <div class="col-12 col-md-3 swe-click" [attr.title]="request.Question">
        <span class="font-weight-bold" *ngIf="request.Header.length>0">{{request.Header}} </span>
        <span>{{request.Question.substring(0,40)}}</span>
        <span *ngIf="request.Question.length>40">...</span>
      </div>
      <div class="col-12 col-md-2 swe-click">{{dateTimeService.format(request.DateTime)}}</div>
      <div class="col-12 col-md-2 swe-click">{{request.Name}}</div>
      <div class="col-12 col-md-4 d-flex flex-wrap swe-click">
        <ng-container *ngFor="let response of request.Responses">
          <div class="col-12 col-md-2">{{response.OptionText}}</div>
          <div class="col-12 col-md-6">{{response.Response}}</div>
          <div class="col-12 col-md-4">{{dateTimeService.format(response.DateTime)}}</div>
        </ng-container>
      </div>
      <div class="col-12 col-md-1" [ngStyle]="{'cursor': request.Access>0 ? 'pointer':'not-allowed'}">
        <button class="btn btn-secondary me-1 mb-1" *ngFor="let option of request.Options" (click)="answer(request.Id, option.Key, $event)" [disabled]="request.Access==0">{{option.Value}}</button>
      </div>
    </div>
    <button class="btn btn-primary" *ngIf="more" (click)="search(true)">{{languageService.getItem(57)}}</button>
  </swe-list>
</swe-card>
