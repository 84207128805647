<swe-pageheader [header]="languageService.getItem(575)">
  <span swe-hits>
    &nbsp;(#{{id}})
  </span>
</swe-pageheader>
<div class="row py-2">
  <!--Help-->
  <div class="col-12">
    <swe-card [header]="languageService.getItem(742)" [open]="false" [hasCollapse]="true" [hasRefresh]="false">
      <div class="mb-3 mt-3 d-flex flex-wrap">
        <!--Operators-->
        <div class="col-12 col-md-4">
          <div class="alert alert-info">
            <h4>{{languageService.getItem(867)}}</h4>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">+</div>
              <div class="col-9 col-md-11">= Addera</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">-</div>
              <div class="col-9 col-md-11">= Subtrahera</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">*</div>
              <div class="col-9 col-md-11">= Multiplicera</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">/</div>
              <div class="col-9 col-md-11">= Dividera</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">^</div>
              <div class="col-9 col-md-11">= Upphöjt i (ex. 2^3 = 8)</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">?</div>
              <div class="col-9 col-md-11">= Villkor (vänster operand om den har ett värde, annars höger operand)</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">></div>
              <div class="col-9 col-md-11">= Större än</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1"><</div>
              <div class="col-9 col-md-11">= Mindre än</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">=</div>
              <div class="col-9 col-md-11">= Lika med</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">#</div>
              <div class="col-9 col-md-11">= Vektorfunktioner</div>
            </div>
          </div>
          <!--VectorFunctions-->
          <div class="alert alert-info">
            <h4>Vektorfunktioner</h4>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">L</div>
              <div class="col-9 col-md-11">= Length (Antal poster i vektorn)</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">S</div>
              <div class="col-9 col-md-11">= Sum (Summerar alla poster i vektorn)</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">A</div>
              <div class="col-9 col-md-11">= Average (Medelvärdet av alla poster)</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">U</div>
              <div class="col-9 col-md-11">= UnbrokenChain (Antal poster i rad som inte innehåller 0)</div>
            </div>
          </div>
        </div>
        <!--TimeVector-->
        <div class="col-12 col-md-4">
          <div class="alert alert-info">
            <h4>Tidvektorn</h4>
            <div>
              <p>Tidvektor är en vektor som innehåller summerad tid i givet intervall.</p>
              <p class="h5">Typ av tid</p>
              <p>Tidvektorn innehåller bara tiden för antingen alla <i>[Arbetspass]</i> eller för alla <i>[Ledigheter]</i>. Detta bestäms av <i>[Tidsgruppering]</i>.</p>
              <p class="h5">Brytpunkt</p>
              <p>Alla <i>[Arbetspass]</i> och <i>[Ledigheter]</i> delas upp (om så behövs) i flera poster med hjälp av <i>[Dygnsbrytpunkt]</i>. Ingen post kan innehålla tid från mer än ett dygn.<p>
              <p>Om så önskas kan <i>[Arbetspass]</i> eller <i>[Ledigheter]</i> räknas ihop över <i>[Dygnsbrytpunkt]</i> om de är av samma typ av tid, via <i>[Tidsgruppering]</i>.</p>
              <p class="h5">Gruppering</p>
              <p>Om man vill göra kontrollen på flera delmängder kan man gruppera kontrollen via <i>[Tidsperiod]</i>. Då "klipps" vektor isär och betraktas som flera vektorer som alla testas var och en.</p>
              <p>Det betyder också att kontroll INTE görs mellan 2 gruppering även om man ställt in att kontrollera över dygnsbrytpunkter</p>
              <p class="h5">Summering</p>
              <p>Summering av tiden i varje post styrs av vilken <i>[Tidsenhet]</i> man valt.</p>
              <p class="h5">Intervall</p>
              <p>Antalet <i>[Arbetspass]</i> och <i>[Ledigheter]</i> styrs av hur stor intervallet (<i>[Påverkade timmar]</i>) är.</p>
              <p>Värdet 24 motsvarar aktuell boknings exakta dygn med hänsyn tagen till <i>[Dygnsbrytpunkt]</i> (kan bli 2 dygn om aktuellt pass går över <i>[Dygnsbrytpunkt]</i>)</p>
              <p>Värdet 168 motsvarar aktuell boknings exakta vecka med hänsyn tagen till <i>[Veckobrytpunkt]</i> (kan bli flera dygn om aktuellt pass går över <i>[Veckobrytpunkt]</i>)</p>
              <p>Övriga värden lägger till antal timmar före aktuellt pass starttid och efter dess sluttid</p>
              <p class="h5">Filter</p>
              <p>För att filtrera bort veckodagar kan man änvända <i>[Dagfilter]</i> som bara inkluderar <i>[Arbetspass]</i> och <i>[Ledigheter]</i> som är valda. Det går dessutom att ta bort passet (inte köra ATKn) via <i>[Filter]</i> som är uträknade formler på passet, passenheten, användaren och tidrapporten.</p>
            </div>
          </div>
        </div>
        <!--TimeVector-->
        <div class="col-12 col-md-4">
          <div class="alert alert-info">
            <h4>Exempel</h4>
            <div>
              <p class="h5">Minst 11 timmars sammanhängande ledighet i hela intervallet</p>
              <p>1. Tidvektorn ställs in på <i>[Tidsenhet]</i> timmar och <i>[Tidsgruppering]</i> Ledigheter med eller utan hänsyn till dygnsbrytpunkt.</p>
              <p>2. Formeln blir ([Tidsvecktor]&gt;10.99), dvs returnera alla positioner från vektorn som är större än 10.99 timmar i en ny vektor. Är den vektorn tom returneras False, annars True</p>
              <p class="h5">Minst 11 timmars sammanhängande ledighet i varje 24 timmars period</p>
              <p>1. Tidvektorn ställs in på <i>[Tidsenhet]</i> timmar och <i>[Tidsgruppering]</i> Ledigheter med eller utan hänsyn till dygnsbrytpunkt samt <i>[Tidsperiod]</i> 24 timmar.</p>
              <p>2. Formeln blir ([Tidsvecktor]&gt;10.99), dvs returnera alla positioner från vektorn som är större än 10.99 timmar i en ny vektor. Är den vektorn tom returneras False, annars True</p>
              <p>Observera att det kan vara flera vektorers som kontrolleras eftersom <i>[Tidsperiod]</i> delar upp vektorn i flera vektorer. Alla vektorer måste uppfylla formeln.</p>
              <p class="h5">11 timmars sammanhängande ledighet mellan varje pass</p>
              <p>1. Tidvektorn ställs in på <i>[Tidsenhet]</i> timmar och <i>[Tidsgruppering]</i> Ledigheter, här får INTE hänsyn till dygnsbrytpunkt tas eftersom vi ska kontrollera tiden mellan passen. <i>[Tidsperiod]</i> får hellre INTE väljas då flera vektorer inte kan titta mellan varandra.</p>
              <p>2. Formeln blir ([Tidsvecktor]&lt;11)#L=0, dvs returnera alla positioner som är mindre än 11 timmar. Kontrollera att dessa är lika med 0 st eftersom alla ledigheter måste vara minst 11 timmar.</p>
            </div>
          </div>
        </div>
      </div>
    </swe-card>
  </div>
  <!--Rule-->
  <div class="col-12 col-md-4">
    <swe-card [header]="languageService.getItem(607)" [open]="true" [hasCollapse]="settingService.isDevice(['xs','sm'])" [hasRefresh]="false">
      <div class="input-group mb-3">
        <swe-element [bottomMargin]="0" [type]="'System.String'" [label]="languageService.getItem(607)" [statusLabel]="0" [(model)]="timerule.RuleStart"></swe-element>
        <span class="input-group-text">{{languageService.getItem(608)}}</span>
        <swe-element [bottomMargin]="0" [type]="'System.String'" [label]="languageService.getItem(607)" [statusLabel]="0" [(model)]="timerule.RuleEnd"></swe-element>
      </div>
      <swe-element [type]="'System.String'" [label]="languageService.getItem(609)" [statusLabel]="2" [(model)]="timerule.Desc"></swe-element>
      <swe-element [type]="'System.String'" [label]="languageService.getItem(610)" [statusLabel]="2" [(model)]="timerule.Failure"></swe-element>
      <swe-element [type]="'System.List'" [label]="languageService.getItem(611)" [statusLabel]="2" [(model)]="timerule.BreakWeekday" [items]="weekdays"></swe-element>
      <swe-element [type]="'System.Int32'" [label]="languageService.getItem(612)" [statusLabel]="2" [(model)]="timerule.BreakHour"></swe-element>
      <swe-element [type]="'System.List'" [label]="languageService.getItem(613)" [statusLabel]="2" [(model)]="timerule.Type" [items]="types"></swe-element>
      <swe-element [type]="'System.String'" [label]="languageService.getItem(614)" [statusLabel]="2" [(model)]="timerule.Filter"></swe-element>
      <swe-element [type]="'System.Int32'" [label]="languageService.getItem(615)" [statusLabel]="2" [(model)]="timerule.AffectedHours"></swe-element>
      <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(343)" [statusLabel]="2" [(model)]="timerule.IsActive"></swe-element>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="save()"><i class="bi bi-save"></i>&nbsp;{{languageService.getItem(16)}}</button>
        <button class="btn btn-primary" (click)="copy()" *ngIf="timerule.Id>0"><i class="bi bi-journals"></i>&nbsp;{{languageService.getItem(206)}}</button>
        <swe-confirm *ngIf="timerule.Id>0" [body]="languageService.getItem(605)" (acceptChange)="delete()">
          <button class="btn btn-danger"><i class="bi bi-swe-fw bi-trash-fill"></i>&nbsp;{{languageService.getItem(17)}}</button>
        </swe-confirm>
      </div>
    </swe-card>
  </div>
  <!--Timevector-->
  <div class="col-12 col-md-4">
    <swe-card [header]="languageService.getItem(608)" [open]="true" [hasCollapse]="settingService.isDevice(['xs','sm'])" [hasRefresh]="false">
      <div class="form-row">
        <label>
          {{languageService.getItem(616)}}
          <i>{{getTimeUnit(timerule.TimeUnit)}}</i>
          {{languageService.getItem(617)}}
          <i>
            <span *ngIf="timerule.TimeGroupByNo>1">{{timerule.TimeGroupByNo}}</span> {{getTimeUnit(timerule.TimeGroupBy)}}
          </i>
          <span *ngIf="timerule.TimePeriod>-1">
            {{languageService.getItem(618)}}
            <span *ngIf="timerule.TimePeriodNo>1">{{timerule.TimePeriodNo}} </span>
            <i>
              {{getTimeUnit(timerule.TimePeriod)}}
            </i> {{languageService.getItem(619)}}
          </span>
          <span *ngIf="timerule.TimeDayFilter>0">. {{languageService.getItem(620)}} <i>{{getDayFilter(timerule.TimeDayFilter)}}</i></span>
        </label>
      </div>
      <swe-element [type]="'System.List'" [label]="languageService.getItem(621)" [statusLabel]="2" [(model)]="timerule.TimeUnit" [items]="timeunits"></swe-element>
      <swe-element [bottomMargin]="0" [type]="'System.Int32'" [label]="languageService.getItem(631)" [statusLabel]="2" [(model)]="timerule.TimeGroupByNo"></swe-element>
      <swe-element [type]="'System.List'" [label]="languageService.getItem(631)" [statusLabel]="0" [(model)]="timerule.TimeGroupBy" [items]="timegroups"></swe-element>
      <swe-element [bottomMargin]="0" [type]="'System.Int32'" [label]="languageService.getItem(632)" [statusLabel]="2" [(model)]="timerule.TimePeriodNo"></swe-element>
      <swe-element [type]="'System.List'" [label]="languageService.getItem(632)" [statusLabel]="0" [(model)]="timerule.TimePeriod" [items]="timeperiods" [optional]="true"></swe-element>
      <swe-element [type]="'System.MultiList'" [label]="languageService.getItem(633)" [statusLabel]="2" [(model)]="timerule.TimeDayFilter" [items]="dayfilters" [container]="{isbit: true}"></swe-element>
    </swe-card>
  </div>
  <!--Belonging-->
  <div class="col-12 col-md-4">
    <swe-card [header]="languageService.getItem(33)" [open]="(contractid>0)" [hasCollapse]="true" [hasRefresh]="false">
      <swe-element [type]="'System.List'" [label]="languageService.getItem(953)" [statusLabel]="2" [(model)]="timerule.ContractId" [items]="contracts" [optional]="true" [disabled]="contractid>0"></swe-element>
      <swe-levelsearch [bottomMargin]="3" [onlyone]="false" (modelChange)="addLevel($event)" [label]="languageService.getItem(14)" [statusLabel]="2" [chosen]="levels" [disabled]="contractid>0"></swe-levelsearch>
      <div class="list-group pt-0" *ngIf="levels&&levels.length>0">
        <div class="list-group-item" *ngFor="let level of levels">
          <span class="swe-grayed-out">{{level.Path}}</span>{{level.Name}}
          <i class="bi bi-swe-fw bi-swe bi-x-lg bi-swe-pull-right swe-click" (click)="removeLevel(level)"></i>
        </div>
      </div>
    </swe-card>
  </div>

</div>
