<swe-pageheader [header]="languageService.getItem(345)"><span swe-hits>&nbsp;({{hits}}<span *ngIf="more">+</span>)</span></swe-pageheader>
<div class="swe-fill-to-edge" sweFixedHeader>
  <swe-datepager [type]="'message'" (dateChange)="search()" [disabled]="loading" [hideTime]="false"></swe-datepager>
  <div class="btn-group ms-1 align-top" *ngIf="all">
    <button class="btn btn-danger" (click)="search(false, true)">{{languageService.getItem(340)}}</button>
  </div>
  <swe-filteritems></swe-filteritems>
</div>
<div class="row py-2">
  <div class="col-12">
    <swe-card [hasCollapse]="false" (refreshChange)="search()">
      <div class="d-inline" swe-rightcommand>
        <div class="btn-group me-2 float-end" *ngIf="permissionService.permissions.Email>0||permissionService.permissions.Sms>0">
          <div class="dropdown">
            <i class="bi bi-swe-fw bi-plus-lg swe-click" (click)="add($event)" [attr.title]="languageService.getItem(100)" data-bs-toggle="dropdown"></i>
            <ul class="dropdown-menu dropdown-menu-end" [ngClass]="{'show': addmenu }">
              <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.Email>0" (click)="send(1, $event)"><a><i class="bi bi-swe-fw bi-envelope-fill"></i>&nbsp;{{languageService.getItem(321)}}</a></li>
              <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.Sms>0" (click)="send(3, $event)"><a><i class="bi bi-swe-fw bi-phone-fill"></i>&nbsp;{{languageService.getItem(320)}}</a></li>
            </ul>
          </div>
        </div>
      </div>
      <swe-list [data]="messages" [loading]="loading">
        <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click" *ngFor="let message of messages" (click)="open(message.Id, message.Category, $event)">
          <div class="col-12 col-md-2">{{dateTimeService.format(message.SendTime, 'yyyy-MM-dd HH:mm:ss')}}</div>
          <div class="col-12 col-md-2">{{message.From}}</div>
          <div class="col-12 col-md-1">{{message.DisplayName}}</div>
          <div class="col-12 col-md-2">{{message.To}}</div>
          <div class="col-12 col-md-2" [attr.title]="message.Body"><span style="font-weight:bold;" *ngIf="message.Subject.length>0">{{message.Subject}} </span>{{message.Body.substring(0,50)}}<span *ngIf="message.Body.length>50">...</span></div>
          <div class="col-12 col-md-1">{{message.Type}}</div>
          <div class="col-12 col-md-1">{{message.Status}}</div>
          <div class="col-12 col-md-1">
            <i class="bi bi-swe-fw bi-swe bi-paperclip" *ngIf="message.Attachments.length>0"></i>
            <i class="bi bi-swe-fw bi-swe bi-trash-fill" *ngIf="deleteAccess(message)" (click)="delete(message, $event)"></i>
          </div>
        </a>
        <button class="btn btn-primary" *ngIf="more" (click)="search(true)">{{languageService.getItem(57)}}</button>
      </swe-list>
</swe-card>
  </div>
</div>
