<div class="card shadow mb-2 {{bordercolor}}" [ngStyle]="{'width': (fitcontent ? 'max-content' : 'unset'), 'min-width': (fitcontent ? '100%' : 'unset')}">
  <div class="card-body p-md-3">
    <h2 class="card-title" [ngClass]="{'mb-0':!isOpen, 'swe-click': hasCollapse}" (click)="hasCollapse&&toggle()">
      <ng-content select="[swe-leftcommand]"></ng-content>
      <span [ngClass]="{'swe-big-header': bigheader}">{{header}}&nbsp;</span>
      <ng-content select="[swe-hits]"></ng-content>
      <span class="swe-print-remove" *ngIf="hasCollapse"><a href="javascript:void(0);" class="swe-no-link float-end" [attr.title]="header" #sweFocusElement><i class="bi bi-swe-fw bi-swe-pull-right" [ngClass]="{'bi-chevron-right': !isOpen,'bi-chevron-down': isOpen}"></i></a></span>
      <span class="swe-print-remove" *ngIf="hasRefresh&&isOpen" (click)="refresh($event)"><i class="bi bi-swe-fw bi-arrow-repeat bi-swe-pull-right swe-click" [ngClass]="{'bi-swe-spin': isRefreshing}" [attr.title]="languageService.getItem(995)"></i></span>
      <span class="swe-print-remove" *ngIf="hasSave&&isOpen" (click)="save($event)"><i class="bi bi-swe-fw bi-save bi-swe-pull-right swe-click" [attr.title]="languageService.getItem(16)"></i></span>
      <ng-content select="[swe-rightcommand]" *ngIf="isOpen"></ng-content>
    </h2>
    <div class="bg-transparent" *ngIf="isOpen">
      <div class="btn-group" *ngIf="tabs.length>1">
        <button *ngFor="let tab of tabs" class="btn" [ngClass]="{'btn-primary': tab.id==active, 'btn-outline-primary': tab.id!=active}" (click)="change(tab.id,$event)">{{tab.name}}</button>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
</div>
