import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LanguageService } from '../../_services/language.service';
import { DateTimeService } from '../../_services/datetime.service';
import { DataService } from '../../_services/data.service';
import { PermissionService } from '../../_services/permission.service';
import { AlertService } from '../../_services/alert.service';


@Component({
  selector: 'swe-requestdata',
  templateUrl: './requestdata.component.html'
})
export class RequestDataComponent implements OnInit {

  @Input() id: number = 0;

  private _request: any = {};
  private _loading: boolean = false;
  private _response: string = '';
  private _showType: boolean = false;
  private _items: any[] = [];
  private _requesttype: number = 1;

  constructor(
    public languageService: LanguageService,
    public dateTimeService: DateTimeService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private alertService: AlertService,
    private router: Router
  ) {

    
  }

  ngOnInit() {
    this.init();
    this.load();
  }

  /*Properties*/
  public get request() {
    return this._request;
  }
  public get loading() {
    return this._loading;
  }
  public get response() {
    return this._response;
  }
  public set response(val) {
    this._response = val;
  }
  public get showType() {
    return this._showType;
  }
  public get items() {
    return this._items;
  }



  //Methods
  public isvisible(obj, val) {
    return (obj & val) == val;
  }
  public manageRequestType(e) {
    let type = 0;
    this._items.forEach((item) => {
      if (item.Chosen) {
        type += item.Id;
      }
    });
    this._requesttype = type;
  }
  public answer(optionid: number, e) {

    e.stopPropagation();

    let dto = {
      Id: this._request.Id,
      Option: optionid,
      Response: this._response,
      Type: this._requesttype
    }

    this.dataService.tokenRequest('/api/v1/requests/response', 'POST', dto, 'text', 'response')
      .subscribe(response => {
        if (response) {
          this.alertService.Add({ message: response.body, type: 'success' });
          this.load();
        }

      });
  }
  public load() {

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/requests/' + this.id, 'GET')
      .subscribe(res => {
        if (res) {

          this._request = res;

          this._showType = (this._request.UserId != this.permissionService.user.Id);

          this._loading = false;
        }
      });
  }
  public save(e) {

    e.stopPropagation();

    let dto = {
      Id: this._request.Id,
      Response: this._response,
      Type: this._requesttype
    }

    this.dataService.tokenRequest('/api/v1/requests/response', 'POST', dto, 'text', 'response')
      .subscribe(response => {
        if (response) {
          this.alertService.Add({ message: response.body, type: 'success' });
          this.load();
        }

      });
  }
  public gotoUser(id: number, e) {
    this.router.navigate(['/users', id]);
  }
  public gotoBooking(id: number, e) {
    this.router.navigate(['/bookings', id]);
  }


  //Functions
  private init() {

    //Items
    this._items.push({ Id: 1, Name: this.languageService.getItem(1082), Chosen: true }); //System
    if (this.permissionService.permissions.Sms > 0) {
      this._items.push({ Id: 2, Name: this.languageService.getItem(1083), Chosen: false }); //Sms
    }
    //this._items.push({ Id: 4, Name: this.languageService.getItem(1084), Chosen: false }); //Email
    //this._items.push({ Id: 8, Name: this.languageService.getItem(1085), Chosen: false }); //Push

  }
  
  
}
