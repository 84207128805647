<swe-pageheader [header]="languageService.getItem(953)">
  <span swe-hits>
    &nbsp;(#{{id}})
  </span>
</swe-pageheader>
<div class="row d-block py-2">
  <!--Data-->
  <div class="col-12 col-md-4 float-start">
    <swe-contractdata [id]="id" (contractdataChange)="manageContractdata($event)"></swe-contractdata>
  </div>
  <!--OB-->
  <div class="col-12 col-md-8 float-start" *ngIf="contractdata&&contractdata.OBAccess>0">
    <swe-contractobrules [id]="id" [access]="contractdata.OBAccess"></swe-contractobrules>
  </div>
  <!--ATK-->
  <div class="col-12 col-md-8 float-start" *ngIf="contractdata&&contractdata.ATKAccess>0">
    <swe-contracttimerules [id]="id"></swe-contracttimerules>
  </div>
  <!--TimeBank-->
  <div class="col-12 col-md-4 float-start" *ngIf="permissionService.permissions.TimeBank&&contractdata&&contractdata.TimeBankAccess>0">
    <swe-contracttimebank [id]="contractdata.TimeBankId"></swe-contracttimebank>
  </div>
  <!--Las-->
  <div class="col-12 col-md-4 float-start" *ngIf="permissionService.permissions.Las&&contractdata&&contractdata.LasAccess>0">
    <swe-contractlas [id]="contractdata.LasId"></swe-contractlas>
  </div>
  <!--Prices-->
  <div class="col-12 col-md-8 float-start" *ngIf="contractdata&&contractdata.PriceAccess>0">
    <swe-contractprices [id]="id"></swe-contractprices>
  </div>
  <!--Users-->
  <div class="col-12 col-md-8 float-start">
    <swe-contractusers [id]="id"></swe-contractusers>
  </div>
</div>
