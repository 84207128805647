<swe-pageheader [header]="languageService.getItem(156)"></swe-pageheader>
<div class="row py-2">
  <div class="col-12">
    <ng-container *ngFor="let groupby of groupedby | keyvalue; index as i">
      <swe-card [header]="groupby.value.header" [(open)]="groupby.value.open" [hasCollapse]="true" [hasRefresh]="false" (refreshChange)="reload()">
        <span swe-rightcommand>
          <i class="bi bi-swe-fw bi-plus-lg bi-swe-pull-right swe-click" (click)="open($event, 0, groupby.key)" *ngIf="permissionService.user.IsSuper"></i>
        </span>
        <swe-list [data]="properties" [loading]="isloading">
          <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click" [ngClass]="{'list-group-item-warning': property.Id==settingService.lastproperty}" *ngFor="let property of groupby.value.items" (click)="open($event, property.Id)">
            <div class="col-2 col-md-1 d-flex flex-wrap">
              {{property.Id}}
            </div>
            <div class="col-7 col-md-10 d-flex flex-wrap">
              <div class="col-12 col-md-2">{{property.Name}}</div>
              <div class="col-12 col-md-2"><i>{{property.Group}}</i></div>
              <div class="col-12 col-md-2">{{property.DataType}}</div>
              <div class="col-12 d-md-none" [attr.title]="property.Calc">{{property.Calc.substring(0, 10)}}</div>
              <div class="col-md-4 d-none d-md-inline-block" [attr.title]="property.Calc">{{property.Calc}}</div>
              <div class="col-6 col-md-1"><i class="bi bi-swe-fw bi-swe bi-file-code" *ngIf="property.Format&&property.Format.length>0" [attr.title]="property.Format"></i></div>
              <div class="col-6 col-md-1"><i class="bi bi-swe-fw bi-swe bi-gear-fill" *ngIf="property.Core&&property.Core.length>0" [attr.title]="property.Core"></i></div>
            </div>
            <div class="col-3 col-md-1">
              {{property.Sort}}
            </div>
          </a>
        </swe-list>
      </swe-card>
    </ng-container>
  </div>
</div>
