<div class="table-responsive">
  <table class="table table-bordered mt-2" *ngIf="table&&table.length==0">
    <thead>
      <tr>
        <td style="border-top:0; border-bottom:0;"><span class="fst-italic">{{languageService.getItem(996)}}</span></td>
      </tr>
    </thead>
  </table>
  <ng-content></ng-content>
</div>
