<swe-card [header]="languageService.getItem(1098)" [hasRefresh]="true" [hasCollapse]="true" (refreshChange)="load()">
  <span swe-rightcommand>
    <a class="swe-no-link" href="javascript:void(0);" (click)="gotoBooking(request.BookingId, $event)" *ngIf="request&&request.BookingId>0"><i class="bi bi-swe-fw bi-calendar-date bi-swe-pull-right"></i></a>
    <a class="swe-no-link" href="javascript:void(0);" (click)="gotoUser(request.UserId, $event)" *ngIf="request&&request.UserId>0"><i class="bi bi-swe-fw bi-person-fill bi-swe-pull-right"></i></a>
  </span>
  <ng-container *ngIf="!request.Personal">
    <swe-element [(model)]="request.Header" [label]="languageService.getItem(1079)" [statusLabel]="2" [type]="'System.String'" *ngIf="isvisible(request.RequestType,4)" [disabled]="true"></swe-element>
    <swe-element [(model)]="request.Question" [label]="languageService.getItem(1080)" [statusLabel]="2" [container]="{rows:8}" [type]="'System.TextArea'" [disabled]="true"></swe-element>
  </ng-container>
  <ng-container *ngIf="request.Personal">
    <div class="alert alert-info">
      {{request.Question}}
    </div>
  </ng-container>
  <div class="mb-3" *ngIf="showType">
    <label>{{languageService.getItem(1081)}}</label>
    <div class="p-1 rounded border">
      <ng-container *ngFor="let item of items">
        <div class="d-flex mt-1">
          <swe-element [bottomMargin]="0" [(model)]="item.Chosen" (modelChange)="manageRequestType($event)" [label]="item.Name" [statusLabel]="4" [customCols]="0" [labelToRight]="true" [type]="'System.Boolean'" [disabled]="item.Id==1"></swe-element>
        </div>
      </ng-container>
    </div>
  </div>
  <swe-element [(model)]="response" [label]="request.Personal?languageService.getItem(1120):languageService.getItem(1099)" [statusLabel]="2" [container]="{rows:8}" [type]="'System.TextArea'"></swe-element>
  <div class="d-grid gap-2 mb-3">
    <button class="btn btn-secondary me-1" *ngFor="let option of request.Options" (click)="answer(option.Key, $event)">{{option.Value}}</button>
    <button class="btn btn-primary" *ngIf="request.Options.length==0" (click)="save($event)"><i class="bi bi-swe-fw bi-save"></i>&nbsp;{{languageService.getItem(16)}}</button>
    <button class="btn btn-primary" *ngIf="request.Options.length>0" (click)="save($event)"><i class="bi bi-swe-fw bi-save"></i>&nbsp;{{languageService.getItem(1111)}}</button>
  </div>
  <div class="mb-3">
    <label>{{languageService.getItem(1100)}}</label>
    <swe-list [data]="request.Responses" [loading]="loading">
      <div class="list-group-item list-group-item-action d-flex flex-wrap" *ngFor="let response of request.Responses">
        <div class="col-6">{{response.OptionText}}</div>
        <div class="col-6">{{dateTimeService.format(response.DateTime)}}</div>
        <div class="col-12">{{response.Response}}</div>
      </div>
    </swe-list>
  </div>
</swe-card>
