import { Component, Input, EventEmitter, Output, OnInit, OnChanges } from '@angular/core';
import { count } from 'console';
import { totalmem } from 'os';

import { DateTimeService } from '../_services/datetime.service';
import { LanguageService } from '../_services/language.service';
import { SettingService } from '../_services/setting.service';

@Component({
  selector: 'swe-datetime',
  templateUrl: './datetime.component.html',
})
export class DateTimeComponent implements OnInit, OnChanges {

  @Input() markChanges: boolean;
  @Input() undoChanges: boolean = false;
  @Input() startChanged: boolean;
  @Input() endChanged: boolean;
  @Input() hideStart: boolean;
  @Input() labelStart: string;
  @Input() start: any;
  @Output() startChange = new EventEmitter<any>();
  @Input() hideEnd: boolean;
  @Input() labelEnd: string;
  @Input() end: any;
  @Output() endChange = new EventEmitter<any>();
  @Input() hideDate: boolean;
  @Input() hideTime: boolean;
  @Input() hideReset: boolean;
  @Input() disabled: boolean;
  @Input() hideSeconds: boolean = false;
  @Input() alwaysshowcontents: boolean;

  private originalstart: any;
  private originalend: any;
  private laststart: any = null;
  private lastend: any;
  private startISO: string;
  private endISO: string;
  private _wholeday: boolean = false;
  private _startWithoutTime: any;
  private _startWithTime: any;
  private _endWithoutTime: any;
  private _endWithTime: any;
  
  constructor(
    public languageService: LanguageService,
    private dateTimeService: DateTimeService,
    private settingService: SettingService
  ) {

  }


  ngOnInit() {

    if (this.start) {
      this.originalstart = this.laststart = this.dateTimeService.formatWithTimeZone(new Date(this.start));
      this.startISO = this.dateTimeService.format(new Date(this.start), 'yyyy-MM-ddTHH:mm');
    }
    if (this.end) {
      this.originalend = this.lastend = this.dateTimeService.formatWithTimeZone(new Date(this.end));
      this.endISO = this.dateTimeService.format(new Date(this.end), 'yyyy-MM-ddTHH:mm');
    }

  }

  ngOnChanges() {
    if (this.undoChanges) {
      this.originalstart = null; 
      this.originalend = null;
      this.startChanged = false;
      this.endChanged = false;
    }

    this.init();
  }


  /*Properties*/
  public get startAsISO(): string {
    return this.startISO;
  }
  public set startAsISO(val) {
    if (val != null && val != "") {
      this.start = this.dateTimeService.formatWithTimeZone(new Date(val));
      this.startISO = val; 
      return;
    }
      this.start = null;
      this.startISO = null;
    }
  public get startAsDateString(): string {
    
    if (this.start != null) {
      return this.dateTimeService.format(new Date(this.start), 'yyyy-MM-dd');
    }

    return null;
  }
  public set startAsDateString(val) {
    
    if (val != null && val.length > 0) {
      this.start = this.dateTimeService.formatWithTimeZone(new Date(val));
    }
    else {
      this.start = null;
    }
  }
  public get startAsTimeString(): string {
    if (this.start != null) {
      return this.dateTimeService.format(new Date(this.start), 'HH:mm');
    }

    return null;
  }
  public set startAsTimeString(val) {
    if (val != null) {
      let timeArr = val.split(':');
      if (timeArr.length == 3) {
        this.start = this.dateTimeService.formatWithTimeZone(new Date(1900, 1, 1, Number(timeArr[0]), Number(timeArr[1]), Number(timeArr[2])));
      }
      else if (timeArr.length == 2) {
        this.start = this.dateTimeService.formatWithTimeZone(new Date(1900, 1, 1, Number(timeArr[0]), Number(timeArr[1])));
      }
      else {
        this.start = null;
      }
    }
    else {
      this.start = null;
    }
  }
  public get endAsISO(): string {
    return this.endISO;
  }
  public set endAsISO(val) {
    if (val != null && val != "") {
      this.end = this.dateTimeService.formatWithTimeZone(new Date(val));
      this.endISO = val;
      return
    }
      this.end = null;
      this.endISO = null;
    }
  public get endAsDateString(): string {
    if (this.end != null) {
      return this.dateTimeService.format(new Date(this.end), 'yyyy-MM-dd');
    }

    return null;
  }
  public set endAsDateString(val) {
    if (val != null) {
      this.end = this.dateTimeService.formatWithTimeZone(new Date(val));
    }
    else {
      this.end = null;
    }
  }
  public get endAsTimeString(): string {
    if (this.end != null) {
      return this.dateTimeService.format(new Date(this.end), 'HH:mm');
    }

    return null;
  }
  public set endAsTimeString(val) {
    if (val != null) {
      let timeArr = val.split(':');
      if (timeArr.length == 3) {
        this.end = this.dateTimeService.formatWithTimeZone(new Date(1900, 1, 1, Number(timeArr[0]), Number(timeArr[1]), Number(timeArr[2])));
      }
      else if (timeArr.length == 2) {
        this.end = this.dateTimeService.formatWithTimeZone(new Date(1900, 1, 1, Number(timeArr[0]), Number(timeArr[1])));
      }
      else {
        this.end = null;
      }
    }
    else {
      this.end = null;
    }
  }
  public get wholeday() {
    return this._wholeday;
  }
  public set wholeday(val) {
    this._wholeday = val;
  }
  public get startWithoutTime() {
    return this._startWithoutTime;
  }
  public set startWithoutTime(val) {
    this._startWithoutTime = val;
  }
  public get startWithTime() {
    return this._startWithTime;
  }
  public set startWithTime(val) {
    this._startWithTime = val;
  }
  public get endWithoutTime() {
    return this._endWithoutTime;
  }
  public set endWithoutTime(val) {
    this._endWithoutTime = val;
  }
  public get endWithTime() {
    return this._endWithTime;
  }
  public set endWithTime(val) {
    this._endWithTime = val;
  }





  /*Methods*/
  public reset() {
    //Only visible when End is not included
    this.start = null;
    this._startWithoutTime = null;
    this._startWithTime = null;
    //Bubble event
    this.startChange.emit(this.start);
  }
  public startHasChanged() {
    
    let dtLast = new Date(this.laststart);
    let dtStart = this._wholeday ? new Date(this._startWithoutTime) : new Date(this._startWithTime);
    let dtEndWithTime = new Date(this._endWithTime);
    let dtEndWithoutTime = new Date(this._endWithoutTime);

    if (dtStart.getTime() >= dtEndWithTime.getTime() && !this.settingService.isDevice(['xs'])) {
      let diff = dtStart.getTime() - dtLast.getTime();
      if (dtStart.getTimezoneOffset() != dtLast.getTimezoneOffset()) {
        //Offset diff
        diff -= (dtStart.getTimezoneOffset() - dtLast.getTimezoneOffset()) * 60 * 1000;
      }

      let newEndWithoutTime = new Date(dtEndWithoutTime.getTime() + diff);
      let newEndWithTime = new Date(dtEndWithTime.getTime() + diff);

      //Offset---------------
      let endWithTimeoffset = dtEndWithTime.getTimezoneOffset();
      let newEndWithTimeoffset = newEndWithTime.getTimezoneOffset();
      if (newEndWithTimeoffset != endWithTimeoffset) {
        let offsetDiff = (newEndWithTimeoffset - endWithTimeoffset) * 60 * 1000;
        newEndWithTime = new Date(newEndWithTime.getTime() + offsetDiff);
      }
      let endWithoutTimeoffset = dtEndWithoutTime.getTimezoneOffset();
      let newEndWithoutTimeoffset = newEndWithoutTime.getTimezoneOffset();
      if (newEndWithoutTimeoffset != endWithoutTimeoffset) {
        let offsetDiff = (newEndWithoutTimeoffset - endWithoutTimeoffset) * 60 * 1000;
        newEndWithoutTime = new Date(newEndWithoutTime.getTime() + offsetDiff);
      }
      //---------------------

      this._endWithoutTime = this.dateTimeService.formatWithTimeZone(newEndWithoutTime);
      this._endWithTime = this.dateTimeService.formatWithTimeZone(newEndWithTime);
      this.endISO = this.dateTimeService.format(dtEndWithTime, 'yyyy-MM-ddTHH:mm');

      this.manageEndTime();

      //Bubble event
      this.endChange.emit(this.end);
    }

    if (!this.settingService.isDevice(['xs', 'sm'])) {
      this.manageStartTime();
    }

    //Bubble event
    this.startChange.emit(this.start);

    //Save to future compare
    this.laststart = this.start;
    this.lastend = this.end;

    //Changed from original value
    if (this.markChanges) {
      this.startChanged = (typeof this.originalstart != 'undefined' && this.originalstart != this.start);
      this.endChanged = (typeof this.originalend != 'undefined' && this.originalend != this.end);
    }
  }
  public endHasChanged() {

    /*Remove because of problems when changing time before date issues*/

    //let dtLast = new Date(this.lastend);
    //let dtStart = new Date(this.start);
    //let dtEnd = new Date(this.end);

    //if (dtStart.getTime() > dtEnd.getTime() && !this.settingService.isDevice(['xs'])) {
    //  let diff = dtLast.getTime() - dtEnd.getTime();
    //  this.start = this.dateTimeService.formatWithTimeZone(new Date(dtStart.getTime() - diff));
    //  this.startISO = this.dateTimeService.format(new Date(this.start), 'yyyy-MM-ddTHH:mm');

    //  //Bubble event
    //  this.startChange.emit(this.start);
    //}

    if (!this.settingService.isDevice(['xs', 'sm'])) {
      this.manageEndTime();
    }

    //Bubble event
    this.endChange.emit(this.end);
    
    //Save to future compare
    this.laststart = this.start;
    this.lastend = this.end;

    //Changed from original value
    if (this.markChanges) {
      this.startChanged = (typeof this.originalstart != 'undefined' && this.originalstart != this.start);
      this.endChanged = (typeof this.originalend != 'undefined' && this.originalend != this.end);
    }
  }
  public wholedayHasChanged() {

    this.startHasChanged();
    this.endHasChanged();

    if (this._wholeday) {
      localStorage.setItem('wholeday', 'true');
    }
    else {
      localStorage.removeItem('wholeday');
    }

  }

  private init() {
    let totWithoutTime = 0;
    let countWithoutTime = 0;
    if (this.start) {
      totWithoutTime++;

      if (this.initStartTime()) { countWithoutTime++; }
    }
    if (this.end) {
      totWithoutTime++;

      if (this.initEndTime()) { countWithoutTime++; }
    }

    if (totWithoutTime > 0 && countWithoutTime == totWithoutTime && !this.hideDate && !this.hideTime && !this.hideEnd) {
      let bWholeDay = localStorage.getItem('wholeday');
      this._wholeday = (typeof bWholeDay != 'undefined' && bWholeDay != null && bWholeDay.toLowerCase() == 'true');
    }
  }

  private initStartTime() {
    if (this.start == '0001-01-01T00:00:00Z') { return true; }
    //First Start DateTime
    let dtStart = new Date(this.start);
    if (dtStart < new Date(1900, 0, 1)) { dtStart = new Date(1900, 0, 1, dtStart.getHours(), dtStart.getMinutes(), dtStart.getSeconds()); }
    //First Start Date (without Time)
    let dtStartWithoutTime = new Date(dtStart.getFullYear(), dtStart.getMonth(), dtStart.getDate());

    //Start Date with time
    this._startWithTime = this.dateTimeService.formatWithTimeZone(new Date(dtStart.getTime()));
    //Start Date without time
    this._startWithoutTime = this.dateTimeService.formatWithTimeZone(new Date(dtStartWithoutTime.getTime()));

    return dtStartWithoutTime.getTime() == dtStart.getTime();
  }
  private initEndTime() {
    if (this.end == '0001-01-01T00:00:00Z') { return true; }
    //First End DateTime
    let dtEnd = new Date(this.end);
    if (dtEnd < new Date(1900, 0, 1)) { dtEnd = new Date(1900, 0, 1, dtEnd.getHours(), dtEnd.getMinutes(), dtEnd.getSeconds()); }
    //First End Date (without Time)
    let dtEndWithoutTime = new Date(dtEnd.getFullYear(), dtEnd.getMonth(), dtEnd.getDate());

    //End Date with time
    this._endWithTime = this.dateTimeService.formatWithTimeZone(new Date(dtEnd.getTime()));
    //End Date without time
    if (dtEndWithoutTime.getTime() == dtEnd.getTime()) {
      this._endWithoutTime = this.dateTimeService.formatWithTimeZone(new Date(dtEndWithoutTime.getTime() - this.dateTimeService.oneday));
    }
    else {
      this._endWithoutTime = this.dateTimeService.formatWithTimeZone(new Date(dtEndWithoutTime.getTime()));
    }

    return dtEndWithoutTime.getTime() == dtEnd.getTime();
  }
  private manageStartTime() {
    if (this._wholeday) {
      //Current Start without time
      let dtStartWithoutTime = new Date(this._startWithoutTime);
      //Current Start with time
      let dtStartWithTime = new Date(this._startWithTime);
      dtStartWithTime = new Date(dtStartWithoutTime.getFullYear(), dtStartWithoutTime.getMonth(), dtStartWithoutTime.getDate(), dtStartWithTime.getHours(), dtStartWithTime.getMinutes());
      //New Start with time
      this._startWithTime = this.dateTimeService.formatWithTimeZone(new Date(dtStartWithTime.getTime()));
      
      //Return datetime
      this.start = this.dateTimeService.formatWithTimeZone(new Date(dtStartWithoutTime.getTime()));
    }
    else {
      //Current Start with time
      let dtStartWithTime = new Date(this._startWithTime);
      //Current Start without time
      let dtStartWithoutTime = new Date(dtStartWithTime.getFullYear(), dtStartWithTime.getMonth(), dtStartWithTime.getDate());
      //New Start without time
      this._startWithoutTime = this.dateTimeService.formatWithTimeZone(new Date(dtStartWithoutTime.getTime()));
      
      //Return datetime
      this.start = this.dateTimeService.formatWithTimeZone(new Date(dtStartWithTime.getTime()));
    }
  }
  private manageEndTime() {
    if (this._wholeday) {
      //Current End without time
      let dtEndWithoutTime = new Date(this._endWithoutTime);
      //Current End with time
      let dtEndWithTime = new Date(this._endWithTime);
      dtEndWithTime = new Date(dtEndWithoutTime.getFullYear(), dtEndWithoutTime.getMonth(), dtEndWithoutTime.getDate(), dtEndWithTime.getHours(), dtEndWithTime.getMinutes());
      //New End with time
      if (dtEndWithoutTime.getTime() == dtEndWithTime.getTime()) {
        this._endWithTime = this.dateTimeService.formatWithTimeZone(new Date(dtEndWithTime.getTime() + this.dateTimeService.oneday));
      }
      else {
        this._endWithTime = this.dateTimeService.formatWithTimeZone(new Date(dtEndWithTime.getTime()));
      }

      //Return datetime
      this.end = this.dateTimeService.formatWithTimeZone(new Date(dtEndWithoutTime.getTime() + this.dateTimeService.oneday));
    }
    else {
      //Current End with time
      let dtEndWithTime = new Date(this._endWithTime);
      //Current End without time
      let dtEndWithoutTime = new Date(dtEndWithTime.getFullYear(), dtEndWithTime.getMonth(), dtEndWithTime.getDate());
      //New End without time
      if (dtEndWithoutTime.getTime() == dtEndWithTime.getTime()) {
        this._endWithoutTime = this.dateTimeService.formatWithTimeZone(new Date(dtEndWithoutTime.getTime() - this.dateTimeService.oneday));
      }
      else {
        this._endWithoutTime = this.dateTimeService.formatWithTimeZone(new Date(dtEndWithoutTime.getTime()));
      }
      
      //Return datetime
      this.end = this.dateTimeService.formatWithTimeZone(new Date(dtEndWithTime.getTime()));
    }
  }
}
