<swe-pageheader></swe-pageheader>
<swe-card header="{{languageService.getItem(330)}}" [open]="true" [hasRefresh]="false" [hasCollapse]="false">
    <swe-element [(model)]="from" [label]="languageService.getItem(322)" [statusLabel]="2" [type]="'System.String'" [disabled]="typeId==6"></swe-element>
    <swe-element [(model)]="displayname" [label]="languageService.getItem(323)" [statusLabel]="2" [type]="'System.String'" *ngIf="isEmail"></swe-element>
    <swe-card header="{{languageService.getItem(806)}}" [color]="'swe'" [open]="false" [hasRefresh]="false">
      <span swe-hits>&nbsp;({{missing.length}})</span>
      <swe-element [label]="languageService.getItem(806)" [items]="missing" [type]="'System.MultiList'"></swe-element>
    </swe-card>
    <swe-card header="{{languageService.getItem(324)}}" [color]="'swe'" [open]="openTo" [hasRefresh]="false">
      <span swe-hits>&nbsp;({{toLength}})</span>
      <swe-element [(model)]="to" [label]="languageService.getItem(337)" [type]="'System.TextArea'" [disabled]="disabledTo"></swe-element>
    </swe-card>
    <swe-card header="{{languageService.getItem(325)}}" [color]="'swe'" [open]="false" [hasRefresh]="false" *ngIf="isEmail">
      <swe-element [(model)]="cc" [label]="languageService.getItem(338)" [type]="'System.TextArea'" [disabled]="disabledTo"></swe-element>
    </swe-card>
    <swe-card header="{{languageService.getItem(326)}}" [color]="'swe'" [open]="openBcc" [hasRefresh]="false" *ngIf="isEmail">
      <swe-element [(model)]="bcc" [label]="languageService.getItem(339)" [type]="'System.TextArea'" [disabled]="disabledTo"></swe-element>
    </swe-card>
    <swe-element [(model)]="subject" [label]="languageService.getItem(327)" [statusLabel]="2" [type]="'System.String'" *ngIf="isEmail"></swe-element>
    <swe-element [(model)]="body" [label]="languageService.getItem(328)" [statusLabel]="2" [container]="{rows:8}" [type]="'System.TextArea'">
      <div swe-footer>
        <span class="small">{{languageService.getItem(331)}} {{body.length}}</span>&nbsp;&nbsp;&nbsp;<span class="small" *ngIf="isSms">{{languageService.getItem(332)}} {{smscounter}}</span>
      </div>
    </swe-element>
    <swe-card header="{{languageService.getItem(432)}}" [color]="'swe'" [open]="openAttachment" [hasRefresh]="false" *ngIf="isEmail">
      <ng-container *ngFor="let file of files;index as i">
        <a href="javascript:void(0)" class="swe-no-link me-2 bi-swe-pull-right" (click)="deleteAttachment(i)"><i class="bi bi-swe-fw bi-swe bi-trash-fill bi-swe-pull-right"></i></a>
        <swe-element [(model)]="file.Content" (modelChange)="fileHasChanged(file)" [label]="languageService.getItem(432)" [statusLabel]="2" [type]="'Internetplatsen.Document'" [container]="{'hidedownload': true}" [bottomMargin]="(file.Name&&file.Name.length>0)?0:3"></swe-element>
        <div class="mb-3" *ngIf="file.Name&&file.Name.length>0">{{file.Name}}</div>
      </ng-container>
      <a href="javascript:void(0)" class="swe-no-link" (click)="addAttachment()"><i class="bi bi-swe-fw bi-swe bi-plus-square-fill"></i>{{languageService.getItem(100)}}</a>
    </swe-card>
    <swe-element [(model)]="delayed" [label]="languageService.getItem(890)" [statusLabel]="2" [type]="'System.DateTime'" *ngIf="isSms"></swe-element>
    <div class="d-grid gap-2">
      <button class="btn btn-primary" (click)="send()"><i class="bi" [ngClass]="{'bi-envelope-fill': isEmail, 'bi-phone-fill': isSms}"></i>&nbsp;{{languageService.getItem(329)}}</button>
    </div>
</swe-card>
