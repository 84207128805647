<swe-card header="{{languageService.getItem(51)}}" [(open)]="open" (refreshChange)="search()" (openChange)="search()">
  <span swe-rightcommand><i class="bi bi-swe-fw bi-plus-lg bi-swe-pull-right swe-click" (click)="goto(0,$event)" *ngIf="userdata.UserMarkedDateAccess>1"></i></span>
  <div class="py-2">
      <div class="btn-group me-5">
        <swe-calendar [(model)]="current" (modelChange)="manageDate()" [items]="usermarkeddates" [hasWeeknumbers]="true" [disabled]="loading||userdata.UserMarkedDateAccess<2" (chosenChange)="manageChosen($event)"></swe-calendar>
      </div>
      <div class="d-inline-block border border-secondary p-2 align-top" *ngIf="permissionService.permissions.UserMarkedDateShowPartOfDay">
        <label>{{languageService.getItem(1069)}}</label>
        <div class="my-3">
          <swe-datetime [(start)]="starttime" [(end)]="endtime" [hideDate]="true" [disabled]="wholeday"></swe-datetime>
        </div>
        <swe-element [type]="'System.Boolean'" [(model)]="wholeday" (modelChange)="toggleTime()" [label]="languageService.getItem(1070)" [statusLabel]="4" [customCols]="0" [labelToRight]="true"></swe-element>
        <swe-element [type]="commenttype" [items]="commentitems" [(model)]="comment" [label]="languageService.getItem(399)" [statusLabel]="2" *ngIf="userdata.UserMarkedDateComment>0"></swe-element>
      </div>
  </div>
  <div class="row py-2">
    <div class="col-12">
      <div class="btn-group me-1">
        <button [attr.aria-label]="languageService.getItem(842)" class="btn btn-swe" (click)="listchecked=!listchecked;listutility.checkall(listchecked)" *ngIf="usermarkeddates.length>0">
          <span><i class="bi bi-check-lg"></i></span>
        </button>
        <swe-confirm [body]="languageService.getItem(194)" (acceptChange)="deleteAll($event)">
          <button [attr.aria-label]="languageService.getItem(17)" class="btn btn-swe" *ngIf="listutility.ischecked">
            <span><i class="bi bi-swe-fw bi-trash-fill"></i></span>
          </button>
        </swe-confirm>
      </div>
      <swe-list [data]="usermarkeddates" [loading]="loading">
        <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click" [ngClass]="{'list-group-item-info': umd.checked, 'swe-disabled': (userdata.UserMarkedDateAccess<2)||(umd.Access<2)}" *ngFor="let umd of usermarkeddates" (click)="(umd.Access>1)&&goto(umd.Id,$event)">
          <div class="col-3 col-md-1" (click)="listutility.toggle(umd, $event)">
            <span class="bi-swe-stack bi-swe-letter-circle-small" *ngIf="umd.checked">
              <i class="bi bi-check-circle-fill bi-swe-stack-2x"></i>
            </span>
            <img class="swe-circle-image" alt="{{umd.Name}}" sweImage imageFolder="userimages" [imageFile]="umd.Image" imageWidth="32" imageHeight="32" *ngIf="!umd.checked&&umd.Image.length>0" />
            <span class="bi-swe-stack bi-swe-letter-circle-small" *ngIf="!umd.checked&&umd.Image.length==0">
              <i class="bi bi-circle-fill bi-swe-stack-2x"></i>
              <span class="bi-swe-stack-1x bi-swe-stack-1x bi-swe-letter">{{umd.Name[0]}}</span>
            </span>
          </div>
          <div class="col-9 col-md-11 d-flex flex-wrap">
            <div class="col-12 col-md-3">{{umd.dateheader}}</div>
            <div class="col-12 col-md-6">{{umd.Comment}}</div>
            <div class="col-12 col-md-3"><div style="display:inline-block;width:10px;height:10px;border:1px solid #999999;" [ngStyle]="{'background-color': umd.Color}"></div>&nbsp;{{umd.Type}}</div>
          </div>
        </a>
      </swe-list>

    </div>
  </div>
</swe-card>
