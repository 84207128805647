<swe-pageheader [header]="languageService.getItem(52)"><span swe-hits>&nbsp;({{hits}}<span *ngIf="more">+</span>)</span></swe-pageheader>
<div class="swe-fill-to-edge" sweFixedHeader>
  <div class="btn-group me-1 align-top" *ngIf="listutility.toggleaccess">
    <div class="dropdown">
      <button [attr.aria-label]="languageService.getItem(842)" class="btn btn-swe" (click)="listchecked=!listchecked;listutility.checkall(listchecked)" *ngIf="listutility.toggleaccess&&hits>0">
        <span><i class="bi bi-check-lg"></i></span>
      </button>
      <button [attr.aria-label]="languageService.getItem(843)" class="btn btn-swe" (click)="actionmenu=!actionmenu" *ngIf="listutility.ischecked">
        <span><i class="bi bi-swe-fw bi-three-dots-vertical"></i></span>
      </button>
      <ul class="dropdown-menu" [ngClass]="{'show': actionmenu && listutility.ischecked}">
        <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.MultiTimereport>0"><a (click)="action('multi')"><i class="bi bi-swe-fw bi-files"></i>&nbsp;{{languageService.getItem(669)}}</a></li>
      </ul>
    </div>
  </div>
  <swe-datepager [type]="'booking'" (dateChange)="search(false)" [disabled]="loading" [hideTime]="true"></swe-datepager>
  <div class="btn-group ms-1 align-top" *ngIf="all">
    <button class="btn btn-danger" (click)="search(false, true)">{{languageService.getItem(340)}}</button>
  </div>
  <swe-filteritems></swe-filteritems>
</div>
<div class="row py-2">
  <div class="col-12">
    <swe-card [hasCollapse]="false" (refreshChange)="search()">
      <span swe-rightcommand>
        <i class="bi bi-swe-fw bi-clock-fill bi-swe-pull-right swe-click" [attr.title]="languageService.getItem(1041)" (click)="create(1, $event)" *ngIf="permissionService.permissions.NewTimereport>0"></i>
        <i class="bi bi-swe-fw bi-person-x-fill bi-swe-pull-right swe-click" [attr.title]="languageService.getItem(1042)" (click)="create(3, $event)" *ngIf="permissionService.permissions.NewAbscentTimereport>0"></i>
      </span>
      <swe-list [data]="timereports" [loading]="loading">
        <div *ngFor="let groupby of groupedby | keyvalue">
          <a class="list-group-item list-group-item-action d-flex flex-wrap bg-light">
            <h2>{{groupby.value.header}}<span class="text-danger" *ngIf="groupby.value.today">&nbsp;({{languageService.getItem(76)}})</span></h2>
          </a>
          <a href="javascript:void(0);" class="list-group-item list-group-item-action d-flex flex-wrap swe-click" [ngClass]="{'list-group-item-success': timereport.Diff&&timereport.Status>40&&timereport.Id!=settingService.lasttimereport&&!timereport.checked, 'list-group-item-danger': timereport.Diff&&timereport.Status<50&&timereport.Id!=settingService.lasttimereport&&!timereport.checked, 'list-group-item-warning': timereport.Id==settingService.lasttimereport&&!timereport.checked, 'list-group-item-info': timereport.checked}" *ngFor="let timereport of groupby.value.items" (click)="open(timereport.Id, $event)">
            <div class="col-3 col-md-1" (click)="listutility.toggle(timereport, $event)">
              <span class="bi-swe-stack bi-swe-letter-circle-small" *ngIf="timereport.checked">
                <i class="bi bi-check-circle-fill bi-swe-stack-2x"></i>
              </span>
              <img class="swe-circle-image" alt="{{timereport.Name}}" sweImage imageFolder="userimages" [imageFile]="timereport.Image" imageWidth="32" imageHeight="32" *ngIf="!timereport.checked&&timereport.Image.length>0">
              <span class="bi-swe-stack bi-swe-letter-circle-small" *ngIf="!timereport.checked&&timereport.Image.length==0">
                <i class="bi bi-circle-fill bi-swe-stack-2x"></i>
                <span class="bi-swe-stack-1x bi-swe-letter">{{timereport.Name[0]}}</span>
              </span>
              <i class="bi bi-swe-border bi-person-bounding-box" *ngIf="!timereport.checked&&timereport.IsStandby" [attr.title]="languageService.getItem(279)"></i>
              <i class="bi bi-swe-border bi-person-x-fill" *ngIf="!timereport.checked&&timereport.IsAbscent" [attr.title]="languageService.getItem(280)"></i>
            </div>
            <div class="col-9 col-md-11 d-flex flex-wrap">
              <div class="col-12 col-md-1" *ngIf="permissionService.permissions.CheckIn>0">
                <i class="bi bi-box-arrow-in-right bi-swe-2x" [ngClass]="{'swe-grayed-out':timereport.CheckIn < 1 && !timereport.hover1}" (click)="!timereport.checked&&checkin(timereport.Id, timereport.CheckInAccess, $event)" (mouseover)="timereport.hover1=true" (mouseout)="timereport.hover1=false" [attr.title]="languageService.getItem(433)"></i>&nbsp;
                <i class="bi bi-box-arrow-right bi-swe-2x" [ngClass]="{'swe-grayed-out':timereport.CheckIn < 2 && !timereport.hover2}" (click)="!timereport.checked&&checkout(timereport.Id, timereport.CheckInAccess, $event)" (mouseover)="timereport.hover2=true" (mouseout)="timereport.hover2=false" [attr.title]="languageService.getItem(434)"></i>
              </div>
              <div class="col-12 col-md-1">{{timereport.dateheader}}</div>
              <div class="col-12 col-md-3">{{timereport.Name}}</div>
              <div class="col-12 col-md-3">{{timereport.Comment}}</div>
              <div class="col-12 col-md-2">{{timereport.Level}}<span *ngIf="timereport.Header&&timereport.Header.length>0">, {{timereport.Header}}</span></div>
              <div class="col-12 col-md-2">
                <i class="bi bi-swe-fw bi-swe-2x" *ngIf="timereport.TimereportAccess == 0"></i>
                <i class="bi bi-swe-fw bi-clock-fill bi-swe-2x" [ngClass]="{'swe-grayed-out':timereport.Status < 40 && !timereport.hover3}" *ngIf="timereport.TimereportAccess > 0" (click)="!timereport.checked&&change(timereport, 40, $event)" (mouseover)="timereport.hover3=true" (mouseout)="timereport.hover3=false" [attr.title]="languageService.getItem(333)"></i>
                <i class="bi bi-swe-fw bi-swe-2x" *ngIf="timereport.ConfirmedAccess == 0"></i>
                <i class="bi bi-swe-fw bi-check-lg bi-swe-2x" [ngClass]="{'swe-grayed-out':timereport.Status < 50 && !timereport.hover4}" *ngIf="timereport.ConfirmedAccess > 0" (click)="!timereport.checked&&change(timereport, 50, $event)" (mouseover)="timereport.hover4=true" (mouseout)="timereport.hover4=false" [attr.title]="languageService.getItem(334)"></i>
                <i class="bi bi-swe-fw bi-swe-2x" *ngIf="timereport.CompletedAccess == 0"></i>
                <i class="bi bi-swe-fw bi-lock-fill bi-swe-2x" [ngClass]="{'swe-grayed-out':timereport.Status < 60 && !timereport.hover5}" *ngIf="timereport.CompletedAccess > 0" (click)="!timereport.checked&&change(timereport, 60, $event)" (mouseover)="timereport.hover5=true" (mouseout)="timereport.hover5=false" [attr.title]="languageService.getItem(335)"></i>
              </div>
            </div>
          </a>
        </div>
        <button class="btn btn-primary" *ngIf="more" (click)="search(true)">{{languageService.getItem(57)}}</button>
      </swe-list>
    </swe-card>
  </div>
</div>
