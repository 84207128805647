import { Component, OnChanges, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { LanguageService } from '../../_services/language.service';
import { SettingService } from '../../_services/setting.service';
import { DateTimeService } from '../../_services/datetime.service';
import { GridSettings } from './timelinegrid.component';
import { PermissionService } from '../../_services/permission.service';
import { ListService } from '../../_services/list.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';
import { ViewCacheService } from '../../_services/viewcache.service';
import { PropertyService } from '../../_services/property.service';
import { GeneralService } from '../../_services/general.service';
import { takeUntil } from 'rxjs/operators';



@Component({
  selector: 'swe-timelinerow',
  templateUrl: './timelinerow.component.html'
})
export class TimelineRowComponent implements OnChanges, OnDestroy {

  @Input() row: any;
  @Input() dofilter: boolean;
  @Input() menucollapsed: boolean = false;
  @Input() notifyemail: boolean = false;
  @Input() notifysms: boolean = false;
  @Input() settings: GridSettings;
  @Output() settingsChange = new EventEmitter<any>();
  @Output() resetScroll = new EventEmitter();
  private _isEmpty: boolean = false;
  private _availablesum: number = 0;
  private _availableunit: string = '';
  private _shiftsum: number = 0;
  private _shiftunit: string = '';
  private _height: number = 0;
  private _minheight: number = 0;
  private _shiftheight: number = 0;
  private _availabilityheight: number = 0;
  private _toggleaccess: boolean = false;
  private _droppedStep1: boolean = false;
  private _dropbody: string = '';
  private _dropevent: any;
  private _droppedStep2: boolean = false;
  private _droppedSerieId = 0;
  private _droppedAffectAllOnly = false;
  private _detail: any;
  private _detailprofileready: boolean = false;

  constructor(
    public languageService: LanguageService,
    public settingService: SettingService,
    public dateTimeService: DateTimeService,
    public generalService: GeneralService,
    private permissionService: PermissionService,
    private listService: ListService,
    private dataService: DataService,
    private alertService: AlertService,
    private viewCacheService: ViewCacheService,
    private propertyService: PropertyService,
    private router: Router
  ) {

    
  }

  ngOnChanges() {

    this._toggleaccess = (this.permissionService.permissions.MultiShift > 0);

    this.load();
  }

  ngOnDestroy() {
    
  }


  //Properties
  public get isEmpty() {
    return this._isEmpty;
  }
  public get availablesum() {
    return this._availablesum;
  }
  public get availableunit() {
    return this._availableunit;
  }
  public get shiftsum() {
    return this._shiftsum;
  }
  public get shiftunit() {
    return this._shiftunit;
  }
  public get height() {
    return this._height;
  }
  public get minheight() {
    return this._minheight;
  }
  public get shiftheight() {
    return this._shiftheight;
  }
  public get availabilityheight() {
    return this._availabilityheight;
  }
  public get droppedStep1() {
    return this._droppedStep1;
  }
  public set droppedStep1(val) {
    this._droppedStep1 = val;
  }
  public get dropbody() {
    return this._dropbody;
  }
  public get dropevent() {
    return this._dropevent;
  }
  public get droppedStep2() {
    return this._droppedStep2;
  }
  public set droppedStep2(val) {
    this._droppedStep2 = val;
  }
  public get droppedSerieId() {
    return this._droppedSerieId;
  }
  public get droppedAffectAllOnly() {
    return this._droppedAffectAllOnly;
  }
  public get detail() {
    return this._detail;
  }
  public get detailprofileready() {
    return this._detailprofileready;
  }
  



  //Methods
  public manageColors(booking) {
    /*
    'swe-disabled-block': booking.Access<2,
    'swe-reference-block': booking.Type > 1 && booking.Access > 1,
    'alert-danger border-danger': booking.Amount == 0 && (booking.Max > 0 || !permissionService.permissions.ZeroAsFull),
    'alert-warning border-warning': (booking.Amount > 0 && booking.Amount < booking.Max),
    'alert-success border-success': (booking.Amount == booking.Max)    
    */
    let cls = '';
    if (booking.Access < 2) {
      //Read access
      cls = 'swe-disabled-block';
    }
    else {
      if (booking.Type == 3) {
        //Abscent
        if (booking.Amount < booking.Max) {
          //Not full
          cls = 'swe-timeline-abscent-notfull';
        }
        else {
          //Full
          cls = 'swe-timeline-abscent-full';
        }
      }
      else if (booking.MinusTime) {
        //Minus time
        cls = 'swe-timeline-minustime border-danger';
      }
      else if (booking.PlusTime) {
        //Plus time
        cls = 'swe-timeline-plustime border-success';
      }
      else if (booking.Type > 1) {
        //Reference
        cls = 'swe-reference-block';
      }
      else if (booking.Amount == 0 && (booking.Max > 0 || !this.permissionService.permissions.ZeroAsFull)) {
        //Empty OR when Zero as Max not means full 
        cls = 'alert-danger border-danger';
      }
      else if (booking.Amount > 0 && booking.Amount < booking.Max) {
        //Partly full
        cls = 'alert-warning border-warning';
      }
      else if (booking.Amount == booking.Max) {
        //Full
        cls = 'alert-success border-success';
      }
    }

    return cls;
  }
  public toggle() {
    this.row.checked = !this.row.checked;

    this.settings.resourcecheckcounter += (this.row.checked ? 1 : -1);
    this.settingsChange.emit({ settings: this.settings, reload: false});

    if (this.settings.resourcecheckcounter == 0) {
      this.dofilter = false;
    }
  }
  public cut(str: string) {

    let max = 8;
    if (this.settingService.isDevice(['xl'])) { max = 28; }
    else if (this.settingService.isDevice(['lg'])) { max = 23; }
    else if (this.settingService.isDevice(['md'])) { max = 18; }
    else if (this.settingService.isDevice(['sm'])) { max = 13; }

    if (str.length > max) {
      str = str.substring(0, max);

      if (str.split(' ').length > 3) {
        str = str.substring(0, str.lastIndexOf(' '));
      }

      str += "...";
    }

    return str;
  }
  //Click
  public click(booking, e) {

    if (!(e.ctrlKey || e.metaKey) && this.settingService.timeline.bookingmode) {
      //Mark for booking
      if (this.settings.marked == booking.Id) {
        this.settings.marked = 0;
        this.settings.markedgroup = 0;
        this.settingsChange.emit({ settings: this.settings, reload: true });
      }
      else {
        this.settings.marked = booking.Id;
        this.settings.markedgroup = this.row.GroupById;
        this.settingsChange.emit({ settings: this.settings, reload: true });
      }
    }
    else if (!(e.ctrlKey || e.metaKey) && this.settingService.timeline.multimode) {
      //Mark shift for multi handling
      if (!this._toggleaccess || booking.Access < 2) {
        return;
      }

      this.settings.marked = 0;
      this.settings.markedgroup = 0;

      booking.ismarked = !booking.ismarked;

      this.settings.shiftcheckcounter += (booking.ismarked ? 1 : -1);
      this.settingsChange.emit({ settings: this.settings, reload: false });

      e.stopPropagation();
    }
    else {
      //Open
      this.open(booking, e);
    }
  }
  public open(booking, e) {
    e.stopPropagation();

    let url = '/bookings/' + booking.Id;

    if (booking.Diff) {
      url = '/timereports/' + booking.Timereport;
    }
    
    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }
  public openNews(id, e) {
    e.stopPropagation();

    let url = '/admin/news/' + id;

    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }
  public openUserMarkedDate(id, e) {
    e.stopPropagation();

    let url = '/usermarkeddates/' + id;

    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }
  public create(date, e) {
    e.stopPropagation();

    if (this.settings.showhour) {
      let start = new Date(date);
      let startOffset = start.getTimezoneOffset();
      this.viewCacheService.add('booking_start', start);
      let end = new Date(date.getTime() + (1000 * 60 * 60));
      let endOffset = end.getTimezoneOffset();
      if (startOffset != endOffset) {
        end = new Date(end.getTime() - ((startOffset - endOffset) * 60000));
      }
      this.viewCacheService.add('booking_end', end);
    }
    else {
      let start = new Date(date);
      let startOffset = start.getTimezoneOffset();
      this.viewCacheService.add('booking_start', start);
      let end = new Date(date.getTime() + (1000 * 60 * 60 * 24));
      let endOffset = end.getTimezoneOffset();
      if (startOffset != endOffset) {
        end = new Date(end.getTime() - ((startOffset - endOffset) * 60000));
      }
      this.viewCacheService.add('booking_end', end);
    }

    let id = this.row.GroupById;

    let url = '/bookings/';
    if (this.permissionService.permissions.ShiftWizard) {
      url += 'wizard/' 
    }

    let category = this.settingService.timeline.groupby;
    if (category == 0) {
      url += '0/user/' + id;
    }
    else if (category == -1 || category == -2) {
      url += '0/level/' + id;
    }
    else {
      url += '0';
    }

    if (url.length > 0) {
      this.router.navigate([url]);
    }
  }
  public goto(e) {
    e.stopPropagation();

    let id = this.row.GroupById;

    if (id != 0) {
      let url = '';
      let category = this.settingService.timeline.groupby;
      if (category == 0) {
        url = '/users/' + id;
      }
      else if (category == -1 || category == -2) {
        url = '/levels/' + id;
      }
      else {
        return;
      }

      if (e.ctrlKey || e.metaKey) {
        window.open(url, '_blank');
      }
      else {
        this.router.navigate([url]);
      }
    }
    else {
      this.settingService.timeline.calendarexpand = !this.settingService.timeline.calendarexpand;
    }
  }
  public showdetail(booking, e) {
    if (booking.Access > 1) {
      if (this.permissionService.permissions.InlineUsers) {
        //With User
        if (booking.RightClick) {
          booking.Users = [];
          booking.RightClick = false;
          booking.IsRightClick = false;
        }
        else {
          booking.IsRightClick = true;
          this.dataService.tokenRequest('/api/v1/bookings/' + booking.Id + '/users', 'GET', {})
            .subscribe((res) => {

              booking.Users = this.listService.groupByInt(
                res.Users,
                'Type',
                [
                  { Id: 1, Name: this.languageService.getItem(30), Sort: 0 },
                  { Id: 0, Name: this.languageService.getItem(423), Sort: 1 },
                  { Id: 2, Name: this.languageService.getItem(279), Sort: 2 },
                  { Id: 3, Name: this.languageService.getItem(280), Sort: 3 },
                  { Id: 4, Name: this.languageService.getItem(1106), Sort: 4 }
                ]);
              booking.RightClick = true;
              booking.IsRightClick = false;
            });
        }
      }
      else {
        //Detail
        this.resetdetail(e);

        this.settings.detail = this.row.GroupById;

        this._detail.row = this.row.GroupById;
        this._detail.left = booking.left;
        this._detail.top = booking.top;
        this._detail.height = booking.height;
        this._detail.width = booking.width;

        this._detail.booking = booking;

        //Header
        let headeritems = 1; //Icons and dateheader
        if (!this.permissionService.permissions.HideLevels) {
          headeritems++; //Levels
        }
        headeritems++; //Header
        if (!this.permissionService.permissions.HideAmount || this._detail.booking.Serie > 0) {
          headeritems++; //Amount or Serie
        }

        //Profile
        let url = '/api/v1/bookings/detail/' + booking.Id + '/timereport/' + booking.Timereport;

        this._detailprofileready = false;
        this.dataService.tokenRequest(url, 'GET', {})
          .subscribe((res) => {

            let extraitems = 1; //Block-Header
            extraitems++; //Bottom-Margin

            let profileheight = (21 * headeritems) + (23 * (res.Profile.length + extraitems));
            if (this._detail.height < profileheight) {
              this._detail.height = profileheight;
            }

            res.Profile.forEach((profile) => {
              let property = this.propertyService.getProperty(profile.Property);
              if (property) {
                profile.Type = property.Type;
                if (property.Type == 'System.DateTime' || property.Type == 'System.Date' || property.Type == 'System.Time') {
                  profile.Value = this.dateTimeService.format(profile.Value, property.Format);
                }
                else if (property.Type == 'System.List' || property.Type == 'System.MultiList') {
                  if (profile.Value != null && profile.Value.length > 0) {
                    let res = '';
                    profile.Value.split('|').forEach((val) => {

                      property.Items.forEach((item) => {
                        if (item.Id == val) {

                          if (res.length > 0) {
                            res += ' | ';
                          }

                          res += item.Name;
                        }
                      });
                    });
                    profile.Value = res;
                  }
                }
              }
            });
            this._detail.profile = res.Profile;

            this._detailprofileready = true;
          });

        //Users
        this.dataService.tokenRequest('/api/v1/bookings/' + booking.Id + '/users', 'GET', {})
          .subscribe((res) => {

            this._detail.users = this.listService.groupByInt(
              res.Users,
              'Type',
              [
                { Id: 1, Name: this.languageService.getItem(30), Sort: 0 },
                { Id: 0, Name: this.languageService.getItem(423), Sort: 1 },
                { Id: 2, Name: this.languageService.getItem(279), Sort: 2 },
                { Id: 3, Name: this.languageService.getItem(280), Sort: 3 },
                { Id: 4, Name: this.languageService.getItem(1106), Sort: 4 }
              ]);

            let userlength = Object.keys(this._detail.users).length;

            let extraitems = userlength; //Block-Header 
            extraitems += userlength; //Bottom-Margin

            let userheight = (20 * headeritems) + (30 * (res.Users.length + extraitems));
            if (this._detail.height < userheight) {
              this._detail.height = userheight;
            }
          });
      }
    }

    return false;
  }
  public resetdetail(e) {

    if (e) {
      e.stopPropagation();
    }

    this.settings.detail = -1;

    this._detail = {
      row: -1,
      left: 0,
      top: 0,
      height: 0,
      width: 0,
      booking: {},
      profile: [],
      users: []
    };

    return false;
  }
  public timelineshow(show) {
    return (this.settingService.timeline.timelineshow & show) == show;
  }
  //Pinned
  public managePinned() {
    this.settings.emptyRowPinned = !this.settings.emptyRowPinned;

    let temp: any = this.settings.emptyRowPinned;
    localStorage.setItem('emptyRowPinned', temp);
  }


  //DragAndDrop
  public drop(e) {
    e.preventDefault();
    let title = e.dataTransfer.getData("DragAndDropTitle");
    let shift = e.dataTransfer.getData("DragAndDrop");
    let serie = e.dataTransfer.getData("DragAndDropSerie");
    let resource = e.currentTarget.id.replace('GroupBy_', '');

    this._dropbody = 'Är du säker på att du vill boka ' + this.row.GroupByList[0] + ' på pass [' + title + ']';
    this._droppedStep1 = true;
    this._dropevent = { target: e.currentTarget, shift: shift, resource: resource, serie: serie };
  }

  public allowDrop(e) {

    //Reset
    this.resetdraganddrop(e.currentTarget);

    let resource = e.currentTarget.id.replace('GroupBy_', '');
    if (resource > 0) {
      e.preventDefault();

      //Current
      e.currentTarget.classList.add('swe-drop-target');
    }

  }

  public drag(e) {

    let id = e.target.id.replace('Booking_', '')

    this.row.Bookings.forEach((booking) => {
      if (booking.Id == id) {
        e.dataTransfer.setData("DragAndDropTitle", booking.title);
        e.dataTransfer.setData("DragAndDropSerie", booking.Serie);
      }
    });

    e.dataTransfer.setData("DragAndDrop", id);
  }
  public dropacceptStep1(e) {
    this._dropbody = '';
    this._droppedStep1 = false;

    this.resetdraganddrop(e.target);

    this._droppedStep2 = true;
    this._droppedSerieId = e.serie;
  }
  public dropacceptStep2(e, dropevent) {

    this._dropevent = null;
    this._droppedStep2 = false;
    this._droppedSerieId = 0;
    this._droppedAffectAllOnly = false;

    let filter = {
      Type: 1,
      AutoRemind: true,
      AutoRemoveATK: true,
      ManualNotify: true,
      ManualNotifyEmail: this.notifyemail,
      ManualNotifySms: this.notifysms,
      Series: e
    };

    this.dataService.tokenRequest('/api/v1/bookings/' + dropevent.shift + '/users/' + dropevent.resource, 'POST', filter, 'text')
      .subscribe((res) => {

        let type = 'success';
        if (res != this.languageService.getItem(34)) {
          type = 'warning';
        }

        this.alertService.Add({ message: res, type: type });

        this.settings.marked = dropevent.shift;
        this.settings.markedgroup = dropevent.resource;

        this.settingsChange.emit({ settings: this.settings, reload: true });
      });
  }
  public dropdecline(e) {
    this._dropbody = '';
    this._droppedStep1 = false;
    this._dropevent = null;

    this.resetdraganddrop(e.target);
  }
  private resetdraganddrop(current) {
    let rows: any[] = current.parentElement.parentElement.parentElement.children;

    for (let i = 0; i < rows.length; i++) {
      let element = rows[i];
      if (element.children.length > 0) {
        let subelement = element.firstElementChild;
        if (subelement.children.length > 0) {
          subelement.firstElementChild.classList.remove('swe-drop-target');
        }
      }
    }
  }



  //Functions
  private load() {
    
    /*Rows per shift*/
    let rows = this.permissionService.permissions.CalendarRows;
    if (typeof rows == "undefined" || rows == null) {
      rows = 1;
    }
    this._shiftheight = (21 * rows);
    /****************/

    /*Rows per availability*/
    let availablerows = this.permissionService.permissions.AvailabilityRows;
    if (typeof availablerows == "undefined" || availablerows == null) {
      availablerows = 1;
    }
    this._availabilityheight = 20 * availablerows;
    /****************/

    if (this.row) {

      if (this.row.WarningTexts == null) { this.row.WarningTexts = ''; }
      if (this.row.PriorityTexts == null) { this.row.PriorityTexts = ''; }

      let item = this.row;

      this._isEmpty = (item.Bookings.length == 0 && item.UserMarkedDates.length == 0);

      let maxtop = 0;
      let objectList = [];

      let useravailablesum = [];
      if (this.settings.showhour) {
        for (var i = 0; i < this.settings.hours.length; i++) {
          useravailablesum.push(0);
        }
      }
      else {
        for (var i = 0; i < this.settings.days.length; i++) {
          useravailablesum.push(0);
        }
      }

      maxtop = this.manageNews(maxtop, objectList);

      maxtop = this.manageUserMarkedDates(useravailablesum, maxtop, objectList);

      maxtop = this.manageBookings(useravailablesum, maxtop, objectList);

      this._height = 0;
      if (item.Bookings.length > 0) {
        this._height = maxtop + this._shiftheight;
      }
      else if (item.UserMarkedDates.length > 0) {
        this._height = maxtop + this.settings.availabilityheight;
      }
      else if (item.News.length > 0) {
        this._height = maxtop + 20;
      }

      this._minheight = 80;
      let __minheight = 14; //Padding + Border
      item.GroupByList.forEach((groupby) => {
        if (groupby.length > 0) {
          __minheight += 21;
        }
      });
      if (__minheight > this._minheight) {
        this._minheight = __minheight;
      }
      
    }

  }
  private manageNews(maxtop: number, objectList: any[]) {

    let item = this.row;

    item.News.forEach((news) => {

      let start = new Date(news.Publish);
      let end = new Date(news.Expires);

      //Header
      news.header = this.dateTimeService.header(start, end, false);
      news.header += " \n";
      news.header += news.Title;
      news.header += " \n";
      news.header += news.Body;
      
      if (!this.settingService.timeline.exactrendering && !this.settings.showhour) {
        start = new Date(start.getFullYear(), start.getMonth(), start.getDate());
        if (end.getHours() == 0 && end.getMinutes() == 0 && end.getSeconds() == 0) {
          end = new Date(end.getFullYear(), end.getMonth(), end.getDate());
        }
        else {
          end = new Date(end.getFullYear(), end.getMonth(), end.getDate() + 1);
        }
      }

      if (start < this.settings.start) {
        start = this.settings.start;
        news.outsidestart = true;
      }
      if (end > this.settings.end) {
        end = this.settings.end;
        news.outsideend = true;
      }

      let objday = (end.getTime() - start.getTime()) / (this.settings.end.getTime() - this.settings.start.getTime());
      news.width = 100 * objday;

      let objstart = (start.getTime() - this.settings.start.getTime()) / (this.settings.end.getTime() - this.settings.start.getTime());
      news.left = 100 * objstart;

      news.height = 20;

      news.top = this.inside(objectList, start, end, news.height);
      if (news.top > maxtop) {
        maxtop = news.top;
      }
    });

    return maxtop;
  }
  private manageUserMarkedDates(useravailablesum: any[], maxtop: number, objectList: any[]) {

    let item = this.row;

    this._availablesum = 0;
    this._availableunit = 'h';
    item.UserMarkedDates.forEach((usermarkeddate) => {

      //Interval Sum
      this.intervalAvailableSum(usermarkeddate, useravailablesum);

      let start = new Date(usermarkeddate.Start);
      let end = new Date(usermarkeddate.End);

      let factor = usermarkeddate.Factor;

      let diffInMinutes = factor * (end.getTime() - start.getTime()) / (1000 * 60);

      //Sum
      if (item.GroupById != 0 && (usermarkeddate.Value > 0)) {
        this._availablesum += (diffInMinutes / 60); //Make Hours
      }

      //Header
      usermarkeddate.dateheader = this.dateTimeService.header(start, end, false);
      

      //Title
      usermarkeddate.title = usermarkeddate.Name
      usermarkeddate.title += ", ";
      usermarkeddate.title += usermarkeddate.dateheader;
      usermarkeddate.title += ", ";
      usermarkeddate.title += usermarkeddate.Type;
      if (usermarkeddate.Factor != 1) {
        usermarkeddate.title += ", " + usermarkeddate.Factor;
      }
      if (usermarkeddate.Comment != null && usermarkeddate.Comment.length > 0) {
        usermarkeddate.title += ", " + usermarkeddate.Comment;
      }
      


      if (!this.settingService.timeline.exactrendering && !this.settings.showhour) {
        start = new Date(start.getFullYear(), start.getMonth(), start.getDate());
        if (end.getHours() == 0 && end.getMinutes() == 0 && end.getSeconds() == 0) {
          end = new Date(end.getFullYear(), end.getMonth(), end.getDate());
        }
        else {
          end = new Date(end.getFullYear(), end.getMonth(), end.getDate() + 1);
        }
      }

      if (start < this.settings.start) {
        start = this.settings.start;
        usermarkeddate.outsidestart = true;
      }
      if (end > this.settings.end) {
        end = this.settings.end;
        usermarkeddate.outsideend = true;
      }

      let objday = (end.getTime() - start.getTime()) / (this.settings.end.getTime() - this.settings.start.getTime());
      usermarkeddate.width = 100 * objday;

      let objstart = (start.getTime() - this.settings.start.getTime()) / (this.settings.end.getTime() - this.settings.start.getTime());
      usermarkeddate.left = 100 * objstart;

      usermarkeddate.height = this.settings.availabilityheight;

      let top = 0;
      if (item.News.length > 0) { top += 20; }

      usermarkeddate.top = this.inside(objectList, start, end, usermarkeddate.height, top);
      if (usermarkeddate.top > maxtop) {
        maxtop = usermarkeddate.top;
      }
    });
    if (this.permissionService.permissions.NumberOfHours > 0) {
      this._availablesum /= this.permissionService.permissions.NumberOfHours;
      this._availableunit = 'd';
    }

    return maxtop;
  }
  private manageBookings(useravailablesum: any[], maxtop: number, objectList: any[]) {

    let item = this.row;
    let bits = 0;

    this._shiftsum = 0;
    this._shiftunit = '';

    item.Bookings.forEach((booking) => {

      booking.RightClick = booking.Users.length > 0;

      //Users
      booking.Users = this.listService.groupByInt(
        booking.Users,
        'Type',
        [
          { Id: 1, Name: this.languageService.getItem(30), Sort: 0 },
          { Id: 0, Name: this.languageService.getItem(423), Sort: 1 },
          { Id: 2, Name: this.languageService.getItem(279), Sort: 2 },
          { Id: 3, Name: this.languageService.getItem(280), Sort: 3 },
          { Id: 4, Name: this.languageService.getItem(1106), Sort: 4 }
        ]);

      //Interval Sums
      if (!booking.MinusTime) {
        if (item.GroupById != 0 || (this.settingService.timeline.groupby == -3)) {
          bits = this.intervalSum(booking, useravailablesum, bits);
        }
        else {
          this.intervalNotFilledSum(booking);
        }
      }

      //DateTimes
      let start = new Date(booking.Start);
      let end = new Date(booking.End);
      let includedbreak = booking.Break;

      //Sum DateTimes
      let sumstart = start;
      if (start < this.settings.start) {
        sumstart = this.settings.start;
      }
      let sumend = end;
      if (end > this.settings.end) {
        sumend = this.settings.end;
      }

      let diffInMinutes = (sumend.getTime() - sumstart.getTime()) / (1000 * 60);

      //Remove Weekend and Holidays
      if (this.permissionService.permissions.ExcludeHolidays) {
        this.settings.cols.forEach((day) => {
          if (day.date.getDay() == 0 || day.date.getDay() == 6 || day.holiday.length > 0) {
            let daystart = day.date;
            let dayend = this.dateTimeService.addDays(day.date, 1);

            if (daystart <= sumstart && sumend <= dayend) {
              //Booking starts and ends in Weekend or Holiday
              diffInMinutes = 0;
            }
            else if (daystart >= sumstart && sumend >= dayend) {
              //Booking starts before and ends after Weekend or Holiday
              diffInMinutes -= (24 * 60);
            }
            else if (daystart < sumstart && sumstart < dayend) {
              //Booking starts in Weekend or Holiday
              diffInMinutes -= (dayend.getTime() - sumstart.getTime()) / (1000 * 60);
            }
            else if (daystart < sumend && sumend < dayend) {
              //Booking ends in Weekend or Holiday
              diffInMinutes -= (sumend.getTime() - daystart.getTime()) / (1000 * 60);
            }
          }
        });
      }

      //EmploymentPlan
      let wholedays = (start.getHours() == 0 && start.getMinutes() == 0 && end.getHours() == 0 && end.getMinutes() == 0);
      if (booking.EmploymentPlan && !this.settings.showhour && wholedays) {

        let calendarDayStart = this.permissionService.permissions.CalendarDayStart;
        let calendarDayEnd = this.permissionService.permissions.CalendarDayEnd;
        if (calendarDayStart > 0 || calendarDayEnd < 24) {
          let numberOfDays = diffInMinutes / (60 * 24);
          let notWorkingHoursPerDay = (24 - calendarDayEnd) + calendarDayStart;
          diffInMinutes -= numberOfDays * notWorkingHoursPerDay * 60;
        }
      }

      //Offsets
      let offsetstart = sumstart.getTimezoneOffset();
      let offsetend = sumend.getTimezoneOffset();
      let diffOffset = 0;
      if (offsetstart != offsetend) {
        diffInMinutes -= (offsetend - offsetstart);
        diffOffset = (offsetend - offsetstart) * 60 * 1000;
      }


      //Sum
      if (!booking.MinusTime) {
        if (item.GroupById != 0) {
          this._shiftsum += (diffInMinutes - includedbreak) / 60; //Make Hours
        }
        else {
          this._shiftsum += (booking.Max - booking.Amount) * (diffInMinutes - includedbreak) / 60; //Make Hours
        }
      }

      //BookingDateHeader
      let overmidnight = (start.getFullYear() != end.getFullYear() || start.getMonth() != end.getMonth() || start.getDate() != end.getDate());
      booking.dateheader = this.dateTimeService.header(start, end, !overmidnight);
      
      //BookingTitle
      booking.title = '';
      if (!this.permissionService.permissions.HideLevels) {
        booking.title += booking.Level + ", " + String.fromCharCode(10);
      }
      if (booking.Header.length > 0) {
        booking.title += booking.Header + ", " + String.fromCharCode(10);
      }
      if (booking.Desc.length > 0) {
        booking.title += booking.Desc + ", " + String.fromCharCode(10);
      }
      booking.title += booking.dateheader + ", " + String.fromCharCode(10);
      if (!this.permissionService.permissions.HideAmount) {
        booking.title += booking.Amount + '(' + booking.Max + ')' + String.fromCharCode(10);
      }

      let oldstart = start;
      let oldend = end;

      //Adjust booking if not exact rendering
      if (!this.settingService.timeline.exactrendering && !this.settings.showhour) {
        start = new Date(start.getFullYear(), start.getMonth(), start.getDate());
        if (end.getHours() == 0 && end.getMinutes() == 0 && end.getSeconds() == 0) {
          end = new Date(end.getFullYear(), end.getMonth(), end.getDate());
        }
        else {
          end = new Date(end.getFullYear(), end.getMonth(), end.getDate() + 1);
        }
      }

      //Outside Offset
      let startOutside = this.settings.start;
      let startOutsideOffset = startOutside.getTimezoneOffset();
      let endOutside = this.settings.end;
      let endOutsideOffset = endOutside.getTimezoneOffset();
      if (startOutsideOffset != endOutsideOffset) {
        endOutside = new Date(endOutside.getTime() - ((startOutsideOffset - endOutsideOffset) * 60000));
      }

      //Adjust booking if outside calendar scope
      let comparestart = oldstart;
      if (start < startOutside) {
        start = this.settings.start;
        comparestart = start;
        booking.outsidestart = true;
        if (end < this.settings.start) {
          end = this.settings.start;
        }
      }
      let compareend = oldend;
      if (end > endOutside) {
        end = this.settings.end;
        compareend = end;
        booking.outsideend = true;
        if (start > this.settings.end) {
          start = this.settings.end;
        }
      }

      //Included Break
      let breaks = [{ Break: booking.Break, BreakStart: booking.BreakStart }];
      if (this.permissionService.permissions.MultipleBreak) {
        breaks = booking.Breaks;
      }
      breaks.forEach((breakObj:any) => {

        if (this.permissionService.permissions.BreakStart && breakObj.Break > 0) {

          let breakstart = (typeof breakObj.BreakStart != 'undefined') ? new Date(breakObj.BreakStart) : start;
          let breakend = new Date(breakstart.getTime() + (breakObj.Break * 60 * 1000));

          //Title
          breakObj.breaktitle = this.languageService.getItem(785) + ', ' + this.dateTimeService.header(breakstart, breakend, false);

          //Adjust break if not exact rendering
          if (!this.settingService.timeline.exactrendering && !this.settings.showhour) {
            let breakstartpercentage = (breakstart.getTime() - comparestart.getTime()) / (compareend.getTime() - comparestart.getTime());
            let breakendpercentage = (breakend.getTime() - comparestart.getTime()) / (compareend.getTime() - comparestart.getTime());

            let ticks = (end.getTime() - start.getTime());

            breakstart = new Date(start.getTime() + (breakstartpercentage * ticks));
            breakend = new Date(start.getTime() + (breakendpercentage * ticks));

          }
        
          //Adjust break if outside calendar scope
          if (breakstart < start) {
            breakstart = start;
          }
          if (breakend > end) {
            breakend = end;
          }
        
          if (breakstart > end) {
            //Starts after visible booking
            breakObj.breakleft = booking.width;
            breakObj.breakwidth = 0;
          }
          else if (breakstart < start) {
            //Starts before visible booking
            breakObj.breakleft = 0;

            let breaklength = (breakend.getTime() - breakstart.getTime()) / (end.getTime() - start.getTime());
            breakObj.breakwidth = 100 * breaklength;
          }
          else {
            //Start inside visible booking
            let breakstartpercentage = (breakstart.getTime() - start.getTime()) / (end.getTime() - start.getTime());
            breakObj.breakleft = 100 * breakstartpercentage;

            let breaklength = (breakend.getTime() - breakstart.getTime()) / (end.getTime() - start.getTime());
            breakObj.breakwidth = 100 * breaklength;
          }

          if ((breakObj.breakleft + breakObj.breakwidth) > 100) {
            breakObj.breakwidth = 100 - breakObj.breakleft;
          }

          /*Color*/
          if (this.settings.showweekendcolor && (breakstart.getDay() == 0 || breakstart.getDay() == 6)) {
            breakObj.breakcolor = "#d9edf7";
          }
          else if (!this.settings.showweekendcolor || (breakstart.getDay() > 0 && breakstart.getDay() < 6)) {
            breakObj.breakcolor = "#efefef";
          }

        }
      });
      booking.Breaks = breaks;

      //Activities
      booking.Activities.forEach((activity) => {

        if (item.GroupById != 0 || (this.settingService.timeline.groupby == -3)) {
          this.intervalSumActivity(activity, useravailablesum);
        }
        else {
          this.intervalNotFilledSumActivity(activity, (booking.Max - booking.Amount));
        }

        let activitystart = new Date(activity.Start);
        let activityend = new Date(activity.End);

        let diffInMinutesActivity = (activityend.getTime() - activitystart.getTime()) / (1000 * 60);

        let offsetstartactivity = activitystart.getTimezoneOffset();
        let offsetendactivity = activityend.getTimezoneOffset();
        if (offsetstartactivity != offsetendactivity) {
          diffInMinutesActivity -= (offsetendactivity - offsetstartactivity);
        }

        //Activity adjustment on booking
        let activityadjustment = 0;
        if (item.GroupById != 0) {
          activityadjustment = diffInMinutesActivity / 60; //Make Hours
        }
        else {
          activityadjustment = (booking.Max - booking.Amount) * (diffInMinutesActivity) / 60; //Make Hours
        }

        this._shiftsum -= activityadjustment * (1 - activity.Factor);

        //Adjust activity if not exact rendering
        if (!this.settingService.timeline.exactrendering && !this.settings.showhour) {
          let activitystartpercentage = (activitystart.getTime() - comparestart.getTime()) / (compareend.getTime() - comparestart.getTime());
          let activityendpercentage = (activityend.getTime() - comparestart.getTime()) / (compareend.getTime() - comparestart.getTime());

          let ticks = (end.getTime() - start.getTime());

          activitystart = new Date(start.getTime() + (activitystartpercentage * ticks));
          activityend = new Date(start.getTime() + (activityendpercentage * ticks));

        }

        //Adjust activity if outside calendar scope
        if (activitystart < start) {
          activitystart = start;
        }
        if (activityend > end) {
          activityend = end;
        }

        if (activitystart > end) {
          //Starts after visible booking
          activity.left = booking.width;
          activity.width = 0;
        }
        else if (activitystart < start) {
          //Starts before visible booking
          activity.left = 0;

          let activitylength = (activityend.getTime() - activitystart.getTime()) / (end.getTime() - start.getTime());
          activity.width = 100 * activitylength;
        }
        else {
          //Start inside visible booking
          let activitystartpercentage = (activitystart.getTime() - start.getTime()) / (end.getTime() - start.getTime());
          activity.left = 100 * activitystartpercentage;

          let activitylength = (activityend.getTime() - activitystart.getTime()) / (end.getTime() - start.getTime());
          activity.width = 100 * activitylength;
        }

        if ((activity.left + activity.width) > 100) {
          activity.width = 100 - activity.left;
        }

        activity.title = activity.Type;
        if (activity.Text.length > 0) {
          activity.title += " (" + activity.Text + ")";
        }
        activity.title += ", " + this.dateTimeService.header(new Date(activity.Start), new Date(activity.End), false)
      });


      //Offsets
      let offsetLeft = this.settings.start.getTimezoneOffset();
      let offsetRight = this.settings.end.getTimezoneOffset();
      let diffInterval = 0;
      if (offsetLeft != offsetRight) {
        diffInterval = (offsetRight - offsetLeft) * 60 * 1000;
      }

      //Set booking properties
      let shiftday = (end.getTime() - start.getTime()) / (this.settings.end.getTime() - this.settings.start.getTime() + diffInterval);
      booking.width = 100 * shiftday;

      let shiftstart = (start.getTime() - this.settings.start.getTime()) / (this.settings.end.getTime() - this.settings.start.getTime() + diffInterval);
      booking.left = 100 * shiftstart;

      booking.height = booking.EmploymentPlan ? 20 : this._shiftheight;

      let top = 0;
      if (item.News.length > 0) { top += 20; }
      if (item.UserMarkedDates.length > 0) { top += this.settings.availabilityheight; }

      booking.top = this.inside(objectList, start, end, booking.height, top);
      if (booking.top > maxtop) {
        maxtop = booking.top;
      }

    });

    

    if (this.permissionService.permissions.NumberOfHours > 0) {
      this._shiftsum /= this.permissionService.permissions.NumberOfHours;
      this._shiftunit = 'd';
    }

    return maxtop;
  }


  //IntervalSums
  private intervalAvailableSum(usermarkeddate: any, useravailablesum: any[]) {
    if (usermarkeddate.Value <= 0) {
      return;
    }

    let bStart = new Date(usermarkeddate.Start).getTime();
    let bEnd = new Date(usermarkeddate.End).getTime();

    let factor = usermarkeddate.Factor;

    let intervalArr = this.settings.cols;

    let diff = (intervalArr.length > 1) ? (intervalArr[1].date - intervalArr[0].date) : (new Date(this.settingService.end('booking')).getTime() - intervalArr[0].date.getTime());
    for (let i = 0; i < intervalArr.length; i++) {

      let iStart = intervalArr[i].date.getTime();
      let iEnd = iStart + diff;
      if ((iStart <= bStart && bStart < iEnd) //Starts in interval
        ||
        (iStart < bEnd && bEnd <= iEnd) //Ends in interval 
        ||
        (iStart > bStart && bEnd > iEnd) //Spans over whole interval
      ) {

        let calcStart = bStart < iStart ? iStart : bStart;
        let calcEnd = bEnd > iEnd ? iEnd : bEnd;

        let calc = factor * (calcEnd - calcStart) / (60 * 60 * 1000);
        if (this.settingService.timeline.sumoptions >= 1) {
          calc = 1;
        }

        this.settings.availablesum[i] += calc;
        this.row.availablesum[i] += calc;
        useravailablesum[i] = calc;
        
      }

    }
  }
  private intervalSum(booking: any, useravailablesum: any[], bits: number): number {
    let start = new Date(booking.Start);
    let bStart = start.getTime();
    let end = new Date(booking.End);
    let bEnd = end.getTime();
    let bBreak = booking.Break * 60 * 1000;
    let bBreakStart = new Date(booking.BreakStart).getTime();

    let procentTime = 1 - (bBreak / (bEnd - bStart));

    let intervalArr = this.settings.cols;

    //Calendar Day Time----------------
    let calendarDayStart = this.permissionService.permissions.CalendarDayStart;
    let calendarDayEnd = this.permissionService.permissions.CalendarDayEnd;
    let calendarDayTime = 0;
    if (booking.EmploymentPlan && (calendarDayStart > 0 || calendarDayEnd < 24)) {
      calendarDayTime = (calendarDayEnd - calendarDayStart) * this.dateTimeService.onehour;
    }
    //---------------------------------

    //Intervall Length
    let diff = (intervalArr.length > 1) ? (intervalArr[1].date - intervalArr[0].date) : (new Date(this.settingService.end('booking')).getTime() - intervalArr[0].date.getTime());
    if (calendarDayTime > 0 && calendarDayTime < diff) {
      diff = calendarDayTime;
    }

    for (let i = 0; i < intervalArr.length; i++) {

      let iStart = intervalArr[i].date.getTime();
      if (calendarDayTime > 0) {
        let cStart = new Date(intervalArr[i].date.getFullYear(), intervalArr[i].date.getMonth(), intervalArr[i].date.getDate(), calendarDayStart, 0, 0).getTime();
        if (cStart > iStart) {
          iStart = cStart;
        }
      }

      let iEnd = iStart + diff;
      if ((iStart <= bStart && bStart < iEnd) //Starts in interval
        ||
        (iStart < bEnd && bEnd <= iEnd) //Ends in interval 
        ||
        (iStart > bStart && bEnd > iEnd) //Spans over whole interval
      ) {

        let calcStart = bStart < iStart ? iStart : bStart;
        let calcEnd = bEnd > iEnd ? iEnd : bEnd;

        let calc = 0;
        if (this.permissionService.permissions.BreakStart) {
          let tickTime = 0;

          let breaks = [{ Break: booking.Break, BreakStart: booking.BreakStart }];
          if (this.permissionService.permissions.MultipleBreak) {
            breaks = booking.Breaks;
          }
          for (let i = 0; i < breaks.length; i++) {
            let breakObj = breaks[i];
            bBreak = breakObj.Break * 60 * 1000;
            bBreakStart = new Date(breakObj.BreakStart).getTime();

            if (bBreak > 0) {
              let startInside = (calcStart <= bBreakStart && bBreakStart < calcEnd);
              let endInside = (calcStart < (bBreakStart + bBreak) && (bBreakStart + bBreak) <= calcEnd);
              if (startInside && endInside) {
                tickTime += bBreak;
              }
              else if (startInside) {
                tickTime += calcEnd - bBreakStart;
              }
              else if (endInside) {
                tickTime += (bBreakStart + bBreak) - calcStart;
              }
            }
          }

          calc = (calcEnd - calcStart - tickTime) / (60 * 60 * 1000);
        }
        else {
          calc = ((calcEnd - calcStart) * procentTime) / (60 * 60 * 1000);
        }

        let alreadysum = false;
        if (this.settingService.timeline.sumoptions == 1) {
          if (calc > 0) {
            calc = 1;
          }
          let binary = Math.pow(2, i);
          alreadysum = ((bits & binary) == binary);
          if (!alreadysum) {
            bits += binary;
          }
        }
        else if (this.settingService.timeline.sumoptions == 2) {
          if (calc > 0) {
            calc = 1;
          }
        }

        if (!alreadysum) {
          this.settings.sum[i] += calc;
          this.row.sum[i] += calc;

          if (useravailablesum[i] > 0) {
            if (calc < useravailablesum[i]) {
              this.settings.availablesum[i] -= calc;
              this.row.availablesum[i] -= calc;
            }
            else {
              this.settings.availablesum[i] -= useravailablesum[i];
              this.row.availablesum[i] -= useravailablesum[i];
            }
          }
        }
      }

    }

    return bits;
  }
  private intervalNotFilledSum(booking: any) {
    let bStart = new Date(booking.Start).getTime();
    let bEnd = new Date(booking.End).getTime();
    let bBreak = booking.Break * 60 * 1000;
    let bBreakStart = new Date(booking.BreakStart).getTime();

    let procentTime = 1 - (bBreak / (bEnd - bStart));

    let intervalArr = this.settings.cols;

    for (let i = 0; i < intervalArr.length; i++) {

      let iDateStart = intervalArr[i].date;
      let iDateEnd = new Date(this.settingService.end('booking')).getTime();
      if (i < intervalArr.length - 1) { iDateEnd = intervalArr[i + 1].date; }
      let diff = iDateEnd - iDateStart;

      let iStart = intervalArr[i].date.getTime();
      let iEnd = iStart + diff;
      if ((iStart <= bStart && bStart < iEnd) //Starts in interval
        ||
        (iStart < bEnd && bEnd <= iEnd) //Ends in interval 
        ||
        (iStart > bStart && bEnd > iEnd) //Spans over whole interval
      ) {

        let calcStart = bStart < iStart ? iStart : bStart;
        let calcEnd = bEnd > iEnd ? iEnd : bEnd;

        let calc = 0;
        if (this.permissionService.permissions.BreakStart) {
          let tickTime = 0;

          let breaks = [{ Break: booking.Break, BreakStart: booking.BreakStart }];
          if (this.permissionService.permissions.MultipleBreak) {
            breaks = booking.Breaks;
          }
          for (let i = 0; i < breaks.length; i++) {
            let breakObj = breaks[i];
            bBreak = breakObj.Break * 60 * 1000;
            bBreakStart = new Date(breakObj.BreakStart).getTime();

            if (bBreak > 0) {
              let startInside = (calcStart <= bBreakStart && bBreakStart < calcEnd);
              let endInside = (calcStart < (bBreakStart + bBreak) && (bBreakStart + bBreak) <= calcEnd);
              if (startInside && endInside) {
                tickTime += bBreak;
              }
              else if (startInside) {
                tickTime += calcEnd - bBreakStart;
              }
              else if (endInside) {
                tickTime += (bBreakStart + bBreak) - calcStart;
              }
            }
          }

          calc = (calcEnd - calcStart - tickTime) / (60 * 60 * 1000);
        }
        else {
          calc = ((calcEnd - calcStart) * procentTime) / (60 * 60 * 1000);
        }

        if (this.settingService.timeline.sumoptions >= 1) {
          if (calc > 0) {
            calc = 1;
          }
        }

        this.settings.notfilledsum[i] += calc * (booking.Max - booking.Amount);
        this.row.notfilledsum[i] += calc * (booking.Max - booking.Amount);
      }

    }
  }
  private intervalSumActivity(activity: any, useravailablesum: any[]) {
    let aStart = new Date(activity.Start).getTime();
    let aEnd = new Date(activity.End).getTime();

    let intervalArr = this.settings.cols;

    let diff = (intervalArr.length > 1) ? (intervalArr[1].date - intervalArr[0].date) : (new Date(this.settingService.end('booking')).getTime() - intervalArr[0].date.getTime());
    for (let i = 0; i < intervalArr.length; i++) {

      let iStart = intervalArr[i].date.getTime();
      let iEnd = iStart + diff;
      if ((iStart <= aStart && aStart < iEnd) //Starts in interval
        ||
        (iStart < aEnd && aEnd <= iEnd) //Ends in interval 
        ||
        (iStart > aStart && aEnd > iEnd) //Spans over whole interval
      ) {

        let calcStart = aStart < iStart ? iStart : aStart;
        let calcEnd = aEnd > iEnd ? iEnd : aEnd;

        let calc = (calcEnd - calcStart) / (60 * 60 * 1000);
        if (this.settingService.timeline.sumoptions >= 1) {
          calc = 1;
        }
        calc *= (1 - activity.Factor);

        this.settings.sum[i] -= calc;
        this.settings.cols[i].sum -= calc;

        if (useravailablesum[i] > 0) {
          if (calc < useravailablesum[i]) {
            this.settings.availablesum[i] += calc;
            this.row.availablesum[i] += calc;
          }
          else {
            this.settings.availablesum[i] += useravailablesum[i];
            this.row.availablesum[i] += useravailablesum[i];
          }
        }
      }

    }
  }
  private intervalNotFilledSumActivity(activity: any, notfilled: number) {
    let aStart = new Date(activity.Start).getTime();
    let aEnd = new Date(activity.End).getTime();

    let intervalArr = this.settings.cols;

    let diff = (intervalArr.length > 1) ? (intervalArr[1].date - intervalArr[0].date) : (new Date(this.settingService.end('booking')).getTime() - intervalArr[0].date.getTime());
    for (let i = 0; i < intervalArr.length; i++) {

      let iStart = intervalArr[i].date.getTime();
      let iEnd = iStart + diff;
      if ((iStart <= aStart && aStart < iEnd) //Starts in interval
        ||
        (iStart < aEnd && aEnd <= iEnd) //Ends in interval 
        ||
        (iStart > aStart && aEnd > iEnd) //Spans over whole interval
      ) {

        let calcStart = aStart < iStart ? iStart : aStart;
        let calcEnd = aEnd > iEnd ? iEnd : aEnd;

        let calc = (calcEnd - calcStart) / (60 * 60 * 1000);
        if (this.settingService.timeline.sumoptions >= 1) {
          calc = 1;
        }
        calc *= (1 - activity.Factor);

        this.settings.notfilledsum[i] -= calc * notfilled;
        this.row.notfilledsum[i] -= calc * notfilled;
      }

    }
  }

  //Inside
  private inside(arr: any[], start: Date, end: Date, height:number, top:number = 0) {

    let insides = [];

    for (var i = arr.length - 1; i >= 0; i--) {

      var source = arr[i];
      if (this.isInside(source, start, end)) {

        if (source.insides.length > 0) {

          /*
          Collect insides from current compare object                                              
          Each entry has its own "insides" to avoid having to go through all the records every time
          */
          for (var j = 0; j < source.insides.length; j++) {
            var inside = source.insides[j];
            if (this.isInside(inside, start, end)) {
              insides.push(inside);
            }
          }
          /*********************************************/

          //Add current compare object
          insides.push(source);

          insides.sort(function (a, b) { return a.top - b.top });

          for (var j = 0; j < insides.length; j++) {
            var inside = insides[j];
            if ((top + height) <= inside.top) {
              break;
            }
            else {
              top = (inside.top + inside.height);
            }
          }

        }
        else {
          //Only this item is inside
          insides.push(source);
          top = source.height;
        }

        //No need to walk further
        break;
      }
    }

    //Add new item
    arr.push({ start: start, end: end, insides: insides, top: top, height: height });
    //arr.sort(function (a, b) {
    //  if (a.start === b.start) {
    //    return a.end > b.end ? -1 : 1
    //  } else {
    //    return a.start < b.start ? -1 : 1
    //  }
    //});

    return top;
  }
  private isInside(item: any, start: Date, end: Date) {

    if (item.start <= start && start < item.end) {
      //Starts in interval
      return true;
    }
    else if (item.start < end && end <= item.end) {
      //Ends in interval
      return true;
    }
    else if (item.start > start && end > item.end) {
      //Over whole interval
      return true;
    }

    return false;
  }
}
