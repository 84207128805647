import { Component, Input, OnChanges, Output, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { GeneralService } from '../../_services/general.service';
import { PropertyService } from '../../_services/property.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';
import { SettingService } from '../../_services/setting.service';
import { ListService } from '../../_services/list.service';
import { DateTimeService } from '../../_services/datetime.service';
import { ViewCacheService } from '../../_services/viewcache.service';
import { ListUtility } from '../../_utilities/list.utility';


@Component({
  selector: 'swe-bookingusers',
  templateUrl: './bookingusers.component.html'
})
export class BookingUsersComponent implements OnInit, OnChanges {
  @Input() id: number;
  @Input() open: boolean;
  @Input() bookingdata: any = {};
  @Input() availabledata: any = {};
  
  private _bookingusers: any[] = [];
  private _groupedby: {};
  private _markaccess: boolean = false;
  private _loading: boolean;
  private _loaded: boolean;
  //private _checkcounter: number = 0;
  //private _ischecked: boolean = false;
  private _checknotificationemail: boolean = this.permissionService.permissions.NotifyOnCancellationEmail == 1 ? true : false;
  private _checknotificationsms: boolean = this.permissionService.permissions.NotifyOnCancellationSms == 1 ? true : false;
  private _usermenu: boolean = false;
  private _showremind: boolean = false;
  private _sortId: number = 0;
  private _activerow: number = -1;
  private _listutility: ListUtility = new ListUtility();
  //Popup
  private _timereportIdList: any[] = [];
  private _popup: any[] = [{ Visible: false, Event: null, Option: 3 }];



  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public generalService: GeneralService,
    public propertyService: PropertyService,
    public settingService: SettingService,
    private dataService: DataService,
    private dateTimeService: DateTimeService,
    private alertService: AlertService,
    private listService: ListService,
    private viewCacheService: ViewCacheService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    
  }

  ngOnInit() {
    this._listutility.dataaccessname = 'MarkAccess';
    this._listutility.dataaccess = 1;
  }

  ngOnChanges() {
    if (this.open) {
      this.load(true);
    }
  }


  /*Properties*/
  public get loading() {
    return this._loading;
  }
  public get groupedby() {
    return this._groupedby;
  }
  public get bookingusers() {
    return this._bookingusers;
  }
  public get markaccess() {
    return this._markaccess;
  }
  //public get ischecked() {
  //  return this._ischecked;
  //}
  public get checknotificationemail() {
    return this._checknotificationemail;
  }
  public set checknotificationemail(val) {
    this._checknotificationemail = val;
  }
  public get checknotificationsms() {
    return this._checknotificationsms;
  }
  public set checknotificationsms(val) {
    this._checknotificationsms = val;
  }
  public get usermenu() {
    return this._usermenu;
  }
  public set usermenu(val) {
    this._usermenu = val;
  }
  public get showremind() {
    return this._showremind;
  }
  public get sortId() {
    return this._sortId;
  }
  public get activerow() {
    return this._activerow;
  }
  public set activerow(val) {
    this._activerow = val;
  }
  public get listutility() {
    return this._listutility;
  }
  //Popup
  public get timereportIdList() {
    return this._timereportIdList;
  }
  public get popup() {
    return this._popup;
  }
  public set popup(val) {
    this._popup = val;
  }



/*Methods*/
  public getTitle(user: any) {
    if (user.Access == 1) {
      return this.languageService.getItem(961);
    }
    return '';
  }
  public load(reload:boolean = false) {

    if (this._loading) { return; }

    if (!this._loaded || reload) {
      this._loading = true;

      this._bookingusers = [];

      this.dataService.tokenRequest('/api/v1/bookings/' + this.id + '/users', 'GET')
        .subscribe(res => {
          if (res) {

            this._markaccess = res.MarkAccess;

            if (this.bookingdata.Filled != res.Filled) {
              this.bookingdata.Filled = res.Filled;
            }

            res.Users.forEach((user) => {

              if (this.permissionService.permissions.BookingProfile) {
                this.manageProfile(user.Profile);
              }

              this._bookingusers.push(user);

            });

            this._listutility.rows = this._bookingusers;

            if (this._sortId == 0) {
              this._groupedby = this.listService.groupByInt(
                this._bookingusers,
                'Type',
                [
                  { Id: 1, Name: '', Sort: 0 },
                  { Id: 0, Name: this.languageService.getItem(423), Sort: 1 },
                  { Id: 2, Name: this.languageService.getItem(279), Sort: 2 },
                  { Id: 3, Name: this.languageService.getItem(280), Sort: 3 },
                  { Id: 4, Name: this.languageService.getItem(1106), Sort: 4 }
                ]);
            }
            else {
              this.resort();
            }

            this._listutility.ischecked = false;
          }

          this._loading = false;
          this._loaded = true;
        });

    }
  }
  public openuser(user, e) {
    e.stopPropagation();

    let url = '/users/' + user.Id;
    
    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }
  public opentimereport(user, e) {
    e.stopPropagation();

    let timereports = [];
    this._bookingusers.forEach((bookinguser) => {
      if (bookinguser.UserAccess > 0) {
        timereports.push(bookinguser.Timereport);
      }
    });
    this.viewCacheService.add('timereports', timereports);

    let url = '/timereports/' + user.Timereport;

    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }
  public bookoption(type) {
    let res = false;

    this._bookingusers.forEach((user) => {

      if (!res && user.Type != type) {
        res = true;
      }

    });

    return res;
  }




  //Check users
  //public toggle(user) {

  //  user.checked = !user.checked;

  //  this._checkcounter = this._checkcounter + (user.checked ? 1 : -1);

  //  this._ischecked = this._checkcounter > 0;
  //}
  //public checkall(option) {
  //  this._bookingusers.forEach((user) => {
  //    if (user.MarkAccess > 0) {
  //      user.checked = option;
  //    }
  //  });

  //  this._checkcounter = option ? this._bookingusers.length : 0;
  //  this._ischecked = option;
  //}



  //Book and Cancellation
  public savelist(e, booktype) {

    this._usermenu = false;

    let users = [];
    let timereports = [];
    this._bookingusers.forEach((user) => {
      if (user.checked) {
        if (user.Access > 1 || booktype == 2 || booktype == 3) {
          //Standby and Abscent booking is valid even when access is to low
          if (!this.availabledata.Exclude || !user.Warning) {
            users.push(user);
            timereports.push(user.Timereport);
          }
        }
        else {
          this.alertService.Add({ message: this.languageService.getItem(175), type: 'danger' });
        }
      }
    });

    if (booktype < 3 || !this.permissionService.permissions.DetailWhenAbscent) {
      this.synchronouslysave(e, booktype, users, 0);
    }
    else {
      //Abscent
      this._popup[0].Visible = true;
      this._popup[0].Event = 3;
      this._popup[0].Option = e;

      this._timereportIdList = timereports;

      //this.viewCacheService.add('timereports', timereports);
      //let url = '/timereports/' + timereports[0] + '/user/0/type/' + booktype;

      //this.router.navigate([url]);
    }
  }
  public save(e, user, booktype) {
    this._usermenu = false;

    this.synchronouslysave(e, booktype, [user], 0);
  }
  public deletelist(e) {
    this._usermenu = false;

    let users = [];
    this._bookingusers.forEach((user) => {
      if (user.checked && user.Access > 2) {
        users.push(user);
      }
    });

    this.synchronouslydelete(e, users, 0);
  }
  public delete(e, user) {
    this._usermenu = false;

    this.synchronouslydelete(e, [user], 0);
  }
  public reloadAfterBook() {
    //The event will also cause a reload on this component
    this._loaded = false;
    this.settingService.viewrefresh('bookingusers_save');
  }


  //Send
  public send(type: number, e) {

    e.stopPropagation();

    let users = [];
    this._bookingusers.forEach((user) => {
      if (user.checked && user.MarkAccess > 0) {
        users.push(user);
      }
    });

    let url = '';
    if (type == 1) {
      url = '/messages/send/email/id/' + this.bookingdata.Id + '/serie/' + this.bookingdata.SerieId + '/body/36/subject/37';
      this.viewCacheService.add('message_users', users);
    }
    else if (type == 3) {
      url = '/messages/send/sms/id/' + this.bookingdata.Id + '/serie/' + this.bookingdata.SerieId + '/body/36';
      this.viewCacheService.add('message_users', users);
    }
    else if (type == 6) {
      //Request 3 => System + Sms
      url = '/requests/send/3/id/' + this.bookingdata.Id + '/serie/' + this.bookingdata.SerieId + '/body/4';
      this.viewCacheService.add('request_users', users);
    }

    this.router.navigate([url]);
  }
  public addmessagelist(type: number) {
    this._usermenu = false;

    let users = [];
    this._bookingusers.forEach((user) => {
      if (user.checked && user.Access > 1) {
        users.push(user);
      }
    });

    this.synchronouslyaddmessage(users, 0, type);
  }
  public removemessagelist(type: number) {
    this._usermenu = false;

    let users = [];
    this._bookingusers.forEach((user) => {
      if (user.checked && user.Access > 1) {
        users.push(user);
      }
    });

    this.synchronouslyremovemessage(users, 0, type);
  }

  //Sort
  public sort(id) {

    if (this._bookingusers.length == 0) {
      return;
    }

    let sorted = [...this._bookingusers];

    let item = this.listService.find(this._bookingusers[0].Profile, 'Property', id);
    let index = this._bookingusers[0].Profile.indexOf(item);

    if (this._sortId == id) {
      this._sortId *= -1;
      sorted = this.sortcolumns(sorted, index, true);
    }
    else if ((this._sortId * -1) == id) {
      this._sortId = 0;
    }
    else {
      this._sortId = id;
      sorted = this.sortcolumns(sorted, index, false);
    }

    this._groupedby = this.listService.groupByInt(
      sorted,
      'Type',
      [
        { Id: 1, Name: '', Sort: 0 },
        { Id: 0, Name: this.languageService.getItem(423), Sort: 1 },
        { Id: 2, Name: this.languageService.getItem(279), Sort: 2 },
        { Id: 3, Name: this.languageService.getItem(280), Sort: 3 },
        { Id: 4, Name: this.languageService.getItem(1106), Sort: 4 }
      ]);
  }



  //Functions
  private resort() {
    if (this._sortId > 0) {
      let id = this._sortId;
      this._sortId = 0;
      this.sort(id);
    }
  }
  private synchronouslysave(e, booktype, users, counter) {

    let user = users[counter];
    if (!user) {
      return;
    }

    let filter = {
      Type: booktype,
      Slots: this.bookingdata.Slots,
      ManualNotify: true,
      ManualNotifyEmail: this._checknotificationemail,
      ManualNotifySms: this._checknotificationsms
    };


    if (e > 1) {
      filter["Series"] = e;
    }


    if (this.availabledata.checkremind) {
      filter["Remind"] = this.availabledata.remind;
    }


    this.dataService.tokenRequest('/api/v1/bookings/' + this.id + '/users/' + user.Id, 'POST', filter, 'text')
      .subscribe(res => {

        let type = 'success';
        if (res != this.languageService.getItem(34)) {
          type = 'warning';
        }

        this.alertService.Add({ message: res, type: type });

        counter++;
        if (counter < users.length) {
          this.synchronouslysave(e, booktype, users, counter);
        }
        else {
          //The event will also cause a reload on this component
          this._loaded = false;
          this.settingService.viewrefresh('bookingusers_save');
        }
      });

  }
  private synchronouslydelete(e, users, counter) {

    let user = users[counter];
    if (!user) {
      return;
    }

    let notify = 0;
    if (this._checknotificationsms) {
      notify |= 1;
    }
    if (this._checknotificationemail) {
      notify |= 2;
    }

    let extra = '';

    extra += '/notify/' + notify;

    if (e > 1) {
      extra += '/series/' + e;
      extra += '/type/' + user.Type;
    }

    this.dataService.tokenRequest('/api/v1/bookings/' + this.id + '/users/' + user.Id + extra, 'DELETE', {}, 'text')
      .subscribe(res => {

        this.alertService.Add({ message: res, type: 'success' });

        counter++;
        if (counter < users.length) {
          this.synchronouslydelete(e, users, counter);
        }
        else {
          //The event will also cause a reload on this component
          this._loaded = false;
          this.settingService.viewrefresh('bookingusers_delete');
        }
      });
  }
  private synchronouslyaddmessage(users, counter, type) {

    let user = users[counter];

    let dto = {};

    if (type == 5) {
      dto["AddRemind"] = true;
    }

    this.dataService.tokenRequest('/api/v1/bookings/' + this.id + '/users/' + user.Id, 'PUT', dto, 'text')
      .subscribe(res => {
        this.alertService.Add({ message: res, type: 'success' });

        counter++;
        if (counter < users.length) {
          this.synchronouslyaddmessage(users, counter, type);
        }
        else {
          this.load(true);
        }
      });

  }
  private synchronouslyremovemessage(users, counter, type) {
    let user = users[counter];

    let dto = {};

    if (type == 5) {
      dto["RemoveRemind"] = true;
    }

    this.dataService.tokenRequest('/api/v1/bookings/' + this.id + '/users/' + user.Id, 'PUT', dto, 'text')
      .subscribe(res => {
        this.alertService.Add({ message: res, type: 'success' });

        counter++;
        if (counter < users.length) {
          this.synchronouslyremovemessage(users, counter, type);
        }
        else {
          this.load(true);
        }
      });
  }

  private manageProfile(profileList: any[]) {

    profileList.forEach((profile) => {

      let property = this.propertyService.getProperty(profile.Property);
      if (property) {
        profile.Type = property.Type;
        if (profile.Type == 'System.DateTime' || profile.Type == 'System.Date' || profile.Type == 'System.Time') {
          if (property.Format && property.Format.length > 0) {
            profile.FormatValue = this.dateTimeService.format(profile.Value, property.Format);
          }
        }
        else if (profile.Type == 'System.List' || profile.Type == 'System.MultiList') {
          let res = '';

          if (profile.Value != null) {
            profile.Value.toString().split('|').forEach((v) => {

              property.Items.forEach((pi) => {

                if (pi.Id == parseInt(v)) {

                  if (res.length > 0) {
                    res += '| ';
                  }
                  res += pi.Name;
                }

              });

            });
          }

          profile.FormatValue = res;

        }
        else {
          profile.FormatValue = profile.Value;
        }
      }

    });

  }
  private sortcolumns(array, index, desc) {

    //Sort list
    array.sort(function (a, b) {

      //Value A
      var objectA = a.Profile[index];
      var valueA = objectA.Value;
      if (typeof valueA == "string") {
        valueA = valueA.toLowerCase();
      }
      //Value B
      var objectB = b.Profile[index];
      var valueB = objectB.Value;
      if (typeof valueB == "string") {
        valueB = valueB.toLowerCase();
      }

      //Compare
      if (valueA == valueB) {
        // if they are equal, return 0 (no sorting)
        return 0;
      }
      else if (valueA > valueB) {
        // if a should come after b, return 1
        return desc ? -1 /*desc*/ : 1 /*asc*/;
      }
      else {
        // if b should come after a, return -1
        return desc ? 1 /*desc*/ : -1 /*asc*/;
      }
    });

    return array;
  }
}
