<nav aria-label="DateTime navigation" class="d-inline-block">
  <ul class="pagination mb-0">
    <li class="page-item" [ngClass]="{'disabled': disabled}"><a href="javascript:void(0);" class="page-link swe-no-link swe-click" (click)="!disabled&&today()" [attr.title]="languageService.getItem(76)">{{languageService.getItem(76)}}</a></li>
    <li class="page-item" [ngClass]="{'disabled': disabled}"><a href="javascript:void(0);" class="page-link swe-no-link swe-click" (click)="!disabled&&prev()" [attr.title]="languageService.getItem(951)"><i class="bi bi-chevron-left"></i></a></li>
    <li class="page-item" [ngClass]="{'disabled': disabled}"><a href="javascript:void(0);" class="page-link swe-no-link swe-click" (click)="!disabled&&next()" [attr.title]="languageService.getItem(952)"><i class="bi bi-chevron-right"></i></a></li>
    <li class="page-item mx-1">
      <div class="d-inline-block align-middle py-2 mx-1 swe-click" (click)="!disabled&&openDate()" *ngIf="!showdate"><a href="javascript:void(0);" class="swe-no-link" [attr.title]="languageService.getItem(791)">{{currentdate}}</a></div>
      <swe-datetime [(start)]="start" [(end)]="end" [hideTime]="hideTime" *ngIf="showdate"></swe-datetime>
      <button [attr.aria-label]="languageService.getItem(13)" class="btn btn-swe" (click)="closeDate()" *ngIf="showdate"><i class="bi bi-swe-fw bi-search"></i></button>
    </li>
    <li class="page-item dropdown">
      <button class="btn btn-swe" [ngClass]="{'disabled': disabled}" (click)="!disabled&&toggle()">
        <span>{{interval}}&nbsp;<i class="bi bi-caret-down-fill"></i></span>
      </button>
      <ul class="dropdown-menu py-0" [ngClass]="{'show': showinterval}">
        <li class="dropdown-item swe-click" (click)="!disabled&&day()"><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':datetimespan==1}"></i>{{languageService.getItem(526)}}</li>
        <li class="dropdown-item swe-click" (click)="!disabled&&week()"><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':datetimespan==2}"></i>{{languageService.getItem(527)}}</li>
        <li class="dropdown-item swe-click" (click)="!disabled&&month()"><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':datetimespan==3}"></i>{{languageService.getItem(528)}}</li>
        <li class="dropdown-item swe-click" (click)="!disabled&&quarter()"><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':datetimespan==4}"></i>{{languageService.getItem(693)}}</li>
        <li class="dropdown-item swe-click" (click)="!disabled&&year()"><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':datetimespan==5}"></i>{{languageService.getItem(694)}}</li>
      </ul>
    </li>
  </ul>
</nav>
