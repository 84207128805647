import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LanguageService } from '../_services/language.service';
import { SettingService } from '../_services/setting.service';
import { GeneralService } from '../_services/general.service';
import { ListService } from '../_services/list.service';


@Component({
  selector: 'app-usermarkeddatedetail',
  templateUrl: './usermarkeddatedetail.component.html'
})
export class UserMarkedDateDetailComponent implements OnInit {

  private _id: number;
  private _userId: number;
  private _objecttoobjects: any[] = [];
  private _usermarkeddatedata: any = {};
  

  constructor(
    public languageService: LanguageService,
    private settingService: SettingService,
    private generalService: GeneralService,
    private listService: ListService,
    private route: ActivatedRoute,
    private router: Router  ) {
    
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this._id = Number(params.get('id'));
      this._userId = Number(params.get('userid'));

      this.load();
    });
  }

  /*Properties*/
  public get cards() {
    return {
      data: true
    };
  }
  public get id() {
    return this._id;
  }
  public get userId() {
    return this._userId;
  }
  public get objecttoobjects() {
    return this._objecttoobjects;
  }



  /*Methods*/
  public manageUserMarkedDatedata(data) {
    this._usermarkeddatedata = data;
  }
  public gotoUser() {
    this.router.navigate(['/users/' + this._usermarkeddatedata.UserId]);
  }


  //Functions
  private load() {

    this._objecttoobjects = this.listService.formatArray(this.generalService.propertycategories, ['UserMarkedDate'], 'Type');

  }
}
