import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';

import { AuthenticationService } from '../_services/authentication.service';
import { PermissionService } from '../_services/permission.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private permissionService: PermissionService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const isauth = this.authenticationService.isAuth;
    if (isauth) {
      if (this.permissionService.user && this.permissionService.user.UpdatePassword) {
        let validUrl = '/users/' + this.permissionService.user.Id;
        if (state.url != validUrl) {
          this.router.navigate([validUrl]);
          return false;
        }
        
      }

      //Everything's ok
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }

}
