<swe-pageheader [header]="header">
  <span swe-hits *ngIf="header.length>0">
    &nbsp;(#{{id}})
    <a href="javascript:void(0)" class="swe-no-link" (click)="gotoBooking()" [attr.title]="languageService.getItem(1127)" *ngIf="id>0"><i class="bi bi-swe-fw bi-calendar-date"></i></a>
    <a href="javascript:void(0)" class="swe-no-link" (click)="gotoUser()" [attr.title]="languageService.getItem(1126)" *ngIf="id>0"><i class="bi bi-swe-fw bi-person-fill"></i></a>
  </span>
</swe-pageheader>
<div class="text-md-center">
  <div class="btn-group">
    <button class="btn btn-swe" (click)="prev()" *ngIf="currentindex>0"><i class="bi bi-swe-fw bi-arrow-left"></i></button>
    <span class="my-2 mx-3" *ngIf="othertimereports.length>1">{{currentindex+1}}/{{othertimereports.length}}</span>
    <button class="btn btn-swe" (click)="next()" *ngIf="currentindex<othertimereports.length-1"><i class="bi bi-swe-fw bi-arrow-right"></i></button>
  </div>
</div>
<div class="alert alert-danger" *ngIf="false&&othertimereports && othertimereports[currentindex].type==3">
  {{languageService.getItem(1047)}}
</div>
<div class="row py-2">
  <div class="col-12 col-md-4">
    <swe-timereportdata [id]="id" [userid]="userid" [(type)]="othertimereports[currentindex].type" [othertimereports]="othertimereports" [open]="cards.data" (headerChange)="changeHeader($event)" (idChange)="changeId($event)" (timereportdataChange)="manageTimereportdata($event)"></swe-timereportdata>
  </div>
</div>
