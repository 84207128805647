<swe-pageheader [header]="languageService.getItem(2)"><span swe-hits>&nbsp;({{hits}}<span *ngIf="more">+</span>)</span></swe-pageheader>
<div class="swe-fill-to-edge" sweFixedHeader>
  <div class="btn-group ms-1 align-top" *ngIf="listutility.toggleaccess">
    <div class="dropdown">
      <button [attr.aria-label]="languageService.getItem(842)" class="btn btn-swe" (click)="listchecked=!listchecked;listutility.checkall(listchecked)" *ngIf="listutility.toggleaccess&&users.length>0">
        <span><i class="bi bi-check-lg"></i></span>
      </button>
      <button [attr.aria-label]="languageService.getItem(843)" class="btn btn-swe" (click)="actionmenu=!actionmenu" *ngIf="listutility.ischecked">
        <span><i class="bi bi-three-dots-vertical"></i></span>
      </button>
      <ul class="dropdown-menu" [ngClass]="{'show': actionmenu && listutility.ischecked}">
        <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.MultiUser>0"><a (click)="action('multi')"><i class="bi bi-files"></i>&nbsp;{{languageService.getItem(410)}}</a></li>
        <li class="dropdown-divider" *ngIf="permissionService.permissions.MultiUser>0&&(permissionService.permissions.Email>0||permissionService.permissions.Sms>0)"></li>
        <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.Email>0"><a (click)="send(1)"><i class="bi bi-envelope-fill"></i>&nbsp;{{languageService.getItem(321)}}</a></li>
        <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.Sms>0"><a (click)="send(3)"><i class="bi bi-phone-fill"></i>&nbsp;{{languageService.getItem(320)}}</a></li>
      </ul>
    </div>
  </div>
  <div class="btn-group ms-1">
    <div class="dropdown">
      <button class="btn btn-swe" type="button" (click)="belongingmenu=!belongingmenu" [disabled]="permissionService.permissions.SearchLocked&&permissionService.permissions.SearchLocked.indexOf(24)>-1">
        <span>{{belongingtext}}&nbsp;<i class="bi bi-caret-down-fill"></i></span>
      </button>
      <ul class="dropdown-menu" [ngClass]="{'show': belongingmenu}">
        <li class="dropdown-item swe-click"><label class="mb-0"><input type="radio" [attr.aria-label]="languageService.getItem(1037)" name="belongingoptions" [value]="0" [(ngModel)]="settingService.user.nobelonging" (ngModelChange)="changebelonging()" />&nbsp;{{languageService.getItem(1037)}}</label></li>
        <li class="dropdown-item swe-click"><label class="mb-0"><input type="radio" [attr.aria-label]="languageService.getItem(104)" name="belongingoptions" [value]="1" [(ngModel)]="settingService.user.nobelonging" (ngModelChange)="changebelonging()" />&nbsp;{{languageService.getItem(104)}}</label></li>
        <li class="dropdown-item swe-click"><label class="mb-0"><input type="radio" [attr.aria-label]="languageService.getItem(1038)" name="belongingoptions" [value]="2" [(ngModel)]="settingService.user.nobelonging" (ngModelChange)="changebelonging()" />&nbsp;{{languageService.getItem(1038)}}</label></li>
      </ul>
    </div>
  </div>
  <div class="btn-group ms-1 align-top" *ngIf="more">
    <button class="btn btn-primary" (click)="search(true)">{{languageService.getItem(57)}}</button>
  </div>
  <swe-filteritems></swe-filteritems>
</div>
<div class="row py-2">
  <div class="col-12">
    <swe-card [hasCollapse]="false" (refreshChange)="search(false)">
      <span swe-rightcommand *ngIf="permissionService.permissions.Users>1">
        <i class="bi bi-plus-lg bi-swe-pull-right swe-click" (click)="open(0, $event)"></i>
      </span>
      <swe-list [data]="users" [loading]="isloading">
        <a href="javascript:void(0);" class="list-group-item list-group-item-action d-flex flex-wrap swe-click" [ngClass]="{'list-group-item-warning': user.Id==settingService.lastuser&&!user.checked, 'list-group-item-danger': user.HasNoBelonging&&!user.checked,'list-group-item-info': user.checked}" *ngFor="let user of users" (click)="open(user.Id, $event)">
          <div class="col-3 col-md-1" (click)="listutility.toggle(user, $event)">
            <span class="bi-swe-stack bi-swe-letter-circle-small" *ngIf="user.checked">
              <i class="bi bi-check-circle-fill bi-swe-stack-2x"></i>
            </span>
            <img class="swe-circle-image" alt="{{user.Firstname}} {{user.Lastname}}" sweImage imageFolder="userimages" [imageFile]="user.Image" imageWidth="32" imageHeight="32" *ngIf="!user.checked&&user.Image.length>0">
            <span class="bi-swe-stack bi-swe-letter-circle-small" *ngIf="!user.checked&&user.Image.length==0">
              <i class="bi bi-circle-fill bi-swe-stack-2x"></i>
              <span class="bi-swe-stack-1x bi-swe-letter">{{user.Firstname[0]}}</span>
            </span>
            <i class="bi bi-swe-border bi-eye-fill ms-1" *ngIf="!user.checked&&user.IsWatching" [attr.title]="languageService.getItem(944)"></i>
          </div>
          <div class="col-9 col-md-11 d-flex flex-wrap">
            <div class="col-12 col-md-3">{{user.Username}}</div>
            <div class="col-12 col-md-3">{{user.Firstname}} {{user.Lastname}}</div>
            <div class="col-12 col-md-3">{{user.Cell}}</div>
            <div class="col-12 col-md-3">{{user.Email}}</div>
          </div>
        </a>
        <button class="btn btn-primary" *ngIf="hits>0 && more" (click)="search(true)">{{languageService.getItem(57)}}</button>
      </swe-list>
    </swe-card>
  </div>
</div>
