import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
import { take, switchMap, map, tap } from 'rxjs/operators';

import { DataService } from '../_services/data.service';
import { SettingService } from './setting.service';


declare const window: any;

@Injectable({ providedIn: 'root' })
export class GeneralService implements Resolve<Observable<any>> {

  private _activitytypes: any[] = null;
  private _bookingstatus: any[] = null;
  private _contracts: any[] = null;
  private _contractCategories: any[] = null;
  private _employmenttypes: any[] = null;
  private _employmentcategories: any[] = null;
  private _first: boolean = true;
  private _levelgroups: any[] = null;
  private _las: any[] = null;
  private _logobjects: any[] = null;
  private _markeddatetypes: any[] = null;
  private _messagetypes: any[] = null;
  private _minustimetypes: any[] = null;
  private _plustimetypes: any[] = null;
  private _propertycategories: any[] = null;
  private _propertytabgroups: any[] = null;
  private _reports: any[] = null;
  private _responseoptions: any[] = null;
  private _roles: any[] = null;
  private _timereportstatus: any[] = null;
  private _templates: any[] = null;
  private _timetypes: any[] = null;
  private _timebanks: any[] = null;

  constructor(private dataService: DataService, private settingService: SettingService) {

    
  }

  resolve() {

    return forkJoin(
      this.loadRoles(),
      this.loadLevelGroups(),
      this.loadBookingStatus(),
      this.loadMessageTypes(),
      this.loadLogObjects(),
      this.loadMarkedDateTypes(),
      this.loadActivityTypes(),
      this.loadReports(),
      this.loadTemplates(),
      this.loadPropertyCategories(),
      this.loadTimeBanks(),
      this.loadLas(),
      this.loadContracts(),
      this.loadContractCategories(),
      this.loadEmploymentTypes(),
      this.loadEmploymentCategories(),
      this.loadResponseOptions(),
      this.loadTimeTypes(),
      this.loadPropertyTabGroups()
    ).pipe(
      take(1),
      switchMap(res => {

        if (this._first) {
          //Check watchdog
          this._first = false;
          this.settingService.checkWatchdog();
          this.getVersion();
        }
        return res;
      })
    );
  }

  /*Properties*/
  public get roles(): any[] {
    return this._roles;
  }
  public get levelgroups(): any[] {
    return this._levelgroups.filter((level) => { if (level.Id > 0) { return level; } });
  }
  public get levelgroupswithmissing(): any[] {
    return this._levelgroups;
  }
  public get bookingstatus(): any[] {
    return this._bookingstatus;
  }
  public get timereportstatus(): any[] {
    return this._timereportstatus;
  }
  public get messagetypes(): any[] {
    return this._messagetypes;
  }
  public get logobjects(): any[] {
    return this._logobjects;
  }
  public get markeddatetypes(): any[] {
    return this._markeddatetypes;
  }
  public get activitytypes(): any[] {
    return this._activitytypes;
  }
  public get reports(): any[] {
    return this._reports;
  }
  public get templates(): any[] {
    return this._templates;
  }
  public get propertycategories(): any[] {
    return this._propertycategories;
  }
  public get timebanks(): any[] {
    return this._timebanks;
  }
  public get las(): any[] {
    return this._las;
  }
  public get contracts(): any[] {
    return this._contracts;
  }

  public get contractCategories(): any[] {
    return this._contractCategories
  }
  public get employmenttypes(): any[] {
    return this._employmenttypes;
  }
  public get employmentcategories(): any[] {
    return this._employmentcategories;
  }
  public get responseoptions(): any[] {
    return this._responseoptions;
  }
  public get plustimetypes(): any[] {
    return this._plustimetypes;
  }
  public get timetypes(): any[] {
    return this._timetypes;
  }
  public get minustimetypes(): any[] {
    return this._minustimetypes;
  }
  public get propertytabgroups(): any[] {
    return this._propertytabgroups;
  }
  private get api() {
    return window.location.origin.replace('https://', '').replace('http://', '') + '/api/v1/';
  }
  public get isIOS() {
    return (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) /*Includes iOS keyword and ignore IE "fake" use of it*/
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document); /*iPad on iOS 13 detection*/
  }
  public get isFireFox() {
    return (/Firefox/.test(navigator.userAgent) && !(/Seamonkey/.test(navigator.userAgent))); /*Includes FireFox keyword and ignore Seamonkey "fake" use of it*/
  }

  //Reload
  public reloadRoles(): Observable<any> {
    this._roles = null;
    return this.dataService.tokenRequest('/api/v1/general/roles', 'GET').pipe(
      tap((res) => {
        this._roles = res;
      })
    );
  }
  public reloadLevelGroups(): Observable<any> {
    this._levelgroups = [];
    return this.dataService.tokenRequest('/api/v1/general/levelgroups', 'GET').pipe(
      tap((res) => {
        res.forEach(levelgroup => {

          if (typeof levelgroup.Type == 'undefined' || levelgroup.Type == null || levelgroup.Type.length == 0) {
            this._levelgroups.push({ Id: levelgroup.Key, Name: levelgroup.Value });

            if (levelgroup.Extra != '1') {
              this.settingService.defaultlevel.levelgroups.push(levelgroup.Key);
              this.settingService.level.levelgroups.push(levelgroup.Key);
            }
          }
          else if (levelgroup.Extra != '1') {
            if (levelgroup.Type == 'FilterBooking') {
              this.settingService.defaultbooking.levelgroups.push(levelgroup.Key);
              this.settingService.booking.levelgroups.push(levelgroup.Key);
            }
            else if (levelgroup.Type == 'FilterUser') {
              this.settingService.defaultuser.levelgroups.push(levelgroup.Key);
              this.settingService.user.levelgroups.push(levelgroup.Key);
            }
          }

        });
      })
    );
  }
  public reloadMarkedDateTypes(): Observable<any> {
    this._markeddatetypes = null;
    return this.dataService.tokenRequest('/api/v1/general/markeddatetypes', 'GET').pipe(
      tap((res) => {
        this._markeddatetypes = res;
      })
    );
  }
  public reloadReports(): Observable<any> {
    this._reports = null;
    return this.dataService.tokenRequest('/api/v1/general/reports', 'GET').pipe(
      tap((res) => {
        this._reports = res;
      })
    );
  }

  public reloadContractCategories(): Observable<any> {
    this._contractCategories = null;
    return this.dataService.tokenRequest('/api/v1/general/contracts/categories', 'GET')
      .pipe(
        tap((res) => {
          this._contractCategories = res;
        })
      );
  }


  //Format
  public formatdecimal(val, length = 2) {
    
    if (length < 1) { return val; }

    if (typeof val != "undefined" && val != null && Number(val) === val) {
      let converter = Math.pow(10, length);
      val = Math.round(val * converter) / converter;
    }

    return val;
  }


  /*Functions*/
  private getVersion() {
    this.dataService.basicRequest('/api/v1/security/version', 'GET', {}, 'text')
      .subscribe((res) => {

        localStorage.setItem('version', res);
      });
  }
  private loadRoles(): Observable<any> {

    if (!this._roles) {

      return this.dataService.tokenRequest('/api/v1/general/roles', 'GET').pipe(
        take(1),
        switchMap(res => {
          if (res) {

            this._roles = res;

            res.forEach(role => {
              if (role.Access > 1) {

                this.settingService.defaultuser.roles.push(role.Id);
                this.settingService.user.roles.push(role.Id);
              }
            });
          }

          return of(this._roles);
        })
      );
    }
    else {
      return of(this._roles);
    }
  }
  public loadLevelGroups() : Observable<any> {

    if (!this._levelgroups) {
      return this.dataService.tokenRequest('/api/v1/general/levelgroups', 'GET').pipe(
        take(1),
        switchMap(res => {
          if (res) {
            this._levelgroups = [];
            res.forEach(levelgroup => {

              if (typeof levelgroup.Type == 'undefined' || levelgroup.Type == null || levelgroup.Type.length == 0) {
                this._levelgroups.push({ Id: levelgroup.Key, Name: levelgroup.Value });

                if (levelgroup.Extra != '1') {
                  this.settingService.defaultlevel.levelgroups.push(levelgroup.Key);
                  this.settingService.level.levelgroups.push(levelgroup.Key);
                }
              }
              else if (levelgroup.Extra != '1') {
                if (levelgroup.Type == 'FilterBooking') {
                  this.settingService.defaultbooking.levelgroups.push(levelgroup.Key);
                  this.settingService.booking.levelgroups.push(levelgroup.Key);
                }
                else if (levelgroup.Type == 'FilterUser') {
                  this.settingService.defaultuser.levelgroups.push(levelgroup.Key);
                  this.settingService.user.levelgroups.push(levelgroup.Key);
                }
              }

            });
          }

          return of(this._levelgroups);
        })
      );
    }
    else {
      return of(this._levelgroups);
    }
   
  }
  private loadBookingStatus(): Observable<any> {

    if (!this._bookingstatus) {
      return this.dataService.tokenRequest('/api/v1/general/status', 'GET').pipe(
        take(1),
        switchMap(res => {
          if (res) {
            this._bookingstatus = res;

            this._timereportstatus = [];
            res.forEach(status => {

              if (status.Id >= 40) {
                this._timereportstatus.push(status);
              }
            });
          }

          return of(this._bookingstatus);
        })
      );
    }
    else {
      return of(this._bookingstatus);
    }
  }
  private loadMessageTypes(): Observable<any> {

    if (!this._messagetypes) {
      return this.dataService.tokenRequest('/api/v1/general/messagetypes', 'GET').pipe(
        take(1),
        switchMap(res => {
          if (res) {
            this._messagetypes = res;
          }

          return of(this._messagetypes);
        })
      );
    }
    else {
      return of(this._messagetypes);
    }
  }
  private loadLogObjects(): Observable<any> {

    if (!this._logobjects) {
      return this.dataService.tokenRequest('/api/v1/general/logobjects', 'GET').pipe(
        take(1),
        switchMap(res => {
          if (res) {
            this._logobjects = res;
          }

          return of(this._logobjects);
        })
      );
    }
    else {
      return of(this._logobjects);
    }
  }
  private loadMarkedDateTypes(): Observable<any> {

    if (!this._markeddatetypes) {
      return this.dataService.tokenRequest('/api/v1/general/markeddatetypes', 'GET').pipe(
        take(1),
        switchMap(res => {
          if (res) {
            this._markeddatetypes = res;
          }

          return of(this._markeddatetypes);
        })
      );
    }
    else {
      return of(this._markeddatetypes);
    }
  }
  private loadActivityTypes(): Observable<any> {

    if (!this._activitytypes) {
      return this.dataService.tokenRequest('/api/v1/general/activitytypes', 'GET').pipe(
        take(1),
        switchMap(res => {
          if (res) {
            this._activitytypes = res;
          }

          return of(this._activitytypes);
        })
      );
    }
    else {
      return of(this._activitytypes);
    }
  }
  private loadReports(): Observable<any> {

    if (!this._reports) {
      return this.dataService.tokenRequest('/api/v1/general/reports', 'GET').pipe(
        take(1),
        switchMap(res => {
          if (res) {
            this._reports = res;
          }

          return of(this._reports);
        })
      );
    }
    else {
      return of(this._reports);
    }
  }
  private loadTemplates(): Observable<any> {

    if (!this._templates) {
      return this.dataService.tokenRequest('/api/v1/general/templates', 'GET').pipe(
        take(1),
        switchMap(res => {
          if (res) {
            this._templates = res;
          }

          return of(this._templates);
        })
      );
    }
    else {
      return of(this._templates);
    }
  }
  private loadPropertyCategories(): Observable<any> {

    if (!this._propertycategories) {
      return this.dataService.tokenRequest('/api/v1/general/propertycategories', 'GET').pipe(
        take(1),
        switchMap(res => {
          if (res) {
            this._propertycategories = res;
          }

          return of(this._propertycategories);
        })
      );
    }
    else {
      return of(this._propertycategories);
    }
  }
  private loadTimeBanks(): Observable<any> {

    if (!this._timebanks) {
      return this.dataService.tokenRequest('/api/v1/general/timebanks', 'GET').pipe(
        take(1),
        switchMap(res => {
          if (res) {
            this._timebanks = res;
          }

          return of(this._timebanks);
        })
      );
    }
    else {
      return of(this._timebanks);
    }
  }
  private loadLas(): Observable<any> {

    if (!this._las) {
      return this.dataService.tokenRequest('/api/v1/general/las', 'GET').pipe(
        take(1),
        switchMap(res => {
          if (res) {
            this._las = res;
          }

          return of(this._las);
        })
      );
    }
    else {
      return of(this._las);
    }
  }
  private loadContracts(): Observable<any> {

    if (!this._contracts) {
      return this.dataService.tokenRequest('/api/v1/general/contracts', 'GET').pipe(
        take(1),
        switchMap(res => {
          if (res) {
            this._contracts = res;
          }

          return of(this._contracts);
        })
      );
    }
    else {
      return of(this._contracts);
    }
  }

  private loadContractCategories(): Observable<any> {
    if (this._contractCategories) {
      return of(this._contractCategories);
    }
    return this.dataService.tokenRequest('/api/v1/general/contracts/categories', 'GET')
      .pipe(take(1), switchMap(res => {
        if (res) {
          this._contractCategories = res;
        }
        return of(this._contractCategories);
      })
      );
  }



  private loadEmploymentTypes(): Observable<any> {

    if (!this._employmenttypes) {
      return this.dataService.tokenRequest('/api/v1/general/employmenttypes', 'GET').pipe(
        take(1),
        switchMap(res => {
          if (res) {
            this._employmenttypes = res;
          }

          return of(this._employmenttypes);
        })
      );
    }
    else {
      return of(this._employmenttypes);
    }
  }
  private loadEmploymentCategories(): Observable<any> {

    if (!this._employmentcategories) {
      return this.dataService.tokenRequest('/api/v1/general/employmentcategories', 'GET').pipe(
        take(1),
        switchMap(res => {
          if (res) {
            this._employmentcategories = res;
          }

          return of(this._employmentcategories);
        })
      );
    }
    else {
      return of(this._employmentcategories);
    }
  }
  private loadResponseOptions(): Observable<any> {

    if (!this._responseoptions) {
      return this.dataService.tokenRequest('/api/v1/general/responseoptions', 'GET').pipe(
        take(1),
        switchMap(res => {
          if (res) {
            this._responseoptions = res;
          }

          return of(this._responseoptions);
        })
      );
    }
    else {
      return of(this._responseoptions);
    }
  }
  private loadTimeTypes(): Observable<any> {

    if (!this._plustimetypes) {
      return this.dataService.tokenRequest('/api/v1/general/timetypes', 'GET').pipe(
        take(1),
        switchMap(res => {
          if (res) {
            this._plustimetypes = [];
            this._timetypes = [];
            this._minustimetypes = [];

            res.forEach((type) => {
              if (type.Extra == '1') {
                //Plus
                this._plustimetypes.push(type);
              }
              else if (type.Extra == '2') {
                //Minus
                this._minustimetypes.push(type);
              }

              this._timetypes.push(type);
            });
          }

          return of(this._plustimetypes);
        })
      );
    }
    else {
      return of(this._plustimetypes);
    }
  }
  private loadPropertyTabGroups(): Observable<any> {

    if (!this._propertytabgroups) {
      return this.dataService.tokenRequest('/api/v1/general/propertytabgroups', 'GET').pipe(
        take(1),
        switchMap(res => {
          if (res) {
            this._propertytabgroups = res;
          }

          return of(this._propertytabgroups);
        })
      );
    }
    else {
      return of(this._propertytabgroups);
    }
  }
 



}
