import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { GeneralService } from '../../_services/general.service';
import { AlertService } from '../../_services/alert.service';
import { PropertyService } from '../../_services/property.service';
import { SettingService } from '../../_services/setting.service';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-adminpropertydetail',
  templateUrl: './adminpropertydetail.component.html'
})
export class AdminPropertyDetailComponent implements OnInit, OnDestroy {

  private _subscriptions: Subscription[] = [];
  private _id: number = 0;
  private _category: number = 0;
  private _property: any = {};
  private _categories: any[] = [];
  private _datatypes: any[] = [];
  private _groups: any[] = [];
  private _tabgroups: any[] = [];
  private _corevalues: any[] = [];
  private _maxproperties: number = 26;
  private _publicaccess: any[] = [];
  private _formula: string = '';
  private _formulavalues: any[] = [];
  private _formularesult: string = '';
  private _formulaerror: string = '';
  private _datetime: any;
  private _datetimeresult: number = 0;
  private _timeintervals: any[] = [];
  private _timeinterval: number = 0;
  private _timeunits: any[] = [];
  private _timeunit: number = 1;
  private _timeunitresult: number = 0;

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public generalService: GeneralService,
    private dataService: DataService,
    private alertService: AlertService,
    private propertyService: PropertyService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private settingService: SettingService
  ) {
    //Reset
    settingService.initView();

    this._subscriptions.push(settingService.onSave$
      .subscribe((e) => {
        //Save
        this.save();
      }));

    this.route.paramMap.subscribe(params => {
      this._id = Number(params.get('id'));
      this._category = Number(params.get('category'));

      this.settingService.lastproperty = this._id;

      this.init();
      this.load();
    });

  }


  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnInit() {

    this.init();
    this.load();

  }



  //Properties
  public get id() {
    return this._id;
  }
  public get property() {
    return this._property;
  }
  public get categories() {
    return this._categories;
  }
  public get category() {
    return this._category;
  }
  public get datatypes() {
    return this._datatypes;
  }
  public get groups() {
    return this._groups;
  }
  public get tabgroups() {
    return this._tabgroups;
  }
  public get corevalues() {
    return this._corevalues;
  }
  public get publicaccess() {
    return this._publicaccess;
  }
  public get formula() {
    return this._formula;
  }
  public set formula(val) {
    this._formula = val;
  }
  public get formulavalues() {
    return this._formulavalues;
  }
  public get formularesult() {
    return this._formularesult;
  }
  public get formulaerror() {
    return this._formulaerror;
  }
  public get datetime() {
    return this._datetime;
  }
  public set datetime(val) {
    this._datetime = val;
  }
  public get datetimeresult() {
    return this._datetimeresult;
  }
  public get timeintervals() {
    return this._timeintervals;
  }
  public get timeinterval() {
    return this._timeinterval;
  }
  public set timeinterval(val) {
    this._timeinterval = val;
  }
  public get timeunits() {
    return this._timeunits;
  }
  public get timeunit() {
    return this._timeunit;
  }
  public set timeunit(val) {
    this._timeunit = val;
  }
  public get timeunitresult() {
    return this._timeunitresult;
  }



  //Methods
  public managepublicaccess(id, e) {

    e.stopPropagation();

    this._publicaccess[id] = !this._publicaccess[id];
  }
  public save() {

    let verb = 'Post';
    let path = '/api/v1/properties/'
    if (this._id > 0) {
      verb = 'Put';
      path += this._id;
    }

    let publicaccessvalue = 0;
    for (var i = 0; i < this._maxproperties; i++) {
      if (this._publicaccess[i]) {
        let access = Math.pow(2, i);
        publicaccessvalue += access;
      }
    }
    this._property.Public = publicaccessvalue;

    this._property.Group = this._property.Group > 0 ? this._property.Group : 0;

    this._property.TabGroup = this._property.TabGroup > 0 ? this._property.TabGroup : 0;

    if (!this._property.ItemsRef) {
      this._property.ItemsRef = 0;
    }   

    this.dataService.tokenRequest(path, verb, this._property, 'text', 'response')
      .subscribe((response) => {
        if (response) {
          this.propertyService.reload().subscribe();
          this.alertService.Add({ type: 'success', message: response.body });

          if (response.status == 201) {
            this.router.navigate([response.headers.get('Location').replace('system/properties', 'admin/properties')], { replaceUrl: true });
          }
          else {
            this.load();
          }
        }

      });

  }
  public delete() {

    this.dataService.tokenRequest('/api/v1/properties/' + this._id, 'Delete', {}, 'text')
      .subscribe((res) => {

        this.alertService.Add({ type: 'success', message: res });

        this.location.back();
      });
  }
  public add() {
    this._property.Items.push({ Id: 0, Name: '', Value: 0, Sort: 0, Image: '', Dependency: '' });
  }
  public remove(item) {

    if (item.Id > 0) {
      item.IsDeleted = true;
    }
    else {
      let index = this._property.Items.indexOf(item);
      if (index > -1) {
        this._property.Items.splice(index, 1);
      }
    }

  }
  public restore(item) {
    item.IsDeleted = false;
  }
  public showformula() {
    if (!this.permissionService.user) {
      return false;
    }

    if (this.permissionService.user.IsSuper) {
      return true;
    }

    let res = false;
    if (this._publicaccess[19]) {
      res = true;
    }

    return res;
  }
  public showformulavalues(e) {

    this._formularesult = '';
    this._formulaerror = '';

    this._formulavalues = [];

    let saving = false;
    let key = '';
    for (let i = 0; i < this._formula.length; i++) {

      let char = this._formula[i];

      if (char == '[') {
        key = '[';
        saving = true;
      }
      else if (char == ']') {
        key += ']';

        this._formulavalues.push({ Key: key, Value: '' });

        saving = false;
        key = '';
      }
      else if (saving) {
        key += char;
      }
    }
  }
  public calculate() {

    if (!this._formula || this._formula.length == 0) { return; }

    let temp = this._formula;
    this._formulavalues.forEach((item) => {
      if (!item.Value) { item.Value = '¤'; }
      temp = temp.replace(item.Key, item.Value);
    });

    if (!this.validate(temp)) { return; }

    let dto = {
      Validate: temp
    };

    this.dataService.tokenRequest('/api/v1/properties/validate', 'POST', dto)
      .subscribe((res) => {

        this._formularesult = res.Result;
      });
  }
  public manageDateTime(e) {
    if (new Date(this._datetime) > new Date(1970, 1, 1)) {

      let date = new Date(this._datetime);

      let offset = date.getTimezoneOffset();

      let jsTicksFrom1970 = date.getTime() - (offset * 60000);

      let netTicksTo1970 = 621355968000000000;

      this._datetimeresult = (netTicksTo1970 + (jsTicksFrom1970 * 10000));
    }
  }
  public manageTimeInterval(e) {
    this._timeunitresult = this._timeinterval * this._timeunit * 60 * 1000 * 10000;
  }
  public goto(id: number, category: number = 0) {

    if (category == 0 && this._property) {
      category = this._property.Category;
    }

    if (category == 0) {
      this.router.navigate(['/admin/properties', id]);
    }
    else {
      this.router.navigate(['/admin/properties/' + id + '/category/' + category]);
    }
  }






  //Functions
  private load() {
    this.dataService.tokenRequest('/api/v1/properties/' + this._id, 'GET', {})
      .subscribe((res) => {

        this._property = res;
        if (this._id == 0 && this._category > 0) {
          this._property.Category = this._category;
        }

        this._publicaccess = [];
        for (var i = 0; i < this._maxproperties; i++) {
          let access = Math.pow(2, i);
          this._publicaccess.push((res.Public & access) == access);
        }
      });
  }
  private init() {

    //Categories
    this._categories = [];
    this.generalService.propertycategories.forEach((category) => {

      this._categories.push({ Id: category.Key, Name: category.Value });

    });

    //DataTypes
    this.dataService.tokenRequest('/api/v1/general/datatypes', 'GET', {})
      .subscribe((res) => {
      this._datatypes = [];
      res.forEach((datatype) => {

        this._datatypes.push({ Id: datatype.Key, Name: datatype.Value });

      });

    });

    //PropertyGroups
    this.dataService.tokenRequest('/api/v1/general/propertygroups', 'GET', {})
      .subscribe((res) => {
        this._groups = [];
        res.forEach((group) => {

          this._groups.push({ Id: group.Key, Name: group.Value });

        });

      });

    //PropertyTabGroups
    this.dataService.tokenRequest('/api/v1/general/propertytabgroups', 'GET', {})
      .subscribe((res) => {
        this._tabgroups = [];
        res.forEach((group) => {

          this._tabgroups.push({ Id: group.Key, Name: group.Value });

        });

      });

    //CoreValues
    this.dataService.tokenRequest('/api/v1/general/corevalues', 'GET', {})
      .subscribe((res) => {
        this._corevalues = [];
        res.forEach((corevalue) => {

          this._corevalues.push({ Id: corevalue.Key, Name: corevalue.Value });

        });

      });

    //TimeIntervals
    this._timeintervals = [];
    for (let i = 1; i < 101; i++) {
      this._timeintervals.push({Id: i, Name: i});
    }

    //TimeUnits
    this._timeunits = [
      { Id: 1, Name: this.languageService.getItem(875) },
      { Id: 60, Name: this.languageService.getItem(874) },
      { Id: 1440, Name: this.languageService.getItem(526) },
      { Id: 10080, Name: this.languageService.getItem(527) }
    ];
  }
  private validate(formula) {

    let res = true;
    let left = 0;
    let right = 0;

    for (let i = 0; i < formula.length; i++) {

      let char = formula[i];

      if (!isNaN(parseInt(char))) {
        //Numeric
      }
      else if (/^[a-zA-ZåäöÅÄÖ]$/.test(char)) {
        //Letter
      }
      else if (/^[., ]$/.test(char)) {
        //Special
      }
      else if (/^[+\-*/^?:><=!~#£$%{}|&´¤\\]$/.test(char)) {
        //Keys formula
      }
      else if (char == '(') {
        //LeftParenthesis
        left++;
      }
      else if (char == ')') {
        //RightParenthesis
        right++;
      }
      else {
        this._formulaerror = this.languageService.getItem(872) + ': ' + char;
        res = false;
        break;
      }
    }

    if (res && left != right) {
      this._formulaerror = this.languageService.getItem(873);
      res = false;
    }

    return res;
  }
}
