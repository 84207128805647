<swe-pageheader [header]="languageService.getItem(712)">
  <span swe-hits>
    &nbsp;(#{{id}})
  </span>
</swe-pageheader>
<div class="row py-2" *ngIf="permissionService.permissions.Holiday>0">
  <div class="col-12 col-md-4">
    <swe-card [header]="languageService.getItem(713)" [hasRefresh]="false" [hasCollapse]="false">
      <swe-element [type]="'System.Date'" [label]="languageService.getItem(714)" [statusLabel]="1" [(model)]="holiday.Date"></swe-element>
      <swe-element [type]="'System.String'" [label]="languageService.getItem(715)" [statusLabel]="1" [(model)]="holiday.Name"></swe-element>
      <swe-element [type]="'System.String'" [label]="languageService.getItem(716)" [statusLabel]="1" [(model)]="holiday.Desc"></swe-element>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="save()" *ngIf="permissionService.permissions.Holiday>1"><i class="bi bi-save"></i>&nbsp;{{languageService.getItem(16)}}</button>
        <swe-confirm *ngIf="holiday.Id>0 && permissionService.permissions.Holiday>2" [body]="languageService.getItem(717)" (acceptChange)="delete()">
          <button class="btn btn-danger"><i class="bi bi-swe-fw bi-trash-fill"></i>&nbsp;{{languageService.getItem(17)}}</button>
        </swe-confirm>
      </div>
    </swe-card>
  </div>
</div>
