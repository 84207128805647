<div class="mb-{{bottomMargin}}" [ngClass]="{'row': (cols > 0), 'swe-invalid-profile border-danger text-danger': invalid}" [ngSwitch]="type">
  <label *ngIf="statusLabel>0" [ngClass]="getLabelClass()">
    <span>{{label}}</span>
    <span *ngIf="markRequired&&!optional"> *</span>
    <swe-infotext [text]="description" [showtitle]="true" *ngIf="description&&description.length>0"></swe-infotext>
    <swe-history [propertyId]="propertyId" [objectId]="objectId" [type]="type" *ngIf="track&&objectId>0"></swe-history>
  </label>
  <div [ngClass]="getInputClass()" *ngIf="access>1">
    <swe-infotext [text]="description" [showtitle]="true" *ngIf="statusLabel==0&&description&&description.length>0"></swe-infotext>
    <swe-datetime [startChanged]="isChanged" [markChanges]="container.markChanges" [(start)]="model" (startChange)="manageChange()" [labelStart]="label" [hideEnd]="true" [hideReset]="container.hideReset" [disabled]="disabled" *ngSwitchCase="'System.DateTime'" [hideSeconds]="container.hideSeconds" [alwaysshowcontents]="container.alwaysshowcontents"></swe-datetime>
    <swe-datetime [startChanged]="isChanged" [markChanges]="container.markChanges" [(start)]="model" (startChange)="manageChange()" [labelStart]="label" [hideEnd]="true" [hideTime]="true" [hideReset]="container.hideReset" [disabled]="disabled" *ngSwitchCase="'System.Date'" [alwaysshowcontents]="container.alwaysshowcontents"></swe-datetime>
    <swe-datetime [startChanged]="isChanged" [markChanges]="container.markChanges" [(start)]="model" (startChange)="manageChange()" [labelStart]="label" [hideEnd]="true" [hideDate]="true" [hideReset]="container.hideReset" [disabled]="disabled" *ngSwitchCase="'System.Time'" [hideSeconds]="container.hideSeconds"></swe-datetime>
    <input type="password" autocomplete="new-password" class="form-control" [ngClass]="{'bg-warning': isChanged}" [(ngModel)]="model" (ngModelChange)="manageChange()" placeholder="{{placeholder}}" [attr.aria-label]="label" [disabled]="disabled" *ngSwitchCase="'System.Password'" (keydown)="keydown($event)" [attr.enterkeyhint]="enterKeyHint" #sweFocusElement />
    <input type="number" class="form-control" [ngClass]="{'bg-warning': isChanged}" [ngStyle]="styleobject" [(ngModel)]="model" (ngModelChange)="manageChange()" placeholder="{{placeholder}}" [attr.aria-label]="label" [disabled]="disabled" (keydown)="keydown($event)" *ngSwitchCase="'System.Int32'" #sweFocusElement />
    <input type="number" class="form-control" [ngClass]="{'bg-warning': isChanged}" [ngStyle]="styleobject" [(ngModel)]="model" (ngModelChange)="manageChange()" placeholder="{{placeholder}}" [attr.aria-label]="label" [disabled]="disabled" (keydown)="keydown($event)" *ngSwitchCase="'System.Double'" #sweFocusElement />
    <div class="pt-1"><a href="javascript:void(0);" class="swe-no-link" (click)="manageBoolean()" *ngSwitchCase="'System.Boolean'"><i class="bi bi-swe-fw bi-swe align-bottom" [ngClass]="{'bi-check-square': model, 'bi-square': !model, 'swe-click': !disabled, 'swe-grayed-out': disabled, 'bg-warning': isChanged}"></i></a></div>
    <select class="form-control form-select" [ngClass]="{'bg-warning': isChanged}" [(ngModel)]="model" (ngModelChange)="manageChange()" [attr.aria-label]="label" [disabled]="disabled" *ngSwitchCase="'System.List'">
      <option *ngIf="optional" [value]="optionalValue"></option>
      <option *ngFor="let item of getManagedItems()" [value]="item.Id">{{item.Name}}</option>
    </select>
    <select class="form-control form-select" [ngClass]="{'bg-warning': isChanged}" [(ngModel)]="model" (ngModelChange)="manageChange()" [attr.aria-label]="label" [disabled]="disabled" *ngSwitchCase="'System.GroupByList'">
      <option *ngIf="optional" [value]="optionalValue"></option>
      <optgroup *ngFor="let groupby of items" label="{{groupby.Name}}">
        <option *ngFor="let item of groupby.Items" [value]="item.Id">{{item.Name}}</option>
      </optgroup>
    </select>
    <swe-multiple [changed]="isChanged" [(model)]="model" (modelChange)="manageChange()" [propertyId]="propertyId" [label]="label" [items]="items" [disabled]="disabled" [isbit]="container.isbit" [min]="container.min" [max]="container.max" *ngSwitchCase="'System.MultiList'">
    </swe-multiple>
    <swe-multiple [changed]="isChanged" [(model)]="model" (modelChange)="manageChange()" [propertyId]="propertyId" [label]="label" [items]="items" [disabled]="disabled" [valueAsString]="true" [min]="container.min" [max]="container.max" *ngSwitchCase="'System.MultiListAsString'">
    </swe-multiple>
    <select class="form-control form-select" [ngClass]="{'bg-warning': isChanged}" [(ngModel)]="model" (ngModelChange)="manageChange()" [attr.aria-label]="label" [disabled]="disabled" multiple [style.height]="cssheight" *ngSwitchCase="'System.GroupByMultiList'">
      <option *ngIf="optional" [value]="optionalValue"></option>
      <optgroup *ngFor="let groupby of items" label="{{groupby.Name}}">
        <option *ngFor="let item of groupby.Items" [value]="item.Id">{{item.Name}}</option>
      </optgroup>
    </select>
    <swe-radio [changed]="isChanged" [(model)]="model" (modelChange)="manageChange()" [label]="label" [items]="items" [extra]="container.extra" [disabled]="disabled" *ngSwitchCase="'System.RadioList'">
    </swe-radio>
    <input type="color" class="form-control" [ngClass]="{'bg-warning': isChanged}" [(ngModel)]="model" (ngModelChange)="manageChange()" [attr.aria-label]="label" [disabled]="disabled" *ngSwitchCase="'System.Color'" />
    <swe-color [changed]="isChanged" [(model)]="model" (modelChange)="manageChange()" [label]="label" [items]="items" [disabled]="disabled" *ngSwitchCase="'System.ColorList'">
    </swe-color>
    <swe-document [changed]="isChanged" [(model)]="model" (modelChange)="manageChange()" [label]="label" [disabled]="disabled"
                  [clientOnly]="container.clientOnly" [encoding]="container.encoding" [allowedPrefix]="container.allowedPrefix" [folder]="container.folder"
                  [document]="container.document" [showimage]="container.showimage" [showdocument]="container.showdocument" [reload]="container.reload"
                  [hidedownload]="container.hidedownload" [writeAccess]="container.writeaccess??true"
                  *ngSwitchCase="'Internetplatsen.Document'">
    </swe-document>
    <textarea class="form-control" [ngClass]="{'bg-warning': isChanged||isNull}" [(ngModel)]="model" (ngModelChange)="manageChange()" [attr.aria-label]="label" [disabled]="disabled" [attr.rows]="container.rows" [ngStyle]="{resize: container.resize}" (keydown)="keydown($event, true)" *ngSwitchCase="'System.TextArea'" #sweFocusElement></textarea>
    <swe-levelsearch [(model)]="model" (modelChange)="manageChange()" [markChanges]="container.markChanges" [displayname]="display" [onlyone]="true" [label]="label" [optional]="optional" [type]="container.type" [disabled]="disabled" [storeAtClient]="container.storeAtClient" *ngSwitchCase="'System.LevelSearch'"></swe-levelsearch>
    <swe-usersearch [(model)]="model" (modelChange)="manageChange()" [markChanges]="container.markChanges" [displayname]="display" [onlyone]="true" [label]="label" *ngSwitchCase="'System.UserSearch'"></swe-usersearch>
    <ng-container *ngIf="!LinkEdit">
      <label class="col-form-label" *ngSwitchCase="'System.Link'">
        <a [target]="'_blank'" [href]="container.Url">{{container.Link}}</a>
      </label>
    </ng-container>
    <a href="javascript:void(0);" class="swe-no-link float-end" (click)="LinkEdit=!LinkEdit" *ngSwitchCase="'System.Link'"><i class="bi bi-swe-pull-right swe-click" [ngClass]="{'bi-pencil-fill': !LinkEdit,'bi-x-lg': LinkEdit}"></i></a>
    <ng-container *ngIf="LinkEdit">
      <input type="text" class="form-control" [ngClass]="{'bg-warning': isChanged||isNull}" [(ngModel)]="model" (ngModelChange)="manageChange()" placeholder="{{placeholder}}" [attr.aria-label]="label" [disabled]="disabled" (keydown)="keydown($event)" *ngSwitchCase="'System.Link'" />
    </ng-container>
    <swe-maps [(model)]="model" (modelChange)="manageChange()" [disabled]="disabled" *ngSwitchCase="'Internetplatsen.Coordinate'"></swe-maps>
    <input type="text" class="form-control" [ngClass]="{'bg-warning': isChanged||isNull}" [(ngModel)]="model" (ngModelChange)="manageChange()" placeholder="{{placeholder}}" [attr.aria-label]="label" [disabled]="disabled" (keydown)="keydown($event)" *ngSwitchCase="'System.String'" #sweFocusElement />
    <input type="text" class="form-control" [ngClass]="{'bg-warning': isChanged||isNull}" [(ngModel)]="model" (ngModelChange)="manageChange()" placeholder="{{placeholder}}" [attr.aria-label]="label" [disabled]="disabled" (keydown)="keydown($event)" *ngSwitchDefault #sweFocusElement />
  </div>
  <!--<div class="h-100" [ngClass]="getInputClass()" *ngIf="access<2">-->
  <label class="col-form-label" [ngClass]="getInputClass()" *ngIf="access<2">
    <swe-document [changed]="isChanged" [(model)]="model" (modelChange)="manageChange()" [label]="label" [disabled]="disabled"
                  [clientOnly]="container.clientOnly" [encoding]="container.encoding" [allowedPrefix]="container.allowedPrefix" [folder]="container.folder"
                  [document]="container.document" [showimage]="container.showimage" [showdocument]="container.showdocument" [reload]="container.reload"
                  [hidedownload]="container.hidedownload" [writeAccess]="false"
                  *ngSwitchCase="'Internetplatsen.Document'">
    </swe-document>
    <span *ngSwitchCase="'System.DateTime'">{{dateTimeService.format(model, format)}}</span>
    <span *ngSwitchCase="'System.Date'">{{dateTimeService.format(model, format)}}</span>
    <span *ngSwitchCase="'System.Time'">{{dateTimeService.format(model, format)}}</span>
    <span *ngSwitchCase="'System.Int32'">{{model}}</span>
    <span *ngSwitchCase="'System.Double'">{{formatvalue||model}}</span>
    <i class="bi bi-swe swe-disabled swe-grayed-out" [ngClass]="{'bi-square': !model,'bi-check-square': model}" *ngSwitchCase="'System.Boolean'"></i>
    <span *ngSwitchCase="'System.LevelSearch'">{{display}}</span>
    <span *ngSwitchCase="'System.List'"><span class="p-1" *ngFor="let item of getReadOnlyItems()">{{item.Name}}</span></span>
    <span *ngSwitchCase="'System.MultiList'"><span class="p-1" *ngFor="let item of getReadOnlyItems()">{{item.Name}}</span></span>
    <span *ngSwitchCase="'System.Link'"><a [target]="'_blank'" [href]="container.Url">{{container.Link}}</a></span>
    <swe-maps [(model)]="model" [disabled]="true" *ngSwitchCase="'Internetplatsen.Coordinate'"></swe-maps>
    <span *ngSwitchCase="'System.TextArea'" [innerText]="model"></span>
    <span *ngSwitchCase="'System.String'">{{model}}</span>
    <span *ngSwitchDefault>{{model}}</span>
  </label>
  <!--</div>-->
  <ng-content select="[swe-footer]"></ng-content>
</div>
