<swe-pageheader [header]="languageService.getItem(574)"></swe-pageheader>
<div class="row py-2">
  <div class="col-12">
    <swe-card [open]="true" [hasCollapse]="false" [hasRefresh]="true" (refreshChange)="load()">
      <span swe-rightcommand *ngIf="permissionService.permissions.Overtime>1">
        <i class="bi bi-swe-fw bi-plus-lg bi-swe-pull-right swe-click" (click)="goto(0)"></i>
      </span>
      <swe-list [data]="ruleList" [loading]="isloading">
        <div *ngFor="let groupby of rules | keyvalue : originalOrder">
          <a class="list-group-item list-group-item-action d-flex flex-wrap bg-light">
            <h2>{{groupby.value.header}}<span class="ms-2" *ngIf="groupby.value.items.length>0">(#{{groupby.value.items[0].GroupId}})</span></h2>
          </a>
          <ng-container *ngFor="let rule of groupby.value.items">
            <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click" (click)="permissionService.permissions.Overtime>1 && goto(rule.Id)">
              <div class="col-12 col-md-8">{{rule.Desc}}</div>
              <div class="col-6 col-md-2">{{rule.Type}}</div>
              <div class="col-6 col-md-2">{{rule.Level}}<span *ngIf="rule.Level.length>0&&rule.Contract.length>0">, </span>{{rule.Contract}}</div>
            </a>
          </ng-container>
        </div>
      </swe-list>
    </swe-card>
  </div>
</div>
