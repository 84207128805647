<div class="d-inline ms-2" *ngIf="settingService.difflist.length>0">
    <span class="badge rounded-pill bg-primary swe-badge swe-click ms-1" [ngClass]="{'swe-badge-grayed-out': permissionService.isLocked(diff.locked)}" *ngFor="let diff of settingService.difflist" (click)="!permissionService.isLocked(diff.locked)&&settingService.resetItem(diff)">
        {{languageService.getItem(diff.language)}} <span *ngIf="diff.icon.length>0"><i class="bi bi-swe-fw {{diff.icon}}"></i></span><span *ngIf="isArray(diff.value)"> ({{(diff.value.length==1&&diff.name)?diff.value[0][diff.name]:diff.value.length}})</span><i class="bi bi-swe-fw bi-x-lg"></i>
    </span>
</div>
<div class="d-inline" (click)="settingService.reset(true, true)" [attr.title]="languageService.getItem(974)">
  <span class="badge rounded-pill bg-secondary swe-badge swe-click ms-1"><i class="bi bi-swe-fw bi-arrow-counterclockwise"></i></span>
</div>
<div class="d-inline" (click)="menuService.toggleSearch()" [attr.title]="menuService.ShowSearch ? languageService.getItem(976) : languageService.getItem(975)">
    <span class="badge rounded-pill bg-secondary swe-badge swe-click ms-1"><i class="bi bi-swe-fw" [ngClass]="{'bi-dash-circle': menuService.ShowSearch, 'bi-plus-circle': !menuService.ShowSearch}"></i></span>
</div>
