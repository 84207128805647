import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import { LanguageService } from '../_services/language.service';
import { SettingService } from '../_services/setting.service';
import { GeneralService } from '../_services/general.service';
import { PermissionService } from '../_services/permission.service';
import { MenuService } from '../_services/menu.service';
import { ListService } from '../_services/list.service';
import { AlertService } from '../_services/alert.service';
import { DataService } from '../_services/data.service';
import { AuthenticationService } from '../_services/authentication.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html'
})
export class SearchbarComponent implements OnInit, OnDestroy {

  @ViewChild('sweFocusElement') focusElement: ElementRef;

  //private unsubsribe$ = new Subject<void>();
  //private unsubsribe2$ = new Subject<void>();
  private _subscriptions: Subscription[] = [];
  
  constructor(
    public languageService: LanguageService,
    public settingService: SettingService,
    public generalService: GeneralService,
    public permissionService: PermissionService,
    public menuService: MenuService,
    private listService: ListService,
    private alertService: AlertService,
    private dataService: DataService,
    private authenticationService: AuthenticationService) {

    //menuService.onSearchChange$
    //  .pipe(takeUntil(this.unsubsribe$))
    //  .subscribe((refresh) => {

    //    if (refresh) {
    //      setTimeout(() => {
    //        if (this.focusElement) {
    //          let element = this.focusElement.nativeElement;
    //          element.focus();
    //        }
    //      }, 0); //Create a macrotask that will run in the next VM turn
    //    }
    //  });

    //authenticationService.onLoginChange$
    //  .pipe(takeUntil(this.unsubsribe2$))
    //  .subscribe((newlogin) => {

    //    if (newlogin) {
    //      this.loadProperties();
    //      this.loadUserFilters();
    //    }
    //  });

    this._subscriptions.push(menuService.onSearchChange$
      .subscribe((refresh) => {
        if (refresh) {
          setTimeout(() => {
            if (this.focusElement) {
              let element = this.focusElement.nativeElement;
              element.focus();
            }
          }, 0); //Create a macrotask that will run in the next VM turn
        }
      }));

    this._subscriptions.push(authenticationService.onLoginChange$
      .subscribe((newlogin) => {
        if (newlogin) {
          this.loadProperties();
          this.loadUserFilters();
        }
      }));

    this._subscriptions.push(settingService.onFilterReset$
      .subscribe((newreset) => {
        if (newreset) {
          let index = this._userfilters.map(function (filter) { return filter.Id; }).indexOf(0);
          if (index > -1) {
            this._userfilter = this._userfilters[index];
            this._userfilterid = this._userfilter.Id;
          }
        }
      }));

  }

  ngOnDestroy() {
    //this.unsubsribe$.next();
    //this.unsubsribe$.complete();
    //this.unsubsribe2$.next();
    //this.unsubsribe2$.complete();

    this._subscriptions.forEach((s) => s.unsubscribe());
  }


  ngOnInit() {
    this.loadProperties();
    this.loadUserFilters();
  }



  //Properties
  




  private _activityList: any[] = [];
  public get activityList() {
    if (this._activityList.length == 0) {
      this._activityList = [
        { Id: 0, Name: 232 },
        { Id: 1, Name: 233 },
        { Id: 2, Name: 234 }
      ];
    }
    return this._activityList;
  }
  private _availabilityList: any[] = [];
  public get availabilityList() {
    if (this._availabilityList.length == 0) {
      this._availabilityList = [
        { Id: 0, Name: 101 },
        { Id: 1, Name: 171 },
        { Id: 3, Name: 344 },
        { Id: 2, Name: 172 },
        { Id: 4, Name: 962 }
      ];
    }
    return this._availabilityList;
  }
  private _employmentList: any[] = [];
  public get employmentList() {
    if (this._employmentList.length == 0) {
      this._employmentList = [
        { Id: 0, Name: 101 },
        { Id: 1, Name: 1198 },
        { Id: 2, Name: 1199 }
      ];
    }
    return this._employmentList;
  }
  private _bookingUserTypeList: any[] = [];
  public get bookingUserTypeList() {
    if (this._bookingUserTypeList.length == 0) {
      this._bookingUserTypeList = [
        { Id: 0, Name: 101 },
        { Id: 1, Name: 174 },
        { Id: 2, Name: 176 },
        { Id: 5, Name: 971 },
        { Id: 3, Name: 177 },
        { Id: 4, Name: 175 }
      ];
    }
    return this._bookingUserTypeList;
  }
  private _intervalTypeList: any[] = [];
  public get intervalTypeList() {
    if (this._intervalTypeList.length == 0) {
      this._intervalTypeList = [
        { Id: 0, Name: 101 },
        { Id: 1, Name: 818 },
        { Id: 2, Name: 819 },
        { Id: 3, Name: 820 }
      ];
    }
    return this._intervalTypeList;
  }
  private _personalList: any[] = [];
  public get personalList() {
    if (this._personalList.length == 0) {
      this._personalList = [
        { Id: 0, Name: 846 },
        { Id: 1, Name: 847 },
        { Id: 2, Name: 848 }
      ];
    }
    return this._personalList;
  }
  private _shiftTypeList: any[] = [];
  public get shiftTypeList() {
    if (this._shiftTypeList.length == 0) {
      this._shiftTypeList = [
        { Id: -1, Name: 101 },
        { Id: 0, Name: 700 },
        { Id: 1, Name: 698 }
      ];
    }
    return this._shiftTypeList;
  }
  private _reservationList: any[] = [];
  public get reservationList() {
    if (this._reservationList.length == 0) {
      this._reservationList = [
        { Id: 0, Name: 101 },
        { Id: 1, Name: 79 },
        { Id: 2, Name: 80 },
        { Id: 3, Name: 159 }
      ];
    }
    return this._reservationList;
  }
  private _checkInList: any[] = [];
  public get checkInList() {
    if (this._checkInList.length == 0) {
      this._checkInList = [
        { Id: 0, Name: 101 },
        { Id: 1, Name: 81 },
        { Id: 2, Name: 102 }
      ];
    }
    return this._checkInList;
  }
  private _activityOptionList: any[] = [];
  public get activityOptionList() {
    if (this._activityOptionList.length == 0) {
      this._activityOptionList = [
        { Id: 0, Name: 163 },
        { Id: 1, Name: 1112 },
        { Id: 2, Name: 1113 }
      ];
    }
    return this._activityOptionList;
  }
  private _bookingTypeList: any[] = [];
  public get bookingTypeList() {
    if (this._bookingTypeList.length == 0) {
      let list = [{ Id: 1, Name: this.languageService.getItem(476) }];
      if (this.permissionService.permissions.Replaceable) {
        list.push({ Id: 0, Name: this.languageService.getItem(423) });
      }
      if (this.permissionService.permissions.Standby > 0) {
        list.push({ Id: 2, Name: this.languageService.getItem(279) });
      }
      if (this.permissionService.permissions.Abscent > 0) {
        list.push({ Id: 3, Name: this.languageService.getItem(280) });
      }
      if (this.permissionService.permissions.Decline > 0) {
        list.push({ Id: 4, Name: this.languageService.getItem(1106) });
      }

      this._bookingTypeList = list;
    }
    return this._bookingTypeList;
  }
  private _logTypeList: any[] = [];
  public get logTypeList() {
    if (this._logTypeList.length == 0) {
      this._logTypeList = [
        { Id: "Added", Language: 401 },
        { Id: "Modified", Language: 402 },
        { Id: "Deleted", Language: 403 }
      ];
    }
    return this._logTypeList;
  }
  private _weekdayList: any[] = [];
  public get weekdayList() {
    if (this._weekdayList.length == 0) {
      this._weekdayList = [
        { Id: 2, Language: 209 },
        { Id: 3, Language: 210 },
        { Id: 4, Language: 211 },
        { Id: 5, Language: 212 },
        { Id: 6, Language: 213 },
        { Id: 0, Language: 214 },
        { Id: 1, Language: 215 }
      ];
    }
    return this._weekdayList;
  }
  private _messagestatusList: any[] = [];
  public get messagestatusList() {
    if (this._messagestatusList.length == 0) {
      this._messagestatusList = [
        { Id: 1, Language: 346 },
        { Id: 2, Language: 347 },
        { Id: 5, Language: 348 },
        { Id: 3, Language: 349 },
        { Id: 4, Language: 350 },
        { Id: 6, Language: 351 }
      ];
    }
    return this._messagestatusList;
  }
  private _timeTypeList: any[] = null;
  public get timeTypeList() {
    if (this._timeTypeList == null) {
      this._timeTypeList = [];

      this.generalService.timetypes.forEach((timetype) => {
        this._timeTypeList.push({ Id: timetype.Key, Name: timetype.Value });
      });
    }
    return this._timeTypeList;
  }
  private _properties: any[] = [];
  public get properties() {

    let filtercategory = this.settingService.filtercategory;

    let visibleproperties = [];
    this._properties.forEach((groupby) => {
      if (this.settingService.hasCategory(groupby.Category) && (filtercategory == 0 || filtercategory == groupby.CategoryId)) {
        visibleproperties.push(groupby);
      }
    });

    this.settingService.property.properties.forEach((property) => {
      if (property.Id == 0) {
        property.Disabled = false;
      }
      else {
        property.Disabled = !(typeof (property.CatType) == 'undefined' || this.settingService.hasCategory(property.CatType));
      }
    });

    return visibleproperties;
  }
  private _userfilters: any[] = [];
  public get userfilters() {
    return this._userfilters
  }
  private _userfilter: any = {};
  public get userfilter() {
    return this._userfilter;
  }
  private _userfilterid: number = 0;
  public get userfilterid() {
    return this._userfilterid;
  }
  public set userfilterid(val) {
    this._userfilterid = val;
  }
  private _showYourFilter: boolean = false;
  public get showYourFilter() {
    return this._showYourFilter;
  }
  public set showYourFilter(val) {
    this._showYourFilter = val;
  }


  //Refresh
  public refresh() {
    if (!this.settingService.pinned) {
      this.menuService.closeSearch();
    }
    this.settingService.refresh();
  }

  //Levels
  public addLevel(e, type: number) {
    if (e.item.Id == 0) { return; }

    if (type == 1) {
      //User
      this.addLevelToList(this.settingService.user.levels, e.item);
    }
    else if (type == 2) {
      //Booking
      this.addLevelToList(this.settingService.booking.levels, e.item);
    }
    else if (type == 3) {
      //Level
      this.addLevelToList(this.settingService.level.levels, e.item);
    }

    if (e.exit) {
      this.settingService.refresh();
    }
  }
  public removeLevel(item, type: number) {
    if (type == 1) {
      //User
      this.removeLevelFromList(this.settingService.user.levels, item);
    }
    else if (type == 2) {
      //Booking
      this.removeLevelFromList(this.settingService.booking.levels, item);
    }
    else if (type == 3) {
      //Level
      this.removeLevelFromList(this.settingService.level.levels, item);
    }
  }
  public toggleLevel(item) {
    item.active = !item.active;
  }


  //Users
  public addUser(e) {
    let item = e.item;
    let index = this.settingService.user.users.map(function (user) { return user.Id; }).indexOf(item.Id);

    if (index == -1) {
      item.active = true;
      this.settingService.user.users.push(item);
      this.listService.sort(this.settingService.user.users, ['Firstname', 'Lastname', 'Username'], true);
    }
    
    if (e.exit) {
      this.settingService.refresh();
    }
  }
  public removeUser(item, type: number) {
    let index = this.settingService.user.users.indexOf(item);
    if (index > -1) {
      this.settingService.user.users.splice(index, 1);
    }
  }
  public toggleUser(item) {
    item.active = !item.active;
  }

  //UserFilter
  private _mappedsettings: any = {};
  public manageUserFilter(e) {

    let index = this._userfilters.map(function (filter) { return filter.Id; }).indexOf(parseInt(e));
    if (index > -1) {
      this._userfilter = this._userfilters[index];
      this._userfilterid = this._userfilter.Id;
    }

    if (this._userfilter.Id == 0) {
      this.settingService.reset(true);
    }
    else {
      this.loadSettings(this._userfilter.Settings);
      this.settingService.refresh();
    }
  }
  public saveUserFilter() {
    let verb = 'POST';
    let path = '/api/v1/users/filters'
    if (this.userfilterid > 0) {
      verb = 'PUT';
    }

    this._userfilter.Settings = this.saveSettings();

    this.dataService.tokenRequest(path, verb, this._userfilter, 'text', 'response')
      .subscribe(response => {
        if (response) {

          this.alertService.Add({ message: response.body, type: 'success' });

          this.loadUserFilters(true);
        }
      });
  }
  public deleteUserFilter() {
    this.dataService.tokenRequest('/api/v1/users/filters/' + this.userfilterid, 'DELETE', {}, 'text')
      .subscribe((res) => {

        this.alertService.Add({ type: 'success', message: res });

        this.loadUserFilters();
      });
  }



  //Functions
  private addLevelToList(levelList: any[], item) {
    let index = levelList.map(function (level) { return level.Id; }).indexOf(item.Id);

    if (index == -1) {
      item.active = true;
      levelList.push(item);
      this.listService.sort(levelList, ['Path', 'Name'], true);
    }
  }
  private removeLevelFromList(levelList: any[], item) {
    let index = levelList.indexOf(item);
    if (index > -1) {
      levelList.splice(index, 1);
    }
  }
  private loadProperties() {
    if (this.authenticationService.isAuth) {
      this.dataService.tokenRequest('/api/v1/properties/type/filter', 'GET', {})
        .subscribe(res => {

          let groupbyList: any[] = [];
          res.forEach((item) => {
            let groupby = this.listService.find(groupbyList, 'Name', item.Category);
            if (groupby) {
              groupby.Items.push({ Id: item.Id, Name: item.Name });
            }
            else {
              groupbyList.push({ Name: item.Category, Category: item.CatType, CategoryId: item.CatTypeId, Items: [{ Id: item.Id, Name: item.Name }] });
            }
          });

          this._properties = groupbyList;
        });
    }
  }
  private loadUserFilters(takeChosen: boolean = false) {
    if (this.authenticationService.isAuth) {
      this.dataService.tokenRequest('/api/v1/users/filters', 'GET', {})
        .subscribe(res => {

          let filters: any[] = [];

          filters.push({ Id: -1, Name: '', OldName: this.languageService.getItem(1153), Settings: '' });
          let filter = { Id: 0, Name: '', OldName: this.languageService.getItem(992), Settings: '' };
          filters.push(filter);
          

          res.forEach((item) => {
            item.OldName = item.Name;
            filters.push(item);
          });

          //Loaded filterlist
          this._userfilters = filters;

          //Chosen filter
          if (takeChosen) {
            let index = this._userfilters.map(function (filter) { return filter.Name; }).lastIndexOf(this._userfilter.Name);
            if (index > -1) {
              this._userfilter = this._userfilters[index];
              this._userfilterid = this._userfilter.Id;
            }
          }
          else {
            this._userfilter = filter;
            this._userfilterid = 0;
          }

        });

      this.mappedSettings();
    }
  }
  private mappedSettings() {

    /*Shifts*/
    this._mappedsettings['intervaltype'] = { Object: 'booking', Property: 'intervaltype' };
    this._mappedsettings['weekdays'] = { Object: 'booking', Property: 'weekdays' };
    this._mappedsettings['bookingstatus'] = { Object: 'booking', Property: 'status' };
    this._mappedsettings['bookingpersonal'] = { Object: 'booking', Property: 'personal' };
    this._mappedsettings['shifttype'] = { Object: 'booking', Property: 'shifttype' };
    this._mappedsettings['reservationtype'] = { Object: 'booking', Property: 'reservation' };
    this._mappedsettings['replaceableasnoslot'] = { Object: 'booking', Property: 'replaceableasnoslot' };
    this._mappedsettings['bookingcheckin'] = { Object: 'booking', Property: 'checkin' };
    this._mappedsettings['bookingusertype'] = { Object: 'booking', Property: 'bookingtype' };
    this._mappedsettings['ownerId'] = { Object: 'booking', Property: 'owner' };
    this._mappedsettings['activityoption'] = { Object: 'booking', Property: 'activityoption' };
    this._mappedsettings['activitytypes'] = { Object: 'booking', Property: 'activitytypes' };
    this._mappedsettings['filterbookinglevelgroups'] = { Object: 'booking', Property: 'levelgroups' };
    this._mappedsettings['filterbookinglevels'] = { Object: 'booking', Property: 'levels' };
    /*Timeline*/
    this._mappedsettings['groupby'] = { Object: 'timeline', Property: 'groupby' };
    this._mappedsettings['timelineshow'] = { Object: 'timeline', Property: 'timelineshow' };
    this._mappedsettings['includereferences'] = { Object: 'timeline', Property: 'includereferences' };
    this._mappedsettings['showemptyrows'] = { Object: 'timeline', Property: 'showemptyrows' };
    /*Availability*/
    this._mappedsettings['usermarkeddateavailable'] = { Object: 'user', Property: 'availability' };
    this._mappedsettings['bookingtype'] = { Object: 'user', Property: 'bookingtype' };
    //Users
    this._mappedsettings['usersearch'] = { Object: 'user', Property: 'search' };
    this._mappedsettings['filterusers'] = { Object: 'user', Property: 'users' };
    this._mappedsettings['roles'] = { Object: 'user', Property: 'roles' };
    this._mappedsettings['activity'] = { Object: 'user', Property: 'activity' };
    this._mappedsettings['nobelonging'] = { Object: 'user', Property: 'nobelonging' };
    this._mappedsettings['filteruserlevelgroups'] = { Object: 'user', Property: 'levelgroups' };
    this._mappedsettings['filteruserlevels'] = { Object: 'user', Property: 'levels' };
    /*Properties*/
    this._mappedsettings['useor'] = { Object: 'property', Property: 'useor' };
    this._mappedsettings['propertyfilter'] = { Object: 'property', Property: 'properties' };
    /*Messages*/
    this._mappedsettings['messageaddress'] = { Object: 'message', Property: 'address' };
    this._mappedsettings['subject'] = { Object: 'message', Property: 'subject' };
    this._mappedsettings['body'] = { Object: 'message', Property: 'body' };
    this._mappedsettings['messagetype'] = { Object: 'message', Property: 'messagetype' };
    this._mappedsettings['messagestatus'] = { Object: 'message', Property: 'messagestatus' };
    /*Logs*/
    this._mappedsettings['messagestatus'] = { Object: 'log', Property: 'logobject' };
    this._mappedsettings['messagestatus'] = { Object: 'log', Property: 'logtype' };
    this._mappedsettings['messagestatus'] = { Object: 'log', Property: 'showauto' };
    this._mappedsettings['levelgroups'] = { Object: 'level', Property: 'levelgroups' };
    this._mappedsettings['levels'] = { Object: 'level', Property: 'levels' };
  }
  private loadSettings(list) {

    if (list) {
      /*Reset*/
      let start = this.settingService.booking.start;
      let end = this.settingService.booking.end;
      this.settingService.reset(false);
      this.settingService.booking.start = start;
      this.settingService.booking.end = end;
      /*****/

      if (list && list.length > 0) {
        let dbObjects = JSON.parse(list);
        for (let index in dbObjects) {
          let dbItem = dbObjects[index];
          let mappedItem = this._mappedsettings[index];

          if (mappedItem) {
            if (index == 'propertyfilter' && Array.isArray(dbItem)) {
              for (let i = 0; i < dbItem.length;i++) {
                let filteritem = dbItem[i];
                filteritem['ReloadItems'] = true;
              }
            }
            this.settingService[mappedItem.Object][mappedItem.Property] = dbItem;
          }
          else {
            console.log('Missing filter property (' + index + ')');
          }
        }
      }

      this.settingService.checkWatchdog();
    }

  }
  private saveSettings() {
    let settings = {};

    for (let index in this._mappedsettings) {
      let mappedItem = this._mappedsettings[index];

      settings[index] = this.settingService[mappedItem.Object][mappedItem.Property];
    }

    return JSON.stringify(settings);
  }

}
