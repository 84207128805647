import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';
import { GeneralService } from '../../_services/general.service';
import { SettingService } from '../../_services/setting.service';



@Component({
  selector: 'app-systemsettings',
  templateUrl: './systemsettings.component.html'
})
export class SystemSettingsComponent implements OnInit, OnDestroy {

  private _subscriptions: Subscription[] = [];
  private _settings: any[] = [];
  private _openList: any[] = [];

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private alertService: AlertService,
    private generalService: GeneralService,
    private settingService: SettingService
  ) {
    //Reset
    settingService.initView();

    this._subscriptions.push(settingService.onSave$
      .subscribe((e) => {
        //Save
        this.save();
      })); 
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnInit() {
    this.loadSettings();
  }


  //Properties
  public get settings() {
    return this._settings;
  }


  //Methods
  public getList(setting) {

    let list:any[] = [];

    setting.List.forEach((item) => {

      list.push({ Id: item.Key, Name: item.Value });

    })

    return list;
  }
  public save() {

    //Save card open status
    this._settings.forEach((setting, idx) => {
      if (this._openList.length > idx) {
        this._openList[idx] = setting.Open;
      }
    });

    this.dataService.tokenRequest('/api/v1/system/settings', 'PUT', this._settings, 'text')
      .subscribe((res) => {

        this.loadSettings();

        this.permissionService.reload().subscribe();

        this.alertService.Add({ type: 'success', message: res });
      });
  }
  public container(setting) {

    let container = {};

    if (setting.Name == 'TimelineSearchDefaultTimelineShow') {
      container['isbit'] = true;
    }

    container['markChanges'] = true;

    return container;
  }


  //Functions
  private loadSettings() {
    this.dataService.tokenRequest('/api/v1/system/settings', 'GET', {})
      .subscribe((res) => {

        this._settings = res;

        this._settings.forEach((setting, idx) => {

          if (this._openList.length <= idx) {
            this._openList.push(true);
          }

          setting.Open = this._openList[idx];
        });
      });
  }

}
