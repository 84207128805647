<swe-pageheader [header]="languageService.getItem(134)"><span swe-hits>&nbsp;({{hits}})</span></swe-pageheader>
<div class="swe-fill-to-edge" sweFixedHeader>
  <swe-datepager [type]="'booking'" (dateChange)="search(false)" [disabled]="loading" [hideTime]="true"></swe-datepager>
  <swe-filteritems></swe-filteritems>
</div>
<div class="row py-2">
  <div class="col-12">
    <swe-card [hasCollapse]="false" (refreshChange)="search(false)">
      <span swe-rightcommand><i class="bi bi-plus-lg bi-swe-pull-right swe-click" (click)="goto(0)" *ngIf="permissionService.permissions.News>1"></i></span>
      <swe-list [data]="news" [loading]="loading">
        <ng-container *ngFor="let item of news">
          <div class="list-group-item" [ngClass]="{'list-group-item-warning': item.NotPublished,'list-group-item-danger': item.Expired}" *ngIf="(showpublic || !item.IsPublic)">
            <!--List mode-->
            <div class="d-flex flex-wrap" *ngIf="!item.isedit && item.Id>0 && (showpublic || !item.IsPublic)"
                  [ngClass]="{'swe-click': (item.Access>1||(permissionService.permissions.NewsAll>0&&item.LevelId==0))}"
                  (click)="(item.Access>1||(permissionService.permissions.NewsAll>0&&item.LevelId==0))&&goto(item.Id)">
              <div class="col-12 col-md-1">
                <img class="swe-circle-image" alt="{{item.Title}}" *ngIf="item.Image.length>0" sweImage imageFolder="announcementimages" [imageFile]="item.Image" imageWidth="32" imageHeight="32" />
              </div>
              <div class="col-12 col-md-2">{{item.Title}}</div>
              <div class="col-12 col-md-3" [innerHTML]="wash(item.Body)"></div>
              <div class="col-12 col-md-2">{{dateTimeService.format(item.Publish)}}</div>
              <div class="col-12 col-md-2">{{dateTimeService.format(item.Expires)}}</div>
              <div class="col-12 col-md-2"><span *ngIf="item.Role">{{item.Role}}&nbsp;</span>{{item.Level}}</div>
            </div>
          </div>
        </ng-container>
        <button class="btn btn-primary" *ngIf="more" (click)="search(true)">{{languageService.getItem(57)}}</button>
      </swe-list>
    </swe-card>
  </div>
</div>
