import { Component } from '@angular/core';
import { ChartType } from 'chart.js';
import { MultiDataSet, Label } from 'ng2-charts';
import { Router } from '@angular/router';

import { SettingService } from '../../_services/setting.service';
import { LanguageService } from '../../_services/language.service';
import { DataService } from '../../_services/data.service';
import { DateTimeService } from '../../_services/datetime.service';
import { DatePipe } from '@angular/common';
import { PermissionService } from '../../_services/permission.service';



@Component({
  selector: 'swe-homestatistics',
  templateUrl: './homestatistics.component.html',
})
export class HomeStatisticsComponent {

  private _charttype: ChartType = 'doughnut';
  private _today: MultiDataSet = [];
  private _currentweek: MultiDataSet = [];
  private _nextweek: MultiDataSet = [];
  private _labels: Label = [];
  private _colors: any = [];
  private _options: any = {};
  private _todaystat: string = "";
  private _currentweekstat: string = "";
  private _nextweekstat: string = "";
  
  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private settingService: SettingService,
    private dataService: DataService,
    private dateTimeService: DateTimeService,
    private datePipe: DatePipe,
    private router: Router) {

  }

  ngOnInit() {
    this._labels = [
      this.languageService.getItem(79),
      this.languageService.getItem(80),
      this.languageService.getItem(204)
    ];

    this._colors = [
      {
        //backgroundColor: ['#4cd964', '#e6b800', '#ff3b30']
        backgroundColor: ['#198754', '#ffc107', '#dc3545']
      }
    ];

    this._options = {
      legend: {
        align: 'start'
      }
    }

    setTimeout(() => {
      this.load();
    }, 500);
  }


  /*Properties*/
  public get charttype() {
    return this._charttype;
  }
  public get today() {
    return this._today;
  }
  public get currentweek() {
    return this._currentweek;
  }
  public get nextweek() {
    return this._nextweek;
  }
  public get labels() {
    return this._labels;
  }
  public get colors() {
    return this._colors;
  }
  public get options() {
    return this._options;
  }
  public get todaystat() {
    return this._todaystat;
  }
  public get currentweekstat() {
    return this._currentweekstat;
  }
  public get nextweekstat() {
    return this._nextweekstat;
  }



  /*Methods*/
  public load() {

    this.loadToday();
    this.loadCurrentWeek();
    this.loadNextWeek();

  }
  public goto(index: number) {
    let currentTime = new Date();
    let currentDate = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate(), 0, 0, 0);

    if (index == 1) {
      this.settingService.booking.start = currentDate;
      this.settingService.booking.end = currentDate;
    }
    else if (index == 2) {
      this.settingService.booking.start = this.dateTimeService.firstDayInWeek(currentDate);
      this.settingService.booking.end = this.dateTimeService.lastDayInWeek(currentDate);
    }
    else if (index == 3) {
      let nextweek = this.dateTimeService.addDays(currentDate, 7);
      this.settingService.booking.start = this.dateTimeService.firstDayInWeek(nextweek);
      this.settingService.booking.end = this.dateTimeService.lastDayInWeek(nextweek);
    }

    this.router.navigate(['/bookings']);
  }


  //Functions
  private loadToday() {

    let currentTime = new Date();
    let currentDate = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate(), 0, 0, 0);
    let end = this.dateTimeService.addDays(currentDate, 1);

    let filter = {
      Start: this.adjustDate(currentDate),
      End: this.adjustDate(end),
      ShiftLevelGroups: this.settingService.booking.levelgroups.join()
    };

    this.dataService.tokenRequest('/api/v1/bookings/statistics/', 'POST', filter)
      .subscribe(res => {

        this._today = [res.Full, res.Total - res.Full - res.Empty, res.Empty];
        this._todaystat = ' (' + res.Full + '/' + res.Total + ')';

      });

  }
  private loadCurrentWeek() {

    let currentTime = new Date();
    let currentDate = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate(), 0, 0, 0);

    let filter = {
      Start: this.adjustDate(this.dateTimeService.firstDayInWeek(currentDate)),
      End: this.adjustDate(this.dateTimeService.addDays(this.dateTimeService.lastDayInWeek(currentDate), 1)),
      ShiftLevelGroups: this.settingService.booking.levelgroups.join()
    };

    this.dataService.tokenRequest('/api/v1/bookings/statistics/', 'POST', filter)
      .subscribe(res => {

        this._currentweek = [res.Full, res.Total - res.Full - res.Empty, res.Empty];
        this._currentweekstat = ' (' + res.Full + '/' + res.Total + ')';

      });

  }
  private loadNextWeek() {

    let currentTime = new Date();
    let currentDate = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate(), 0, 0, 0);
    let nextweek = this.dateTimeService.addDays(currentDate, 7);

    let filter = {
      Start: this.adjustDate(this.dateTimeService.firstDayInWeek(nextweek)),
      End: this.adjustDate(this.dateTimeService.addDays(this.dateTimeService.lastDayInWeek(nextweek), 1)),
      ShiftLevelGroups: this.settingService.booking.levelgroups.join()
    };

    this.dataService.tokenRequest('/api/v1/bookings/statistics/', 'POST', filter)
      .subscribe(res => {

        this._nextweek = [res.Full, res.Total - res.Full - res.Empty, res.Empty];
        this._nextweekstat = ' (' + res.Full + '/' + res.Total + ')';

      });

  }

  private adjustDate(date: any, isend: boolean = false) {

    let newdate = new Date(date);
    if (isend) {
      newdate = new Date(newdate.getTime() + (1000 * 60 * 60 * 24));
    }

    let dateWithTimeZone = this.datePipe.transform(newdate, 'yyyy-MM-ddTHH:mm:ssZ').replace(/([+\-]\d\d)(\d\d)$/, "$1:$2");

    return dateWithTimeZone;
  }

}
