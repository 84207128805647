<swe-card header="{{languageService.getItem(680)}}" [color]="'swe'" [(open)]="open" (refreshChange)="search(false)" (openChange)="search(false)">
  <span swe-hits *ngIf="open">&nbsp;({{hits}}<span *ngIf="more">+</span>)</span>
  <span swe-rightcommand></span>
  <swe-list [data]="logs" [loading]="loading">
    <a class="list-group-item list-group-item-action d-flex flex-wrap" *ngFor="let log of logs" [ngClass]="{'list-group-item-warning': log.OriginalId > 0}">
      <div class="col-12 col-md-2">{{log.Object}}</div>
      <div class="col-12 col-md-2">{{log.Type}}</div>
      <div class="col-12 col-md-5">
        <span *ngFor="let value of log.Values" class="me-1" [ngClass]="{'bg-warning': value.Diff}">{{value.Key}}={{value.Value}},</span>
      </div>
      <div class="col-12 col-md-2">{{log.CreatedBy}} <span style="white-space:nowrap;" class="small" *ngIf="log.OriginalId>0"><i>({{languageService.getItem(317)}}: {{log.OriginalId}})</i></span></div>
      <div class="col-12 col-md-1">{{dateTimeService.format(log.Created,'yyyy-MM-dd HH:mm:ss')}}</div>
    </a>
    <button class="btn btn-primary" *ngIf="more" (click)="search(true)">{{languageService.getItem(57)}}</button>
  </swe-list>
</swe-card>
