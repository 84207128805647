<swe-card [header]="languageService.getItem(997)" [open]="true" [hasCollapse]="settingService.isDevice(['xs','sm'])" [hasRefresh]="false">
  <swe-element [type]="'System.Text'" [label]="languageService.getItem(715)" [statusLabel]="1" [(model)]="contract.Name"></swe-element>
  <swe-element [type]="'System.Double'" [label]="languageService.getItem(1144)" [statusLabel]="1" [(model)]="contract.WorkdayHours"></swe-element>
  <ng-container *ngIf="permissionService.permissions.TimeBank&&(contract.Id==0||permissionService.user.IsSuper)">
    <swe-element [type]="'System.List'" [label]="languageService.getItem(893)" [statusLabel]="1" [(model)]="contract.TimeBankId" [items]="timebanks" [optional]="true" [optionalValue]="0"></swe-element>
  </ng-container>
  <ng-container *ngIf="permissionService.permissions.Las&&(contract.Id==0||permissionService.user.IsSuper)">
    <swe-element [type]="'System.List'" [label]="'LAS'" [statusLabel]="1" [(model)]="contract.LasId" [items]="las" [optional]="true" [optionalValue]="0"></swe-element>
  </ng-container>
  <!--Buttons-->
  <div class="d-grid gap-2">
    <button class="btn btn-primary" (click)="save()" *ngIf="permissionService.permissions.Contract>1"><i class="bi bi-save"></i>&nbsp;{{languageService.getItem(16)}}</button>
    <swe-confirm *ngIf="contract.Id>0 && permissionService.permissions.Contract>2" [body]="languageService.getItem(998)" (acceptChange)="delete()">
      <button class="btn btn-danger"><i class="bi bi-swe-fw bi-trash-fill"></i>&nbsp;{{languageService.getItem(17)}}</button>
    </swe-confirm>
  </div>
</swe-card>
